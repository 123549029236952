import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import { useCopyWorkspaceMutation, useWorkspaceQuery } from "GraphQL/Generated/Apollo";

export interface ICopyWorkspaceDialogProps {
  onClose: () => void;
  open: boolean;
  workspaceId: string;
}

export function CopyWorkspaceDialog(props: ICopyWorkspaceDialogProps) {
  const { open, workspaceId } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: workspaceData } = useWorkspaceQuery({ variables: { id: workspaceId } });
  const [CopyWorkspace] = useCopyWorkspaceMutation();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [workspaceName, setWorkspaceName] = React.useState("");
  const [newWorkspaceName, setNewWorkspaceName] = React.useState("");
  const [nameFocused, setNameFocused] = React.useState(true);
  const [copyBoardPermissions, setCopyBoardPermissions] = React.useState<boolean>(false);
  const [copyFiles, setCopyFiles] = React.useState<boolean>(false);
  const nameRef = React.useRef<HTMLInputElement>(null);

  function onClose() {
    setErrorMessage("");
    setWorkspaceName("");
    setNewWorkspaceName("");
    setCopyBoardPermissions(false);
    setCopyFiles(false);
    props.onClose();
  }

  async function onCopy() {
    try {
      const result = await CopyWorkspace({
        variables: {
          input: {
            workspaceId,
            name: newWorkspaceName,
            workspaceBoard: {
              name: t("Pages.WorkspaceBoard.DefaultName"),
            },
            copyBoardPermissions,
            copyFiles,
          },
        },
        refetchQueries: ["Workspaces"],
      });

      const defaultBoardId = result.data?.copyWorkspace?.defaultWorkspaceBoard?.id;
      if (defaultBoardId) {
        navigate(`/workspaces/${workspaceId}/boards/${defaultBoardId}`, { replace: true });
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(t("Components.CopyWorkspaceDialog.Error"));
    }
  }

  React.useEffect(() => {
    if (workspaceData?.workspace?.name) {
      setWorkspaceName(workspaceData.workspace.name);
      setNewWorkspaceName(t("Components.CopyWorkspaceDialog.NewWorkspaceName", { name: workspaceData.workspace?.name }));
    }
  }, [workspaceData]);

  React.useEffect(() => {
    if (open && nameRef.current) {
      nameRef.current.focus();
    }
  }, [nameRef.current, open]);

  return (
    <>
      <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="xs">
        <DialogTitle variant="h5">{t("Components.CopyWorkspaceDialog.Title", { name: workspaceName })}</DialogTitle>
        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <FormGroup>
            <TextField
              autoFocus
              margin="dense"
              label={t("Components.CopyWorkspaceDialog.NameLabel")}
              type="text"
              fullWidth
              variant="standard"
              value={newWorkspaceName}
              onBlur={() => setNameFocused(false)}
              onChange={(e) => setNewWorkspaceName(e.target.value ?? "")}
              onFocus={() => setNameFocused(true)}
              required
              inputRef={nameRef}
              error={workspaceName === "" && !nameFocused}
            />

            <FormGroup sx={{ mt: 2, mb: 2 }}>
              <FormControlLabel
                control={<Checkbox checked={copyBoardPermissions} onChange={(_, checked) => setCopyBoardPermissions(checked)} />}
                label={t("Components.CopyWorkspaceDialog.CopyPermissionsLabel")}
              />
              <FormControlLabel
                control={<Checkbox checked={copyFiles} onChange={(_, checked) => setCopyFiles(checked)} />}
                label={t("Components.CopyWorkspaceDialog.CopyFilesLabel")}
              />
            </FormGroup>
          </FormGroup>
          <DialogContentText variant="caption">{t("Components.CopyWorkspaceDialog.Tip")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onCopy} disabled={newWorkspaceName === ""}>
            {t("Components.CopyWorkspaceDialog.Copy")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
