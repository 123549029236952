import { Box, CardActions } from "@mui/material";
import { ButtonBase, Card, CardHeader, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { WorkspaceFilesQuery } from "GraphQL/Generated/Apollo";
import { formatWorkspaceUpdatedAt } from "Utils/WorkspaceUtils";
import WorkspaceFileIcon from "./WorkspaceFileIcon";
import WorkspaceFileListMenu from "./WorkspaceFileListMenu";
import { WorkspaceContentGridSize } from "./WorkspaceContentView";

export interface IWorkspaceFileGridItemProps {
  workspaceFileDetails: WorkspaceFilesQuery["workspaceFiles"][number];
  size: WorkspaceContentGridSize;
}

export default function WorkspaceFileGridItem(props: IWorkspaceFileGridItemProps) {
  const { workspaceFileDetails, size } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid
      item
      xl={size === "small" ? 3 : size === "medium" ? 4 : 6}
      lg={size === "small" ? 2 : size === "medium" ? 3 : 4}
      md={size === "small" ? 3 : size === "medium" ? 4 : 6}
      xs={size === "small" ? 6 : size === "medium" ? 6 : 12}
      sx={{ display: "grid" }}
    >
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderWidth: "2px",
          backgroundColor: "#e8eaeb",
          ":hover": {
            backgroundColor: "#d8dadb",
          },
        }}
      >
        <CardHeader
          avatar={<WorkspaceFileIcon mimetype={workspaceFileDetails.mimeType} />}
          title={
            <Typography
              sx={{
                textAlign: "start",
                fontSize: size === "small" ? "1rem" : size === "medium" ? "1.1rem" : "1.2rem",
                fontWeight: "500",
                wordBreak: "break-word",
              }}
            >
              {workspaceFileDetails.name}
            </Typography>
          }
          action={
            <WorkspaceFileListMenu
              workspaceId={workspaceFileDetails.workspaceId}
              workspaceFileId={workspaceFileDetails.id}
              workspaceFileFilename={workspaceFileDetails.filename}
            />
          }
        />

        <CardActions sx={{ display: "block", bottom: 0 }}>
          <Box sx={{ mt: 1 }}>
            <Typography variant="caption" color="text.secondary">
              {formatWorkspaceUpdatedAt(workspaceFileDetails.updatedAt, t)}
            </Typography>
          </Box>
        </CardActions>
      </Card>
    </Grid>
  );
}
