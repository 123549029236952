import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import MoreIcon from "@mui/icons-material/MoreHoriz";
import { Box, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip, Divider, ButtonBase } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

import HasWorkspaceRole from "Components/HasWorkspaceRole/HasWorkspaceRole";
import { ObjectRole } from "GraphQL/Generated/Apollo";
import { DeleteWorkspaceFileDialog } from "./DeleteWorkspaceFileDialog";
import { RenameWorkspaceFileDialog } from "./RenameWorkspaceFileDialog";
import { getWorkspaceFileDownloadUrl } from "Utils/WorkspaceFileUtils";

export interface IWorkspaceFileListMenuProps {
  workspaceFileFilename: string;
  workspaceFileId: string;
  workspaceId: string;
}

export default function WorkspaceFileListMenu(props: IWorkspaceFileListMenuProps) {
  const { workspaceFileFilename, workspaceFileId, workspaceId } = props;

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = React.useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("Global.Action.Actions") || ""}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "workspace-file-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MoreIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 24,
              height: 24,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <HasWorkspaceRole workspaceId={workspaceId} roles={[ObjectRole.Manager, ObjectRole.Owner]}>
          <MenuItem>
            <ButtonBase href={getWorkspaceFileDownloadUrl(workspaceId, workspaceFileId, workspaceFileFilename)} target="_blank">
              <ListItemIcon>
                <DownloadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t("Global.Action.Download")} />
            </ButtonBase>
          </MenuItem>

          <MenuItem onClick={() => setRenameDialogOpen(true)}>
            <ListItemIcon>
              <DriveFileRenameOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Global.Action.Rename")} />
          </MenuItem>

          <Divider />

          <MenuItem onClick={() => setDeleteDialogOpen(true)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: "danger.main" }} primary={t("Global.Action.Delete")} />
          </MenuItem>
        </HasWorkspaceRole>
      </Menu>

      {deleteDialogOpen && (
        <DeleteWorkspaceFileDialog
          workspaceFileId={workspaceFileId}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        />
      )}

      {renameDialogOpen && (
        <RenameWorkspaceFileDialog
          workspaceFileId={workspaceFileId}
          open={renameDialogOpen}
          onClose={() => setRenameDialogOpen(false)}
        />
      )}
    </React.Fragment>
  );
}
