// *********************************************
// Public constants
// *********************************************/
export const FILE_DOWNLOAD_PROXY_PATH = "file";
export const FILE_DOWNLOAD_ROUTE_TEMPLATE = `/${FILE_DOWNLOAD_PROXY_PATH}/:fileId`;

export const DOCUMENT_IMAGE_DOWNLOAD_PROXY_PATH = "doc-image";
export const DOCUMENT_IMAGE_DOWNLOAD_ROUTE_TEMPLATE = `/${DOCUMENT_IMAGE_DOWNLOAD_PROXY_PATH}/:organizationId/documents/:documentId/images/:imageFilename`;

export const EXPORT_TO_WORD_PROXY_PATH = "export-to-word";
export const EXPORT_TO_WORD_ROUTE_TEMPLATE = `/${EXPORT_TO_WORD_PROXY_PATH}`;

export const WORKSPACE_ASSET_DOWNLOAD_PROXY_PATH = "wsb-asset";
export const WORKSPACE_ASSET_DOWNLOAD_ROUTE_TEMPLATE = `/${WORKSPACE_ASSET_DOWNLOAD_PROXY_PATH}/workspaces/:workspaceId/workspaceBoards/:workspaceBoardId/assets/:filename`;

export const WORKSPACE_FILE_DOWNLOAD_PROXY_PATH = "workspace-file";
export const WORKSPACE_FILE_DOWNLOAD_ROUTE_TEMPLATE = `/${WORKSPACE_FILE_DOWNLOAD_PROXY_PATH}/workspaces/:workspaceId/files/:workspaceFileId/:filename`;
