export enum IndexDocumentType {
  /**
   * Workspace file.
   */
  WorkspaceFile = "WorkspaceFile",

  /**
   * Workspace board shape.
   */
  BoardShape = "BoardShape",

  /**
   * Transcript.
   */
  Transcript = "Transcript",
}
