import { ApolloError } from "@apollo/client";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { WorkspaceFilesQuery } from "GraphQL/Generated/Apollo";
import WorkspaceFileIcon from "./WorkspaceFileIcon";
import WorkspaceFileListMenu from "./WorkspaceFileListMenu";

export interface IWorkspaceFileListProps {
  error?: ApolloError;
  loading: boolean;
  rows: WorkspaceFilesQuery["workspaceFiles"];
}

export default function WorkspaceFileList(props: IWorkspaceFileListProps) {
  const { loading, rows, error } = props;

  const { t } = useTranslation();

  return (
    <>
      {loading && <Typography variant="caption">{t("Global.Status.Loading")}</Typography>}
      {!loading && rows.length > 0 && (
        <List component="nav" sx={{ maxWidth: "960px", display: "flex", flexDirection: "column", flex: 1 }}>
          {rows.map((row) => {
            return (
              <ListItem
                key={row.id}
                secondaryAction={
                  <WorkspaceFileListMenu
                    workspaceFileId={row.id}
                    workspaceId={row.workspaceId}
                    workspaceFileFilename={row.filename}
                  />
                }
                disablePadding
              >
                <ListItemButton sx={{ cursor: "default" }} disableRipple>
                  <ListItemIcon>
                    <WorkspaceFileIcon mimetype={row.mimeType} />
                  </ListItemIcon>
                  <ListItemText primary={row.name} primaryTypographyProps={{ fontWeight: "500" }} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
      {!loading && !error && rows.length === 0 && (
        <Typography variant="caption" color="textSecondary">
          {t("Components.WorkspaceFileList.NoItems")}
        </Typography>
      )}
      {error && (
        <Typography variant="caption" color="error.main">
          {t("Components.WorkspaceFileList.LoadError")}
        </Typography>
      )}
    </>
  );
}
