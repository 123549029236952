import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import { useDeleteWorkspaceFileMutation, useWorkspaceFileQuery } from "GraphQL/Generated/Apollo";

export interface IDeleteWorkspaceFileDialogProps {
  open: boolean;
  workspaceFileId: string;
  onClose: () => void;
}

export function DeleteWorkspaceFileDialog(props: IDeleteWorkspaceFileDialogProps) {
  const { open, workspaceFileId } = props;

  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = React.useState("");

  const [deleteWorkspaceFile] = useDeleteWorkspaceFileMutation();
  const { data, loading, error } = useWorkspaceFileQuery({ variables: { id: workspaceFileId } });

  function onClose() {
    setErrorMessage("");
    props.onClose();
  }

  function onDelete(): void {
    deleteWorkspaceFile({
      variables: {
        input: {
          id: workspaceFileId,
        },
      },
      refetchQueries: ["WorkspaceFiles", "WorkspaceFileAggregate", "Workspaces"],
    })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(t("Components.DeleteWorkspaceFileDialog.Error"));
      });
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="xs">
        <DialogTitle variant="h5">
          {t("Components.DeleteWorkspaceFileDialog.Title", { name: data?.workspaceFile?.name || "" })}
        </DialogTitle>
        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <DialogContentText>{t("Components.DeleteWorkspaceFileDialog.Tip")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onDelete} color="danger">
            {t("Global.Action.DeletePermanently")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
