import { Download } from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Link,
  Popover,
  Typography,
} from "@mui/material";
import { HTMLReactParserOptions, Element, domToReact, DOMNode, default as parse } from "html-react-parser";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { useEffect, useState } from "react";

import { ChatMessageChannelType, WorkspaceFileQuery, useWorkspaceFileLazyQuery } from "GraphQL/Generated/Apollo";
import { getWorkspaceFileDownloadUrl } from "Utils/WorkspaceFileUtils";

export interface IShowCard1Props {
  dataReferenceId: string;
  domNode: Element;
  nodeData?: Record<string, any>;
  options: HTMLReactParserOptions;
  channelType: ChatMessageChannelType;
  channelId: string;
}

export function ShowCard1(props: IShowCard1Props) {
  const { dataReferenceId, domNode, nodeData, options, channelId, channelType } = props;
  const [cardData, setCardData] = useState<Record<string, any> | undefined>(nodeData);

  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState<boolean>(true);
  const [fileDetails, setFileDetails] = useState<WorkspaceFileQuery["workspaceFile"] | undefined>(undefined);

  const [getWorkspaceFile, { loading: workspaceFileDataLoading }] = useWorkspaceFileLazyQuery();

  useEffect(() => {
    if (JSON.stringify(nodeData) !== JSON.stringify(cardData)) {
      setCardData(nodeData);
    }
  }, [nodeData, cardData]);

  useEffect(() => {
    const workspaceId = channelType === ChatMessageChannelType.Workspace ? channelId : undefined;
    if (cardData && cardData.fileId && workspaceId) {
      /**
       * Sanity check to ensure -
       * 1. We have the required file in the storage
       * 2. The file belongs to the current workspace
       * 3. User has access to the file/ workspace
       * 4. We are not using unsanitized filename
       */
      getWorkspaceFile({
        variables: {
          id: cardData.fileId,
        },
      }).then(({ data }) => {
        if (data?.workspaceFile?.workspaceId === workspaceId) {
          setFileDetails(data.workspaceFile);
          setIsDownloadButtonDisabled(false);
        }
      });
    }
  }, [cardData, channelId, channelType, getWorkspaceFile]);

  return (
    <PopupState variant="popover" popupId={dataReferenceId}>
      {(popupState) => (
        <>
          <Link component="button" disabled={!cardData} {...bindTrigger(popupState)}>
            {domToReact(domNode.children as Array<DOMNode>, options)}
          </Link>
          {cardData && (
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <Card sx={{ maxWidth: 345 }}>
                {cardData && cardData.title && (
                  <CardHeader title={cardData.title} titleTypographyProps={{ variant: "h6" }} subheader={cardData.subTitle} />
                )}
                <CardContent className="astra document">
                  <Typography>{cardData.content ? parse(cardData.content, options) : ""}</Typography>
                </CardContent>
                <CardActions disableSpacing sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    href={
                      fileDetails
                        ? getWorkspaceFileDownloadUrl(fileDetails.workspaceId, fileDetails.id, fileDetails.filename)
                        : ""
                    }
                    target="_blank"
                    disabled={isDownloadButtonDisabled || workspaceFileDataLoading}
                  >
                    {workspaceFileDataLoading ? <CircularProgress size={20} /> : <Download />}
                  </IconButton>
                </CardActions>
              </Card>
            </Popover>
          )}
        </>
      )}
    </PopupState>
  );
}
