import { ONE_MB, WORKSPACE_FILE_UPLOAD_MAX_FILE_SIZE } from "@bigpi/cookbook";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useWorkspaceFilesQuery } from "GraphQL/Generated/Apollo";
import WorkspaceFileGrid from "./WorkspaceFileGrid";
import WorkspaceFileList from "./WorkspaceFileList";
import { WorkspaceContentGridSize } from "./WorkspaceContentView";

const DEFAULT_PAGE_SIZE = 20;

export interface IWorkspaceFileViewProps {
  gridSize: WorkspaceContentGridSize;
  onGridSizeChange: (gridSize: WorkspaceContentGridSize) => void;
  viewType: "list" | "grid";
  workspaceId: string;
  searchValue: string;
}

export default function WorkspaceFileView(props: IWorkspaceFileViewProps) {
  const { gridSize, onGridSizeChange, viewType, workspaceId, searchValue } = props;

  const { t } = useTranslation();

  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState<Record<string, string>>({ updatedAt: "desc" });

  const {
    data: workspaceFilesData,
    loading: workspaceFilesLoading,
    fetchMore: fetchMoreWorkspaceFiles,
    error: workspaceFilesError,
  } = useWorkspaceFilesQuery({
    variables: {
      workspaceId,
      limit: DEFAULT_PAGE_SIZE,
      offset,
      orderBy,
      filters: {
        searchTerm: searchValue,
      },
    },
  });
  const files = workspaceFilesData?.workspaceFiles || [];
  const fileCount = workspaceFilesData?.workspaceFileAggregate.count || 0;

  if (fileCount === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          minHeight: "300px",
        }}
      >
        <UploadFileIcon sx={{ fontSize: "xx-large", mb: 2, color: "#0b3d62" }} />
        {t("Components.SelectedFilesListDialog.DragAndDrop")}
        <Typography variant="body1" color="GrayText">
          {t("Components.SelectedFilesListDialog.FileTypeAndSize", {
            maxInMBs: WORKSPACE_FILE_UPLOAD_MAX_FILE_SIZE / ONE_MB,
          })}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", minHeight: "200px" }}>
      <Box sx={{ display: "flex", p: 3, justifyContent: "center" }}>
        {viewType === "list" ? (
          <WorkspaceFileList rows={files} error={workspaceFilesError} loading={workspaceFilesLoading} />
        ) : (
          <WorkspaceFileGrid
            rows={files}
            error={workspaceFilesError}
            loading={workspaceFilesLoading}
            gridSize={gridSize}
            onGridSizeChange={onGridSizeChange}
          />
        )}
      </Box>

      {fileCount > files.length && (
        <Button
          sx={{ alignSelf: "center", maxWidth: "150px" }}
          onClick={() => {
            fetchMoreWorkspaceFiles({
              variables: {
                offset: files.length,
                filters: {
                  searchTerm: searchValue,
                },
              },
            });
          }}
        >
          {t("Components.WorkspaceFileView.ShowMore")}
        </Button>
      )}
    </Box>
  );
}
