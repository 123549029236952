import { useAuthUser } from "@frontegg/react";
import { Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import FileIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useState } from "react";
import { useFilesQuery } from "GraphQL/Generated/Apollo";
import { useTranslation } from "react-i18next";

import { getFileDownloadUrl } from "Utils/FileUtils";
import FileListMenu from "./FileListMenu";

const DEFAULT_PAGE_SIZE = 20;

export function FileList() {
  const user = useAuthUser();
  const { t } = useTranslation();
  const [offset, setOffset] = useState(0);
  const [orderBy, setOrderBy] = useState<Record<string, string>>({ updatedAt: "desc" });
  const { data, loading, fetchMore, error } = useFilesQuery({
    variables: {
      limit: DEFAULT_PAGE_SIZE,
      offset,
      orderBy,
    },
  });

  const rows = data?.files || [];
  const count = data?.fileAggregate.count || 0;

  function formatCollaborators(acl: Array<(typeof rows)[number]["fileAccessControlList"][number]>) {
    let key = "Components.Collaborators.You";
    const collaborators = acl.filter((acl) => acl.user?.id !== user.id);
    let count = collaborators.length;

    // We can't use i18n to automatically give these and the interval post processor didn't work...
    switch (collaborators.length) {
      case 0:
        key = "Components.Collaborators.JustYou";
        break;
      case 1:
        key = "Components.Collaborators.OneOther";
        break;
      case 2:
        key = "Components.Collaborators.FewOthers";
        break;
      default:
        key = "Components.Collaborators.ManyOthers";
    }

    return t(key, { count, val: collaborators.map((a) => a.user?.name || a.userGroup?.name) });
  }

  return (
    <>
      {loading && <Typography variant="caption">{t("Global.Status.Loading")}</Typography>}
      {!loading && (
        <List component="nav" aria-label="available files">
          {rows.map((row) => {
            return (
              <ListItem key={row.id} secondaryAction={<FileListMenu fileId={row.id} />} disablePadding>
                <ListItemButton href={getFileDownloadUrl(row.id)} target="_blank">
                  <ListItemIcon>
                    <FileIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={row.name}
                    primaryTypographyProps={{ fontWeight: "500" }}
                    secondary={formatCollaborators(row.fileAccessControlList)}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
      {count > rows.length && (
        <Button
          sx={{ alignSelf: "center", maxWidth: "150px" }}
          onClick={() => {
            fetchMore({
              variables: {
                offset: rows.length,
              },
            });
          }}
        >
          Show more...
        </Button>
      )}
      {data?.files.length === 0 && !error && (
        <Typography variant="caption" color="textSecondary">
          {t("Pages.Library.Files.List.NoItems")}
        </Typography>
      )}
      {error && (
        <Typography variant="caption" color="error.main">
          {t("Pages.Library.Files.List.LoadError")}
        </Typography>
      )}
    </>
  );
}
