import Config from "Config";

/**
 * Converts given data url to file
 *
 * @param dataurl Data URL to convert to file
 * @param filename File name
 * @param type File type
 * @returns
 */
export function dataUrltoFile(dataUrl: string, filename: string, type: string) {
  const arr = dataUrl.split(",");
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type });
}

export function getFileDownloadUrl(fileId: string): string {
  return `${Config.assetHttpUrl}/file/${fileId}`;
}
