import { gql } from "@apollo/client";

export const WORKSPACE_FILE_UPLOAD_SUBSCRIPTION = gql`
  subscription OnWorkspaceFileAdded($workspaceId: ID!) {
    onWorkspaceFileAdded(workspaceId: $workspaceId) {
      id
      uploadedSize
      totalSize
      fileName
      mimeType
      workspaceId
      uploadStatus
      error
    }
  }
`;
