import { useAuthUser } from "@frontegg/react";
import { CircularProgress, Fade, LinearProgress, Typography } from "@mui/material";
import {
  ObjectRole,
  useCreateFileAccessControlListMutation,
  useDeleteFileAccessControlListMutation,
  useFileAccessControlListByObjectQuery,
  useFileAccessControlListUserRolesQuery,
} from "GraphQL/Generated/Apollo";
import { useTranslation } from "react-i18next";

import AccessControlListDropdown from "Components/AccessControlList/AccessControlListDropdown";
import AccessControlListTable from "Components/AccessControlList/AccessControlListTable";
import HasFileRole from "Components/HasFileRole/HasFileRole";

export interface FileAccessControlListEditorProps {
  fileId: string;
}

export default function FileAccessControlListEditor(props: FileAccessControlListEditorProps) {
  const { fileId } = props;
  const { t } = useTranslation();
  const user = useAuthUser();
  const [createFileAccessControlListMutation, { data: createData, loading: createLoading, error: createError }] =
    useCreateFileAccessControlListMutation();
  const [deleteFileAccessControlListMutation, { data: deleteData, loading: deleteLoading, error: deleteError }] =
    useDeleteFileAccessControlListMutation();
  const { data, loading, error } = useFileAccessControlListByObjectQuery({
    variables: {
      fileId,
    },
    fetchPolicy: "no-cache",
  });
  const {
    data: aclData,
    error: aclError,
    loading: aclLoading,
  } = useFileAccessControlListUserRolesQuery({
    variables: {
      userId: user.id,
      fileId,
    },
  });

  // Check if we should be in read-only mode
  const readOnly =
    aclData?.fileAccessControlListUserRoles.roles.some((assignedRole) =>
      [ObjectRole.ContentManager, ObjectRole.Contributor, ObjectRole.Manager, ObjectRole.Owner].includes(assignedRole),
    ) !== true;

  function handleOnAddAcl(subjectId: string, subjectType: string, role: ObjectRole) {
    createFileAccessControlListMutation({
      variables: {
        input: {
          fileId: fileId,
          userId: subjectType === "User" ? subjectId : undefined,
          userGroupId: subjectType === "UserGroup" ? subjectId : undefined,
          role,
        },
      },
      refetchQueries: ["FileAccessControlListByObject", "Files", "File"],
    });
  }

  function handleOnDeleteAcl(id: string) {
    deleteFileAccessControlListMutation({
      variables: {
        input: {
          id,
        },
      },
      refetchQueries: ["FileAccessControlListByObject", "Files", "File"],
    });
  }

  const isLoading = loading || createLoading || deleteLoading || aclLoading;
  return (
    <>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {t("Components.FileAccessControlListEditor.Description")}
        <Fade
          in={isLoading}
          style={{
            transitionDelay: isLoading ? "800ms" : "0ms",
          }}
        >
          <LinearProgress />
        </Fade>
      </Typography>
      <AccessControlListTable
        rows={data?.fileAccessControlListByObject || []}
        onDelete={handleOnDeleteAcl}
        showActions={!readOnly}
      />
      <HasFileRole fileId={fileId} roles={[ObjectRole.Manager, ObjectRole.Owner]}>
        <AccessControlListDropdown allowEveryone={true} onAdd={handleOnAddAcl} />
      </HasFileRole>
    </>
  );
}
