import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { DefaultDialogTransition } from "Components/DialogTransition/DefaultDialogTransition";
import { useUpdateWorkspaceFileMutation, useWorkspaceFileQuery } from "GraphQL/Generated/Apollo";

export interface IRenameWorkspaceFileDialogProps {
  onClose: () => void;
  open: boolean;
  workspaceFileId: string;
}

export function RenameWorkspaceFileDialog(props: IRenameWorkspaceFileDialogProps) {
  const { open, workspaceFileId } = props;

  const { t } = useTranslation();

  const { data, loading, error } = useWorkspaceFileQuery({ variables: { id: workspaceFileId } });
  const [updateWorkspaceFile] = useUpdateWorkspaceFileMutation();

  const [errorMessage, setErrorMessage] = React.useState("");
  const [oldName, setOldName] = React.useState("");
  const [name, setName] = React.useState("");
  const [nameFocused, setNameFocused] = React.useState(true);

  const nameRef = React.useRef<HTMLInputElement>(null);

  function onClose() {
    setErrorMessage("");
    setName("");
    props.onClose();
  }

  function onRename() {
    updateWorkspaceFile({
      variables: { input: { id: workspaceFileId, name: name } },
      refetchQueries: ["WorkspaceFile", "WorkspaceFiles", "Workspaces"],
    })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(t("Components.RenameWorkspaceFileDialog.Error"));
      });
  }

  React.useEffect(() => {
    if (data?.workspaceFile?.name) {
      setOldName(data.workspaceFile?.name || "");
      setName(data.workspaceFile?.name || "");
    }
  }, [data]);

  React.useEffect(() => {
    if (open && nameRef.current) {
      nameRef.current.focus();
    }
  }, [nameRef.current, open]);

  return (
    <>
      <Dialog open={open} onClose={onClose} TransitionComponent={DefaultDialogTransition} fullWidth maxWidth="xs">
        <DialogTitle variant="h5">{t("Components.RenameWorkspaceFileDialog.Title", { name: oldName })}</DialogTitle>
        <DialogContent>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <TextField
            autoFocus
            margin="dense"
            label={t("Components.RenameWorkspaceFileDialog.NameLabel")}
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onBlur={() => setNameFocused(false)}
            onChange={(e) => setName(e.target.value ?? "")}
            onFocus={() => setNameFocused(true)}
            required
            inputRef={nameRef}
            error={name === "" && !nameFocused}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Global.Action.Close")}</Button>
          <Button onClick={onRename} disabled={name === ""}>
            {t("Components.RenameWorkspaceFileDialog.Rename")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
