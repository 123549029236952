import { InMemoryCache } from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";
import { getObjectHash } from "@bigpi/cookbook";

import { StrictTypedTypePolicies } from "GraphQL/Generated/ApolloHelpers";
import { TranscriptsQuery } from "./Generated/Apollo";

function paginatedMerge(existing: any, incoming: any, { args }: { args: Record<string, any> | null }) {
  const offset = args?.offset || 0;
  const merged = existing ? existing.slice(0) : [];
  for (let i = 0; i < incoming.length; ++i) {
    merged[offset + i] = incoming[i];
  }
  return merged;
}

function paginatedRead(existing: any, { args }: { args: Record<string, any> | null }) {
  const offset = args?.offset || 0;
  // Limit results to request amount if set. Otherwise attempt to return all results
  const limit = args?.limit || (Array.isArray(existing) && existing.length ? existing.length : 0);
  if (existing && existing.length >= offset + limit) {
    return existing && existing.slice(offset, offset + limit);
  } else {
    return undefined;
  }
}

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      userGroups: {
        merge: paginatedMerge,
        read: paginatedRead,
        keyArgs: ["orderBy"],
      },
      userGroupMembers: {
        merge: paginatedMerge,
        read: paginatedRead,
        keyArgs: ["orderBy", "userGroupId", "userId"],
      },
      workspaces: {
        ...offsetLimitPagination(),
        keyArgs: ["orderBy", "filters"],
      },
      workspaceFiles: {
        ...offsetLimitPagination(),
        keyArgs: ["workspaceId", "orderBy", "filters"],
      },
      workspaceBoards: {
        ...offsetLimitPagination(),
        keyArgs: ["workspaceId", "orderBy", "filters"],
      },
      files: {
        ...offsetLimitPagination(),
        keyArgs: ["orderBy", "filters"],
      },
      documents: {
        ...offsetLimitPagination(),
        keyArgs: ["orderBy", "filters"],
      },
      transcripts: {
        merge: (
          existing: TranscriptsQuery["transcripts"] | undefined,
          incoming: TranscriptsQuery["transcripts"] | undefined,
          { args }: { args: Record<string, any> | null },
        ) => {
          const merged = {
            results: existing?.results?.slice(0) || [],
            totalCount: existing?.totalCount || 0,
            status: existing?.status || "success",
          };
          merged.results = merged.results.concat(incoming?.results || []);
          merged.totalCount = incoming?.totalCount || merged.results.length;
          merged.status = incoming?.status || "success";

          return merged;
        },
        keyArgs: ["orderBy", "filters"],
      },
    },
  },
  UserPreference: {
    // NOTE: Only the client uses just the `key` field as a key. The real key is key/organizationId/userId.
    keyFields: ["key"],
  },
  GroupedAnalystQuestion: {
    fields: {
      group: {
        keyArgs: (args, context) => {
          // Creating unique id for the group based on the variables to have a unique cache key
          return `GroupedAnalystQuestion:group:${getObjectHash(JSON.stringify(context.variables))}`;
        },
      },
    },
  },
};

export const cache = new InMemoryCache({
  typePolicies,
});
