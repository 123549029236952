import { GraphQLClient, RequestOptions } from 'graphql-request';
import { gql } from 'graphql-request';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
  Tag: { input: any; output: any; }
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type IAnalysisFeedback = {
  __typename?: 'AnalysisFeedback';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  dataItemId: Scalars['String']['output'];
  dataItemType: Scalars['String']['output'];
  feedbackContent: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type IAnalysisResultOverrideType = {
  bodyStyleMatch?: InputMaybe<IScoreItemOverrideType>;
  disabledPlugIns?: InputMaybe<Array<Scalars['String']['input']>>;
  h1StyleMatch?: InputMaybe<IScoreItemOverrideType>;
  h2StyleMatch?: InputMaybe<IScoreItemOverrideType>;
  h3StyleMatch?: InputMaybe<IScoreItemOverrideType>;
  h4StyleMatch?: InputMaybe<IScoreItemOverrideType>;
  h5StyleMatch?: InputMaybe<IScoreItemOverrideType>;
  h6StyleMatch?: InputMaybe<IScoreItemOverrideType>;
  hierarchy?: InputMaybe<IHierarchyResultType>;
  indent?: InputMaybe<IIndentResultType>;
  titleStyleMatch?: InputMaybe<IScoreItemOverrideType>;
};

export type IAnalystQuestion = {
  __typename?: 'AnalystQuestion';
  analystQuestionClusterAssignment: Array<IAnalystQuestionClusterAssignment>;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  eventDate: Scalars['Date']['output'];
  firm: Scalars['String']['output'];
  firmType: Scalars['String']['output'];
  /** Group */
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  question: Scalars['String']['output'];
  segment: Array<Scalars['String']['output']>;
  topics: Array<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type IAnalystQuestionAggregate = {
  __typename?: 'AnalystQuestionAggregate';
  max?: Maybe<Scalars['Date']['output']>;
  min?: Maybe<Scalars['Date']['output']>;
};

export type IAnalystQuestionArrayFieldAggregate = {
  __typename?: 'AnalystQuestionArrayFieldAggregate';
  segment?: Maybe<Array<Scalars['String']['output']>>;
  topics?: Maybe<Array<Scalars['String']['output']>>;
};

export type IAnalystQuestionArrayFieldGroupType = {
  __typename?: 'AnalystQuestionArrayFieldGroupType';
  count: Scalars['Int']['output'];
  segment?: Maybe<Scalars['String']['output']>;
  topics?: Maybe<Scalars['String']['output']>;
};

export type IAnalystQuestionCluster = {
  __typename?: 'AnalystQuestionCluster';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  idealizedQuestion: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type IAnalystQuestionClusterAssignment = {
  __typename?: 'AnalystQuestionClusterAssignment';
  analystQuestion: IAnalystQuestion;
  analystQuestionCluster: IAnalystQuestionCluster;
  analystQuestionClusterId: Scalars['ID']['output'];
  analystQuestionId: Scalars['ID']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  score: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type IAnalystQuestionFacetsInput = {
  eventDate?: InputMaybe<IDateRangeInput>;
  firm?: InputMaybe<Array<Scalars['String']['input']>>;
  firmType?: InputMaybe<Array<Scalars['String']['input']>>;
  segment?: InputMaybe<Array<Scalars['String']['input']>>;
  topics?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IAnalystQuestionFieldGroupType = {
  __typename?: 'AnalystQuestionFieldGroupType';
  count: Scalars['Int']['output'];
  firm?: Maybe<Scalars['String']['output']>;
  firmType?: Maybe<Scalars['String']['output']>;
};

export type IBulkDeleteResult = {
  __typename?: 'BulkDeleteResult';
  count: Scalars['Int']['output'];
};

export type IChatCommandContext = {
  application: Scalars['JSONObject']['input'];
  organization: Scalars['JSONObject']['input'];
  selection: Scalars['JSONObject']['input'];
  session: Array<Scalars['JSONObject']['input']>;
  user: Scalars['JSONObject']['input'];
};

export type IChatMessage = {
  __typename?: 'ChatMessage';
  attachments: Array<IChatMessageAttachment>;
  channelId: Scalars['ID']['output'];
  channelType: IChatMessageChannelType;
  childCount: Scalars['Int']['output'];
  children?: Maybe<Array<IChatMessage>>;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  createdByUser?: Maybe<IUser>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  deletedBy?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  mentions: Array<IChatMessageMention>;
  message: Scalars['String']['output'];
  messageType: IChatMessageType;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  parent?: Maybe<IChatMessage>;
  parentId?: Maybe<Scalars['ID']['output']>;
  pinned: Scalars['Boolean']['output'];
  pinnedAt?: Maybe<Scalars['Date']['output']>;
  pinnedBy?: Maybe<Scalars['ID']['output']>;
  privateRecipientIds?: Maybe<Array<Scalars['ID']['output']>>;
  reactions: Array<IChatMessageReaction>;
  updateCount: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<Scalars['ID']['output']>;
};

export type IChatMessageAttachment = {
  __typename?: 'ChatMessageAttachment';
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  storageLocationUrl: Scalars['String']['output'];
};

export enum IChatMessageChannelType {
  /** A generic chat channel */
  Channel = 'Channel',
  /** A direct message channel */
  Direct = 'Direct',
  /** Workspace linked chat */
  Workspace = 'Workspace'
}

export type IChatMessageMention = {
  __typename?: 'ChatMessageMention';
  mentionType: IChatMessageMentionType;
  name: Scalars['String']['output'];
  targetId: Scalars['String']['output'];
};

export enum IChatMessageMentionType {
  /** A bot was mentioned */
  Bot = 'Bot',
  /** A channel was mentioned */
  Channel = 'Channel',
  /** A dynamic mention, such as @here or @everyone */
  Dynamic = 'Dynamic',
  /** A specific user was mentioned */
  User = 'User'
}

export type IChatMessageReaction = {
  __typename?: 'ChatMessageReaction';
  reaction: Scalars['String']['output'];
  userIds: Array<Scalars['ID']['output']>;
};

export enum IChatMessageType {
  /** Message originated from an AI bot */
  Ai = 'Ai',
  /** Message originated from a function */
  Function = 'Function',
  /** Message originated from a human */
  Human = 'Human',
  /** Message originated from a system process */
  System = 'System',
  /** Message originated from a tool */
  Tool = 'Tool'
}

export type ICommandContext = {
  application: Scalars['JSON']['input'];
  organization: Scalars['JSON']['input'];
  selection: Scalars['JSON']['input'];
  session: Array<Scalars['JSON']['input']>;
  user: Scalars['JSON']['input'];
};

export type ICommandResponse = {
  __typename?: 'CommandResponse';
  activeCommandContext: Scalars['JSON']['output'];
  callerData?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  requestId: Scalars['UUID']['output'];
  sessionId: Scalars['UUID']['output'];
  status: Scalars['String']['output'];
};

export type IConfigsAggregate = {
  __typename?: 'ConfigsAggregate';
  count: Scalars['Int']['output'];
};

export type IConfigsOrderBy = {
  key?: InputMaybe<ISortOrder>;
  mimeType?: InputMaybe<ISortOrder>;
  organizationId?: InputMaybe<ISortOrder>;
  updatedAt?: InputMaybe<ISortOrder>;
};

export type ICopyShapeDataInput = {
  datastoreId: Scalars['ID']['input'];
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['String']['input'];
};

export type ICopyWorkspaceInput = {
  copyBoardPermissions: Scalars['Boolean']['input'];
  copyFiles: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  workspaceBoard: ICopyWorkspaceWorkspaceBoardSubInput;
  workspaceId: Scalars['ID']['input'];
};

export type ICopyWorkspaceWorkspaceBoardSubInput = {
  name: Scalars['String']['input'];
};

export type ICreateAnalysisFeedbackInput = {
  dataItemId: Scalars['String']['input'];
  dataItemType: Scalars['String']['input'];
  feedbackContent: Scalars['String']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ICreateAnalystQuestionClusterAssignmentInput = {
  analystQuestionClusterId: Scalars['ID']['input'];
  analystQuestionId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  score: Scalars['Int']['input'];
  version: Scalars['String']['input'];
};

export type ICreateAnalystQuestionClusterInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  idealizedQuestion: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ICreateAnalystQuestionInput = {
  eventDate: Scalars['Date']['input'];
  firm: Scalars['String']['input'];
  firmType: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  question: Scalars['String']['input'];
  segment: Array<Scalars['String']['input']>;
  topics: Array<Scalars['String']['input']>;
};

export type ICreateChatMessageInput = {
  channelId: Scalars['ID']['input'];
  channelType: IChatMessageChannelType;
  commandContext?: InputMaybe<IChatCommandContext>;
  message: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type ICreateDocumentAccessControlListInput = {
  documentId: Scalars['ID']['input'];
  role: IObjectRole;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type ICreateDocumentContentInput = {
  content: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  documentId: Scalars['ID']['input'];
};

export type ICreateDocumentContentSubInput = {
  content: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
};

export type ICreateDocumentInput = {
  content: ICreateDocumentContentSubInput;
  name: Scalars['String']['input'];
};

export type ICreateFileAccessControlListInput = {
  fileId: Scalars['ID']['input'];
  role: IObjectRole;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type ICreateFileInput = {
  filename: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type ICreateShapeDataInput = {
  data: Scalars['String']['input'];
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['String']['input'];
};

export type ICreateTopicDiscussionExampleInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  detail: Scalars['String']['input'];
  event: Scalars['String']['input'];
  eventDate: Scalars['Date']['input'];
  eventType?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  firm?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Scalars['Int']['input']>;
  question: Scalars['String']['input'];
  rating?: InputMaybe<Scalars['Float']['input']>;
  reportTitle?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Float']['input']>;
  section?: InputMaybe<Scalars['String']['input']>;
  segmentId?: InputMaybe<Scalars['String']['input']>;
  segmentType?: InputMaybe<Scalars['String']['input']>;
  sourceFormat?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<Scalars['String']['input']>;
  sourceUrl?: InputMaybe<Scalars['String']['input']>;
  /** Array of speaker info */
  speakers: Array<ISpeakerInput>;
  summary: Scalars['String']['input'];
  ticker: Scalars['String']['input'];
  topic: Scalars['String']['input'];
  turn?: InputMaybe<Scalars['String']['input']>;
  type?: Scalars['String']['input'];
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type ICreateTopicDiscussionInNewsArticleInput = {
  articleCount: Scalars['Int']['input'];
  date: Scalars['Date']['input'];
  headline: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of new source info */
  newSources: Array<INewSourceInput>;
  paragraph: Scalars['String']['input'];
  segmentId: Scalars['String']['input'];
  segmentType: Scalars['String']['input'];
  sourceType: Scalars['String']['input'];
  theme: Scalars['String']['input'];
  /** Array of topic discussion info */
  topicDiscussions: Array<ITopicDiscussionInput>;
};

export type ICreateTopicDiscussionSummaryExampleInput = {
  analysisName: Scalars['String']['input'];
  category: Scalars['String']['input'];
  detail: Scalars['String']['input'];
  event: Scalars['String']['input'];
  eventDate: Scalars['Date']['input'];
  eventType: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Scalars['Int']['input']>;
  questionMentionCounts: Array<Scalars['Int']['input']>;
  questions: Array<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  score?: InputMaybe<Scalars['Float']['input']>;
  section: Scalars['String']['input'];
  sourceFormat: Scalars['String']['input'];
  sourceId: Scalars['String']['input'];
  sourceUrl: Scalars['String']['input'];
  /** Array of speaker info */
  speakers: Array<ISpeakerInput>;
  summary: Scalars['String']['input'];
  ticker: Scalars['String']['input'];
  topic: Scalars['String']['input'];
  topicMentionCount: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type ICreateUserGroupInput = {
  name: Scalars['String']['input'];
};

export type ICreateUserGroupMemberInput = {
  userGroupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type ICreateUserPreferenceInput = {
  data: Scalars['JSONObject']['input'];
  key: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type ICreateWorkspaceAccessControlListInput = {
  role: IObjectRole;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  workspaceId: Scalars['ID']['input'];
};

export type ICreateWorkspaceBoardInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type ICreateWorkspaceFileInput = {
  filename: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type ICreateWorkspaceInput = {
  name: Scalars['String']['input'];
  workspaceBoard: ICreateWorkspaceWorkspaceBoardSubInput;
};

export type ICreateWorkspaceWorkspaceBoardSubInput = {
  name: Scalars['String']['input'];
};

export type IDateRangeInput = {
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type IDeleteAnalysisFeedbackInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteAnalystQuestionClusterAssignmentInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteAnalystQuestionClusterInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteAnalystQuestionInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteChatMessageInput = {
  channelType: IChatMessageChannelType;
  id: Scalars['ID']['input'];
};

export type IDeleteConfigKeyInput = {
  key: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type IDeleteDocumentAccessControlListInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteDocumentInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteFileAccessControlListInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteFileInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteInactiveWorkspaceBoardShapeDataInput = {
  workspaceBoardId: Scalars['String']['input'];
};

export type IDeleteResult = {
  __typename?: 'DeleteResult';
  id: Scalars['ID']['output'];
};

export type IDeleteTopicDiscussionExampleInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteTopicDiscussionInNewsArticleInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteTopicDiscussionSummaryExampleInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteUserGroupInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteUserGroupMemberInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteUserPreferenceInput = {
  key: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type IDeleteWorkspaceAccessControlListInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteWorkspaceBoardInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteWorkspaceFileInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteWorkspaceInput = {
  id: Scalars['ID']['input'];
};

export enum IDiscussionNature {
  FinancialDetails = 'FinancialDetails',
  ForwardLooking = 'ForwardLooking',
  Initiatives = 'Initiatives',
  Investments = 'Investments',
  QuantitativeMetrics = 'QuantitativeMetrics',
  StateOfMarket = 'StateOfMarket'
}

export type IDistilledTranscript = {
  __typename?: 'DistilledTranscript';
  audioUrl: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  displaySymbol: Scalars['String']['output'];
  distilledSegments: Array<IDistilledTranscriptSegment>;
  eventType: ITranscriptEventType;
  id: Scalars['ID']['output'];
  itemDate: Scalars['Date']['output'];
  participants: Array<ITranscriptParticipant>;
  quarter: Scalars['Int']['output'];
  symbols: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  transcriptId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  year: Scalars['Int']['output'];
};

export type IDistilledTranscriptAggregate = {
  __typename?: 'DistilledTranscriptAggregate';
  maxItemDate?: Maybe<Scalars['String']['output']>;
  minItemDate?: Maybe<Scalars['String']['output']>;
};

export type IDistilledTranscriptFilters = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  symbols?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IDistilledTranscriptOrderBy = {
  displaySymbol?: InputMaybe<ISortOrder>;
  itemDate?: InputMaybe<ISortOrder>;
  quarter?: InputMaybe<ISortOrder>;
  title?: InputMaybe<ISortOrder>;
  year?: InputMaybe<ISortOrder>;
};

export type IDistilledTranscriptSearchResponse = {
  __typename?: 'DistilledTranscriptSearchResponse';
  errors?: Maybe<Array<ISushiCustomError>>;
  results?: Maybe<Array<IDistilledTranscript>>;
  status: Scalars['String']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type IDistilledTranscriptSegment = {
  __typename?: 'DistilledTranscriptSegment';
  distilledTranscriptItems: Array<ITranscriptItem>;
  originalTranscriptItems: Array<ITranscriptItem>;
  segmentIndex: Scalars['Int']['output'];
  session: Scalars['String']['output'];
};

export type IDocument = {
  __typename?: 'Document';
  content: IDocumentContent;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  documentAccessControlList: Array<IDocumentAccessControlList>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type IDocumentAccessControlList = {
  __typename?: 'DocumentAccessControlList';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  documentId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  role: IObjectRole;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  user?: Maybe<IUser>;
  userGroup?: Maybe<IUserGroup>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type IDocumentAccessControlListAggregate = {
  __typename?: 'DocumentAccessControlListAggregate';
  count: Scalars['Int']['output'];
};

export type IDocumentAccessControlListOrderBy = {
  createdAt?: InputMaybe<ISortOrder>;
  updatedAt?: InputMaybe<ISortOrder>;
};

export type IDocumentAccessControlListUserRoles = {
  __typename?: 'DocumentAccessControlListUserRoles';
  objectId: Scalars['String']['output'];
  roles: Array<IObjectRole>;
  userId: Scalars['String']['output'];
};

export type IDocumentAggregate = {
  __typename?: 'DocumentAggregate';
  count: Scalars['Int']['output'];
};

export type IDocumentContent = {
  __typename?: 'DocumentContent';
  content: Scalars['String']['output'];
  contentType: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type IDocumentFile = {
  __typename?: 'DocumentFile';
  document: IDocumentType;
  filename: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  storageLocation: Scalars['String']['output'];
};

export type IDocumentImageFile = {
  __typename?: 'DocumentImageFile';
  apiDownloadUrl: Scalars['String']['output'];
  assetDownloadUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  storageLocation: Scalars['String']['output'];
};

export type IDocumentOrderBy = {
  createdAt?: InputMaybe<ISortOrder>;
  name?: InputMaybe<ISortOrder>;
  updatedAt?: InputMaybe<ISortOrder>;
};

export type IDocumentType = {
  __typename?: 'DocumentType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export type IExecuteCommandInput = {
  callerData?: InputMaybe<Scalars['String']['input']>;
  commandContext: ICommandContext;
  commandId: Scalars['String']['input'];
  deduplicationId?: InputMaybe<Scalars['String']['input']>;
  outputTemplate: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};

export type IFile = {
  __typename?: 'File';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  fileAccessControlList: Array<IFileAccessControlList>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type IFileAccessControlList = {
  __typename?: 'FileAccessControlList';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  fileId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  role: IObjectRole;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  user?: Maybe<IUser>;
  userGroup?: Maybe<IUserGroup>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type IFileAccessControlListAggregate = {
  __typename?: 'FileAccessControlListAggregate';
  count: Scalars['Int']['output'];
};

export type IFileAccessControlListOrderBy = {
  createdAt?: InputMaybe<ISortOrder>;
  updatedAt?: InputMaybe<ISortOrder>;
};

export type IFileAccessControlListUserRoles = {
  __typename?: 'FileAccessControlListUserRoles';
  objectId: Scalars['String']['output'];
  roles: Array<IObjectRole>;
  userId: Scalars['String']['output'];
};

export type IFileAggregate = {
  __typename?: 'FileAggregate';
  count: Scalars['Int']['output'];
};

export type IFileData = {
  __typename?: 'FileData';
  apiDownloadUrl: Scalars['String']['output'];
  assetDownloadUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  storageLocation: Scalars['String']['output'];
};

export type IFileOrderBy = {
  createdAt?: InputMaybe<ISortOrder>;
  name?: InputMaybe<ISortOrder>;
  updatedAt?: InputMaybe<ISortOrder>;
};

export type IGroupedAnalystQuestion = {
  __typename?: 'GroupedAnalystQuestion';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  eventDate: Scalars['Date']['output'];
  firm: Scalars['String']['output'];
  firmType: Scalars['String']['output'];
  group: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  question: Scalars['String']['output'];
  segment: Array<Scalars['String']['output']>;
  topics: Array<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type IHierarchyResultType = {
  maxDepth: Scalars['Int']['input'];
  textLength: Scalars['Int']['input'];
};

export type IITopicDiscussion = {
  __typename?: 'ITopicDiscussion';
  summary: Scalars['String']['output'];
  topic: Scalars['String']['output'];
};

export type IImageTokenResultType = {
  __typename?: 'ImageTokenResultType';
  token: Scalars['String']['output'];
};

export type IIndentResultType = {
  indentLevels: Scalars['JSON']['input'];
  indentValues: Array<Scalars['Int']['input']>;
  maxIndent: Scalars['Int']['input'];
};

export type ILinkPropsType = {
  __typename?: 'LinkPropsType';
  isEmbeddable: Scalars['Boolean']['output'];
};

export type IMarkWorkspaceBoardShapeAsDeletedInput = {
  shapeIds: Array<Scalars['String']['input']>;
  workspaceBoardId: Scalars['ID']['input'];
};

export type IMinMaxAggregate = {
  __typename?: 'MinMaxAggregate';
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
};

export type IMutation = {
  __typename?: 'Mutation';
  copyShapeData?: Maybe<IShapeData>;
  copyWorkspace?: Maybe<IWorkspace>;
  createAnalysisFeedback?: Maybe<IAnalysisFeedback>;
  createAnalystQuestion?: Maybe<IAnalystQuestion>;
  createAnalystQuestionCluster?: Maybe<IAnalystQuestionCluster>;
  createAnalystQuestionClusterAssignment?: Maybe<IAnalystQuestionClusterAssignment>;
  createChatMessage: IChatMessage;
  createDocument?: Maybe<IDocument>;
  createDocumentAccessControlList?: Maybe<IDocumentAccessControlList>;
  createDocumentContent?: Maybe<IDocumentContent>;
  createFile?: Maybe<IFile>;
  createFileAccessControlList?: Maybe<IFileAccessControlList>;
  createNewConfigKey: IConfigs;
  createShapeData?: Maybe<IShapeData>;
  createTopicDiscussionExample?: Maybe<ITopicDiscussionExample>;
  createTopicDiscussionInNewsArticle?: Maybe<ITopicDiscussionInNewsArticle>;
  createTopicDiscussionSummaryExample?: Maybe<ITopicDiscussionSummaryExample>;
  createUserGroup?: Maybe<IUserGroup>;
  createUserGroupMember?: Maybe<IUserGroupMember>;
  createUserPreference: IUserPreference;
  createWorkspace?: Maybe<IWorkspace>;
  createWorkspaceAccessControlList?: Maybe<IWorkspaceAccessControlList>;
  createWorkspaceBoard: IWorkspaceBoard;
  createWorkspaceFile: IWorkspaceFile;
  deleteAnalysisFeedback: IDeleteResult;
  deleteAnalystQuestion: IDeleteResult;
  deleteAnalystQuestionCluster: IDeleteResult;
  deleteAnalystQuestionClusterAssignment: IDeleteResult;
  deleteBulkAnalystQuestionClusterAssignments: IBulkDeleteResult;
  deleteBulkAnalystQuestionClusters: IBulkDeleteResult;
  deleteBulkAnalystQuestions: IBulkDeleteResult;
  deleteBulkTopicDiscussionExamples: IBulkDeleteResult;
  deleteBulkTopicDiscussionInNewsArticles: IBulkDeleteResult;
  deleteBulkTopicDiscussionSummaryExamples: IBulkDeleteResult;
  deleteConfigKey: Array<IConfigs>;
  deleteDocument: IDeleteResult;
  deleteDocumentAccessControlList: IDeleteResult;
  deleteFile: IDeleteResult;
  deleteFileAccessControlList: IDeleteResult;
  deleteInactiveWorkspaceBoardShapeData: IBulkDeleteResult;
  deleteTopicDiscussionExample: IDeleteResult;
  deleteTopicDiscussionInNewsArticle: IDeleteResult;
  deleteTopicDiscussionSummaryExample: IDeleteResult;
  deleteUserGroup: IDeleteResult;
  deleteUserGroupMember: IDeleteResult;
  deleteUserPreference: IUserPreference;
  deleteWorkspace: IDeleteResult;
  deleteWorkspaceAccessControlList: IDeleteResult;
  deleteWorkspaceBoard: IDeleteResult;
  deleteWorkspaceFile: IDeleteResult;
  executeCommand?: Maybe<ICommandResponse>;
  executePingCommand?: Maybe<ICommandResponse>;
  markWorkspaceBoardShapesAsDeleted: Array<Scalars['String']['output']>;
  processHtml: IProcessHtmlResponseType;
  reindexWorkspace: IReindexWorkspaceResult;
  softOrHardDeleteChatMessage: IDeleteResult;
  updateAnalystQuestion: IAnalystQuestion;
  updateAnalystQuestionCluster: IAnalystQuestionCluster;
  updateAnalystQuestionClusterAssignment: IAnalystQuestionClusterAssignment;
  updateChatMessage: IChatMessage;
  updateConfigKey: IConfigs;
  updateDocument: IDocument;
  updateDocumentAccessControlList: IDocumentAccessControlList;
  updateDocumentContent: IDocumentContent;
  updateFile: IFile;
  updateFileAccessControlList: IFileAccessControlList;
  updateShapeData?: Maybe<IShapeData>;
  updateTopicDiscussionExample?: Maybe<ITopicDiscussionExample>;
  updateTopicDiscussionInNewsArticle?: Maybe<ITopicDiscussionInNewsArticle>;
  updateTopicDiscussionSummaryExample?: Maybe<ITopicDiscussionSummaryExample>;
  updateUserGroup: IUserGroup;
  updateUserGroupMember: IUserGroupMember;
  updateWorkspace: IWorkspace;
  updateWorkspaceAccessControlList: IWorkspaceAccessControlList;
  updateWorkspaceBoard: IWorkspaceBoard;
  updateWorkspaceFile: IWorkspaceFile;
  uploadDocument?: Maybe<IDocumentFile>;
  uploadDocumentImage?: Maybe<IDocumentImageFile>;
  uploadFile?: Maybe<IFileData>;
  uploadWorkspaceBoardAsset?: Maybe<IWorkspaceBoardAssetFile>;
  uploadWorkspaceFiles?: Maybe<IUploadWorkspaceFileResponse>;
  upsertUserPreference: IUserPreference;
};


export type IMutationCopyShapeDataArgs = {
  input: ICopyShapeDataInput;
};


export type IMutationCopyWorkspaceArgs = {
  input: ICopyWorkspaceInput;
};


export type IMutationCreateAnalysisFeedbackArgs = {
  input: ICreateAnalysisFeedbackInput;
};


export type IMutationCreateAnalystQuestionArgs = {
  input: ICreateAnalystQuestionInput;
};


export type IMutationCreateAnalystQuestionClusterArgs = {
  input: ICreateAnalystQuestionClusterInput;
};


export type IMutationCreateAnalystQuestionClusterAssignmentArgs = {
  input: ICreateAnalystQuestionClusterAssignmentInput;
};


export type IMutationCreateChatMessageArgs = {
  input: ICreateChatMessageInput;
};


export type IMutationCreateDocumentArgs = {
  input: ICreateDocumentInput;
};


export type IMutationCreateDocumentAccessControlListArgs = {
  input: ICreateDocumentAccessControlListInput;
};


export type IMutationCreateDocumentContentArgs = {
  input: ICreateDocumentContentInput;
};


export type IMutationCreateFileArgs = {
  input: ICreateFileInput;
};


export type IMutationCreateFileAccessControlListArgs = {
  input: ICreateFileAccessControlListInput;
};


export type IMutationCreateNewConfigKeyArgs = {
  input: INewConfigKeyInput;
};


export type IMutationCreateShapeDataArgs = {
  input: ICreateShapeDataInput;
};


export type IMutationCreateTopicDiscussionExampleArgs = {
  input: ICreateTopicDiscussionExampleInput;
};


export type IMutationCreateTopicDiscussionInNewsArticleArgs = {
  input: ICreateTopicDiscussionInNewsArticleInput;
};


export type IMutationCreateTopicDiscussionSummaryExampleArgs = {
  input: ICreateTopicDiscussionSummaryExampleInput;
};


export type IMutationCreateUserGroupArgs = {
  input: ICreateUserGroupInput;
};


export type IMutationCreateUserGroupMemberArgs = {
  input: ICreateUserGroupMemberInput;
};


export type IMutationCreateUserPreferenceArgs = {
  input: ICreateUserPreferenceInput;
};


export type IMutationCreateWorkspaceArgs = {
  input: ICreateWorkspaceInput;
};


export type IMutationCreateWorkspaceAccessControlListArgs = {
  input: ICreateWorkspaceAccessControlListInput;
};


export type IMutationCreateWorkspaceBoardArgs = {
  input: ICreateWorkspaceBoardInput;
};


export type IMutationCreateWorkspaceFileArgs = {
  input: ICreateWorkspaceFileInput;
};


export type IMutationDeleteAnalysisFeedbackArgs = {
  input: IDeleteAnalysisFeedbackInput;
};


export type IMutationDeleteAnalystQuestionArgs = {
  input: IDeleteAnalystQuestionInput;
};


export type IMutationDeleteAnalystQuestionClusterArgs = {
  input: IDeleteAnalystQuestionClusterInput;
};


export type IMutationDeleteAnalystQuestionClusterAssignmentArgs = {
  input: IDeleteAnalystQuestionClusterAssignmentInput;
};


export type IMutationDeleteConfigKeyArgs = {
  input: IDeleteConfigKeyInput;
};


export type IMutationDeleteDocumentArgs = {
  input: IDeleteDocumentInput;
};


export type IMutationDeleteDocumentAccessControlListArgs = {
  input: IDeleteDocumentAccessControlListInput;
};


export type IMutationDeleteFileArgs = {
  input: IDeleteFileInput;
};


export type IMutationDeleteFileAccessControlListArgs = {
  input: IDeleteFileAccessControlListInput;
};


export type IMutationDeleteInactiveWorkspaceBoardShapeDataArgs = {
  input: IDeleteInactiveWorkspaceBoardShapeDataInput;
};


export type IMutationDeleteTopicDiscussionExampleArgs = {
  input: IDeleteTopicDiscussionExampleInput;
};


export type IMutationDeleteTopicDiscussionInNewsArticleArgs = {
  input: IDeleteTopicDiscussionInNewsArticleInput;
};


export type IMutationDeleteTopicDiscussionSummaryExampleArgs = {
  input: IDeleteTopicDiscussionSummaryExampleInput;
};


export type IMutationDeleteUserGroupArgs = {
  input: IDeleteUserGroupInput;
};


export type IMutationDeleteUserGroupMemberArgs = {
  input: IDeleteUserGroupMemberInput;
};


export type IMutationDeleteUserPreferenceArgs = {
  input: IDeleteUserPreferenceInput;
};


export type IMutationDeleteWorkspaceArgs = {
  input: IDeleteWorkspaceInput;
};


export type IMutationDeleteWorkspaceAccessControlListArgs = {
  input: IDeleteWorkspaceAccessControlListInput;
};


export type IMutationDeleteWorkspaceBoardArgs = {
  input: IDeleteWorkspaceBoardInput;
};


export type IMutationDeleteWorkspaceFileArgs = {
  input: IDeleteWorkspaceFileInput;
};


export type IMutationExecuteCommandArgs = {
  input: IExecuteCommandInput;
};


export type IMutationExecutePingCommandArgs = {
  input: IExecuteCommandInput;
};


export type IMutationMarkWorkspaceBoardShapesAsDeletedArgs = {
  input: IMarkWorkspaceBoardShapeAsDeletedInput;
};


export type IMutationProcessHtmlArgs = {
  analysisOverride?: InputMaybe<IAnalysisResultOverrideType>;
  analysisOverrideConfig?: InputMaybe<Scalars['String']['input']>;
  preset?: InputMaybe<Scalars['String']['input']>;
  sourceDocument: Scalars['String']['input'];
};


export type IMutationReindexWorkspaceArgs = {
  input: IReindexWorkspaceInput;
};


export type IMutationSoftOrHardDeleteChatMessageArgs = {
  input: IDeleteChatMessageInput;
};


export type IMutationUpdateAnalystQuestionArgs = {
  input: IUpdateAnalystQuestionInput;
};


export type IMutationUpdateAnalystQuestionClusterArgs = {
  input: IUpdateAnalystQuestionClusterInput;
};


export type IMutationUpdateAnalystQuestionClusterAssignmentArgs = {
  input: IUpdateAnalystQuestionClusterAssignmentInput;
};


export type IMutationUpdateChatMessageArgs = {
  input: IUpdateChatMessageInput;
};


export type IMutationUpdateConfigKeyArgs = {
  input: IUpdateConfigKeyInput;
};


export type IMutationUpdateDocumentArgs = {
  input: IUpdateDocumentInput;
};


export type IMutationUpdateDocumentAccessControlListArgs = {
  input: IUpdateDocumentAccessControlListInput;
};


export type IMutationUpdateDocumentContentArgs = {
  input: IUpdateDocumentContentInput;
};


export type IMutationUpdateFileArgs = {
  input: IUpdateFileInput;
};


export type IMutationUpdateFileAccessControlListArgs = {
  input: IUpdateFileAccessControlListInput;
};


export type IMutationUpdateShapeDataArgs = {
  input: IUpdateShapeDataInput;
};


export type IMutationUpdateTopicDiscussionExampleArgs = {
  input: IUpdateTopicDiscussionExampleInput;
};


export type IMutationUpdateTopicDiscussionInNewsArticleArgs = {
  input: IUpdateTopicDiscussionInNewsArticleInput;
};


export type IMutationUpdateTopicDiscussionSummaryExampleArgs = {
  input: IUpdateTopicDiscussionSummaryExampleInput;
};


export type IMutationUpdateUserGroupArgs = {
  input: IUpdateUserGroupInput;
};


export type IMutationUpdateUserGroupMemberArgs = {
  input: IUpdateUserGroupMemberInput;
};


export type IMutationUpdateWorkspaceArgs = {
  input: IUpdateWorkspaceInput;
};


export type IMutationUpdateWorkspaceAccessControlListArgs = {
  input: IUpdateWorkspaceAccessControlListInput;
};


export type IMutationUpdateWorkspaceBoardArgs = {
  input: IUpdateWorkspaceBoardInput;
};


export type IMutationUpdateWorkspaceFileArgs = {
  input: IUpdateWorkspaceFileInput;
};


export type IMutationUploadDocumentArgs = {
  file: Scalars['Upload']['input'];
};


export type IMutationUploadDocumentImageArgs = {
  documentId: Scalars['UUID']['input'];
  file: Scalars['Upload']['input'];
};


export type IMutationUploadFileArgs = {
  file: Scalars['Upload']['input'];
};


export type IMutationUploadWorkspaceBoardAssetArgs = {
  file: Scalars['Upload']['input'];
  workspaceBoardId: Scalars['UUID']['input'];
};


export type IMutationUploadWorkspaceFilesArgs = {
  input: IUploadWorkspaceFilesInput;
};


export type IMutationUpsertUserPreferenceArgs = {
  input: IUpsertUserPreferenceInput;
};

export type INewConfigKeyInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type INewSource = {
  __typename?: 'NewSource';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type INewSourceInput = {
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type INewsSummary = {
  __typename?: 'NewsSummary';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  documentSource?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sourceNames?: Maybe<Array<Scalars['String']['output']>>;
  sourceUrls?: Maybe<Array<Scalars['String']['output']>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type INewsSummarySearchResponse = {
  __typename?: 'NewsSummarySearchResponse';
  errors?: Maybe<Array<ISushiCustomError>>;
  results?: Maybe<Array<INewsSummary>>;
  status: Scalars['String']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum IObjectRole {
  /** View and comment */
  Commenter = 'Commenter',
  /** Add, edit, and delete objects */
  ContentManager = 'ContentManager',
  /** Add and edit objects */
  Contributor = 'Contributor',
  /** Manage content, people, and settings */
  Manager = 'Manager',
  /** Full permissions */
  Owner = 'Owner',
  /** View (read) only */
  Viewer = 'Viewer'
}

export enum IOccupationTitle {
  AdvertisingVicePresident = 'AdvertisingVicePresident',
  Chairman = 'Chairman',
  ChiefAdministrativeOfficer = 'ChiefAdministrativeOfficer',
  ChiefDiversityOfficer = 'ChiefDiversityOfficer',
  ChiefExecutiveOfficer = 'ChiefExecutiveOfficer',
  ChiefFinancialOfficer = 'ChiefFinancialOfficer',
  ChiefInformationOfficer = 'ChiefInformationOfficer',
  ChiefInformationSecurityOfficer = 'ChiefInformationSecurityOfficer',
  ChiefInnovationOfficer = 'ChiefInnovationOfficer',
  ChiefLegalOfficer = 'ChiefLegalOfficer',
  ChiefMarketingOfficer = 'ChiefMarketingOfficer',
  ChiefNursingOfficer = 'ChiefNursingOfficer',
  ChiefOperatingOfficer = 'ChiefOperatingOfficer',
  ChiefProductOfficer = 'ChiefProductOfficer',
  ChiefRiskOfficer = 'ChiefRiskOfficer',
  ChiefSecurityOfficer = 'ChiefSecurityOfficer',
  ChiefSustainabilityOfficer = 'ChiefSustainabilityOfficer',
  ChiefTechnologyOfficer = 'ChiefTechnologyOfficer',
  ExecutiveVicePresident = 'ExecutiveVicePresident',
  FinanceVicePresident = 'FinanceVicePresident',
  GeneralCounsel = 'GeneralCounsel',
  InvestorRelationsVicePresident = 'InvestorRelationsVicePresident',
  LogisticsVicePresident = 'LogisticsVicePresident',
  MarketingVicePresident = 'MarketingVicePresident',
  OperationsVicePresident = 'OperationsVicePresident',
  Other = 'Other',
  President = 'President',
  SalesAndMarketingVicePresident = 'SalesAndMarketingVicePresident',
  SalesVicePresident = 'SalesVicePresident',
  SeniorVicePresident = 'SeniorVicePresident',
  VicePresident = 'VicePresident'
}

export type IOrganization = {
  __typename?: 'Organization';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type IOrganizationOrderBy = {
  createdAt?: InputMaybe<ISortOrder>;
  name?: InputMaybe<ISortOrder>;
  updatedAt?: InputMaybe<ISortOrder>;
};

export type IPressRelease = {
  __typename?: 'PressRelease';
  fullText: Scalars['String']['output'];
  fullTextHtml: Scalars['String']['output'];
  fullTextHtmlUrl: Scalars['String']['output'];
  headline: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  itemDate: Scalars['Date']['output'];
  symbols: Array<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type IPressReleaseSearchResponse = {
  __typename?: 'PressReleaseSearchResponse';
  errors?: Maybe<Array<ISushiCustomError>>;
  results?: Maybe<Array<IPressRelease>>;
  status: Scalars['String']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type IProcessHtmlResponseType = {
  __typename?: 'ProcessHtmlResponseType';
  resultDocument: Scalars['String']['output'];
};

export enum IPubSubMutationType {
  Create = 'Create',
  Delete = 'Delete',
  Update = 'Update'
}

export type IQuery = {
  __typename?: 'Query';
  AllConfigVersions?: Maybe<Array<IConfigs>>;
  AllConfigs: Array<IConfigs>;
  Config?: Maybe<IConfigs>;
  ConfigsAggregate: IConfigsAggregate;
  ConfigsDistinctValues: Array<IConfigs>;
  LatestConfig?: Maybe<IConfigs>;
  LatestConfigsByPrefix?: Maybe<Array<IConfigs>>;
  analysisFeedback?: Maybe<IAnalysisFeedback>;
  analysisFeedbacks: Array<IAnalysisFeedback>;
  analystQuestion?: Maybe<IAnalystQuestion>;
  analystQuestionAggregate: IAnalystQuestionAggregate;
  analystQuestionArrayFieldDistinctValues?: Maybe<IAnalystQuestionArrayFieldAggregate>;
  analystQuestionArrayFieldGroup?: Maybe<Array<IAnalystQuestionArrayFieldGroupType>>;
  analystQuestionCluster?: Maybe<IAnalystQuestionCluster>;
  analystQuestionClusterAssignment?: Maybe<IAnalystQuestionClusterAssignment>;
  analystQuestionClusterAssignments: Array<IAnalystQuestionClusterAssignment>;
  analystQuestionClusters: Array<IAnalystQuestionCluster>;
  analystQuestionFieldDistinctValues?: Maybe<Array<IAnalystQuestion>>;
  analystQuestionFieldGroup?: Maybe<Array<IAnalystQuestionFieldGroupType>>;
  analystQuestions: Array<IAnalystQuestion>;
  chatMessage?: Maybe<IChatMessage>;
  chatMessages: Array<IChatMessage>;
  distilledTranscript?: Maybe<IDistilledTranscript>;
  distilledTranscriptAggregate: IDistilledTranscriptAggregate;
  distilledTranscripts?: Maybe<IDistilledTranscriptSearchResponse>;
  document?: Maybe<IDocument>;
  documentAccessControlList?: Maybe<IDocumentAccessControlList>;
  documentAccessControlListByObject: Array<IDocumentAccessControlList>;
  documentAccessControlListByObjectAggregate: IDocumentAccessControlListAggregate;
  documentAccessControlListUserRoles: IDocumentAccessControlListUserRoles;
  documentAggregate: IDocumentAggregate;
  documentContent?: Maybe<IDocumentContent>;
  documents: Array<IDocument>;
  file?: Maybe<IFile>;
  fileAccessControlList?: Maybe<IFileAccessControlList>;
  fileAccessControlListByObject: Array<IFileAccessControlList>;
  fileAccessControlListByObjectAggregate: IFileAccessControlListAggregate;
  fileAccessControlListUserRoles: IFileAccessControlListUserRoles;
  fileAggregate: IFileAggregate;
  files: Array<IFile>;
  groupedAnalystQuestions: Array<IGroupedAnalystQuestion>;
  imageToken: IImageTokenResultType;
  linkProps?: Maybe<ILinkPropsType>;
  newsSummary?: Maybe<INewsSummarySearchResponse>;
  organization?: Maybe<IOrganization>;
  organizations?: Maybe<Array<IOrganization>>;
  pressRelease?: Maybe<IPressRelease>;
  shapeData?: Maybe<IShapeData>;
  testFilter: ITestFilter;
  theme?: Maybe<ITheme>;
  themeDiscussionAnalyses?: Maybe<IThemeDiscussionAnalysisSearchResponse>;
  themeDiscussionAnalysis?: Maybe<IThemeDiscussionAnalysis>;
  themes?: Maybe<IThemeSearchResponse>;
  topicDiscussionExample?: Maybe<ITopicDiscussionExample>;
  topicDiscussionExampleAggregate: ITopicDiscussionExampleAggregate;
  topicDiscussionExampleEventAggregate: IMinMaxAggregate;
  topicDiscussionExampleFieldDistinctValues?: Maybe<Array<ITopicDiscussionExample>>;
  topicDiscussionExampleFieldGroup?: Maybe<Array<ITopicDiscussionExampleFieldGroupType>>;
  topicDiscussionExampleQuestionCountsAggregate: IMinMaxAggregate;
  topicDiscussionExamples: Array<ITopicDiscussionExample>;
  topicDiscussionInNewsArticle?: Maybe<ITopicDiscussionInNewsArticle>;
  topicDiscussionInNewsArticleAggregate: ITopicDiscussionInNewsArticleAggregate;
  topicDiscussionInNewsArticleFieldDistinctValues?: Maybe<Array<ITopicDiscussionInNewsArticle>>;
  topicDiscussionInNewsArticleFieldGroup?: Maybe<Array<ITopicDiscussionInNewsArticleFieldGroupType>>;
  topicDiscussionInNewsArticles: Array<ITopicDiscussionInNewsArticle>;
  topicDiscussionSummaryExample?: Maybe<ITopicDiscussionSummaryExample>;
  topicDiscussionSummaryExampleAggregate: ITopicDiscussionSummaryExampleAggregate;
  topicDiscussionSummaryExampleEventAggregate: IMinMaxAggregate;
  topicDiscussionSummaryExampleFieldDistinctValues?: Maybe<Array<ITopicDiscussionSummaryExample>>;
  topicDiscussionSummaryExampleFieldGroup?: Maybe<Array<ITopicDiscussionSummaryExampleFieldGroupType>>;
  topicDiscussionSummaryExampleQuestionMentionCountsAggregate: IMinMaxAggregate;
  topicDiscussionSummaryExamples: Array<ITopicDiscussionSummaryExample>;
  transcript?: Maybe<ITranscript>;
  transcriptAggregate: ITranscriptAggregate;
  transcripts?: Maybe<ITranscriptSearchResponse>;
  user?: Maybe<IUser>;
  userGroup?: Maybe<IUserGroup>;
  userGroupAggregate: IUserGroupAggregate;
  userGroupMember?: Maybe<IUserGroupMember>;
  userGroupMemberAggregate: IUserGroupMemberAggregate;
  userGroupMembers: Array<IUserGroupMember>;
  userGroups: Array<IUserGroup>;
  userPreference?: Maybe<IUserPreference>;
  users?: Maybe<Array<IUser>>;
  workspace?: Maybe<IWorkspace>;
  workspaceAccessControlList?: Maybe<IWorkspaceAccessControlList>;
  workspaceAccessControlListByObject: Array<IWorkspaceAccessControlList>;
  workspaceAccessControlListByObjectAggregate: IWorkspaceAccessControlListAggregate;
  workspaceAccessControlListUserRoles: IWorkspaceAccessControlListUserRoles;
  workspaceAggregate: IWorkspaceAggregate;
  workspaceBoard?: Maybe<IWorkspaceBoard>;
  workspaceBoardAccessControlListUserRoles: IWorkspaceAccessControlListUserRoles;
  workspaceBoardAggregate: IWorkspaceBoardAggregate;
  workspaceBoardShape?: Maybe<Scalars['JSONObject']['output']>;
  workspaceBoardShapes: Array<Maybe<Scalars['JSONObject']['output']>>;
  workspaceBoardTags: Array<Scalars['String']['output']>;
  workspaceBoards: Array<IWorkspaceBoard>;
  workspaceFile?: Maybe<IWorkspaceFile>;
  workspaceFileAggregate: IWorkspaceFileAggregate;
  workspaceFiles: Array<IWorkspaceFile>;
  workspaceTags: Array<Scalars['String']['output']>;
  workspaces: Array<IWorkspace>;
};


export type IQueryAllConfigVersionsArgs = {
  key: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type IQueryAllConfigsArgs = {
  key?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IConfigsOrderBy>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};


export type IQueryConfigArgs = {
  key: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type IQueryConfigsAggregateArgs = {
  key?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};


export type IQueryConfigsDistinctValuesArgs = {
  field: Scalars['String']['input'];
  key?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};


export type IQueryLatestConfigArgs = {
  key: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type IQueryLatestConfigsByPrefixArgs = {
  keyPrefix: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};


export type IQueryAnalysisFeedbackArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryAnalystQuestionArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryAnalystQuestionAggregateArgs = {
  facets?: InputMaybe<IAnalystQuestionFacetsInput>;
  field: Scalars['String']['input'];
};


export type IQueryAnalystQuestionArrayFieldDistinctValuesArgs = {
  facets?: InputMaybe<IAnalystQuestionFacetsInput>;
  field: Scalars['String']['input'];
};


export type IQueryAnalystQuestionArrayFieldGroupArgs = {
  facets?: InputMaybe<IAnalystQuestionFacetsInput>;
  field: Scalars['String']['input'];
};


export type IQueryAnalystQuestionClusterArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryAnalystQuestionClusterAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryAnalystQuestionClusterAssignmentsArgs = {
  analystQuestionClusterId?: InputMaybe<Scalars['ID']['input']>;
  analystQuestionId?: InputMaybe<Scalars['ID']['input']>;
};


export type IQueryAnalystQuestionFieldDistinctValuesArgs = {
  facets?: InputMaybe<IAnalystQuestionFacetsInput>;
  field: Scalars['String']['input'];
};


export type IQueryAnalystQuestionFieldGroupArgs = {
  facets?: InputMaybe<IAnalystQuestionFacetsInput>;
  field: Scalars['String']['input'];
};


export type IQueryAnalystQuestionsArgs = {
  facets?: InputMaybe<IAnalystQuestionFacetsInput>;
  numberOfClusters?: InputMaybe<Scalars['Int']['input']>;
  otherGroupLabel?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};


export type IQueryChatMessageArgs = {
  channelType: IChatMessageChannelType;
  id: Scalars['ID']['input'];
};


export type IQueryChatMessagesArgs = {
  channelId: Scalars['ID']['input'];
  channelType: IChatMessageChannelType;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};


export type IQueryDistilledTranscriptArgs = {
  transcriptId: Scalars['ID']['input'];
};


export type IQueryDistilledTranscriptsArgs = {
  filters?: InputMaybe<IDistilledTranscriptFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<IDistilledTranscriptOrderBy>>>;
};


export type IQueryDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryDocumentAccessControlListArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryDocumentAccessControlListByObjectArgs = {
  documentId: Scalars['ID']['input'];
};


export type IQueryDocumentAccessControlListByObjectAggregateArgs = {
  documentId: Scalars['ID']['input'];
};


export type IQueryDocumentAccessControlListUserRolesArgs = {
  documentId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type IQueryDocumentContentArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryDocumentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IDocumentOrderBy>;
};


export type IQueryFileArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryFileAccessControlListArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryFileAccessControlListByObjectArgs = {
  fileId: Scalars['ID']['input'];
};


export type IQueryFileAccessControlListByObjectAggregateArgs = {
  fileId: Scalars['ID']['input'];
};


export type IQueryFileAccessControlListUserRolesArgs = {
  fileId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type IQueryFilesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IFileOrderBy>;
};


export type IQueryGroupedAnalystQuestionsArgs = {
  facets?: InputMaybe<IAnalystQuestionFacetsInput>;
  numberOfClusters?: InputMaybe<Scalars['Int']['input']>;
  otherGroupLabel?: InputMaybe<Scalars['String']['input']>;
  version: Scalars['String']['input'];
};


export type IQueryLinkPropsArgs = {
  url: Scalars['String']['input'];
};


export type IQueryNewsSummaryArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IResultsOrderBy>;
  queryText: Scalars['String']['input'];
};


export type IQueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryOrganizationsArgs = {
  fetchFromAstra?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IOrganizationOrderBy>;
};


export type IQueryPressReleaseArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryShapeDataArgs = {
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['String']['input'];
};


export type IQueryThemeArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryThemeDiscussionAnalysesArgs = {
  filters?: InputMaybe<IThemeDiscussionAnalysisFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<IThemeDiscussionAnalysisOrderBy>>>;
};


export type IQueryThemeDiscussionAnalysisArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryThemesArgs = {
  filters?: InputMaybe<IThemeFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<IThemeOrderBy>>>;
};


export type IQueryTopicDiscussionExampleArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryTopicDiscussionExampleAggregateArgs = {
  facets?: InputMaybe<ITopicDiscussionExampleFacetsInput>;
  field: Scalars['String']['input'];
};


export type IQueryTopicDiscussionExampleEventAggregateArgs = {
  facets?: InputMaybe<ITopicDiscussionExampleFacetsInput>;
};


export type IQueryTopicDiscussionExampleFieldDistinctValuesArgs = {
  facets?: InputMaybe<ITopicDiscussionExampleFacetsInput>;
  field: Scalars['String']['input'];
};


export type IQueryTopicDiscussionExampleFieldGroupArgs = {
  facets?: InputMaybe<ITopicDiscussionExampleFacetsInput>;
  field: Scalars['String']['input'];
};


export type IQueryTopicDiscussionExampleQuestionCountsAggregateArgs = {
  facets?: InputMaybe<ITopicDiscussionExampleFacetsInput>;
};


export type IQueryTopicDiscussionExamplesArgs = {
  facets?: InputMaybe<ITopicDiscussionExampleFacetsInput>;
};


export type IQueryTopicDiscussionInNewsArticleArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryTopicDiscussionInNewsArticleAggregateArgs = {
  facets?: InputMaybe<ITopicDiscussionInNewsArticleFacetsInput>;
  field: Scalars['String']['input'];
};


export type IQueryTopicDiscussionInNewsArticleFieldDistinctValuesArgs = {
  facets?: InputMaybe<ITopicDiscussionInNewsArticleFacetsInput>;
  field: Scalars['String']['input'];
};


export type IQueryTopicDiscussionInNewsArticleFieldGroupArgs = {
  facets?: InputMaybe<ITopicDiscussionInNewsArticleFacetsInput>;
  field: Scalars['String']['input'];
};


export type IQueryTopicDiscussionInNewsArticlesArgs = {
  facets?: InputMaybe<ITopicDiscussionInNewsArticleFacetsInput>;
};


export type IQueryTopicDiscussionSummaryExampleArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryTopicDiscussionSummaryExampleAggregateArgs = {
  facets?: InputMaybe<ITopicDiscussionSummaryExampleFacetsInput>;
  field: Scalars['String']['input'];
};


export type IQueryTopicDiscussionSummaryExampleEventAggregateArgs = {
  facets?: InputMaybe<ITopicDiscussionSummaryExampleFacetsInput>;
};


export type IQueryTopicDiscussionSummaryExampleFieldDistinctValuesArgs = {
  facets?: InputMaybe<ITopicDiscussionSummaryExampleFacetsInput>;
  field: Scalars['String']['input'];
};


export type IQueryTopicDiscussionSummaryExampleFieldGroupArgs = {
  facets?: InputMaybe<ITopicDiscussionSummaryExampleFacetsInput>;
  field: Scalars['String']['input'];
};


export type IQueryTopicDiscussionSummaryExampleQuestionMentionCountsAggregateArgs = {
  facets?: InputMaybe<ITopicDiscussionSummaryExampleFacetsInput>;
};


export type IQueryTopicDiscussionSummaryExamplesArgs = {
  facets?: InputMaybe<ITopicDiscussionSummaryExampleFacetsInput>;
};


export type IQueryTranscriptArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryTranscriptsArgs = {
  filters?: InputMaybe<ITranscriptFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ITranscriptOrderBy>>>;
};


export type IQueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryUserGroupArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryUserGroupMemberArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type IQueryUserGroupMemberAggregateArgs = {
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type IQueryUserGroupMembersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IUserGroupMemberOrderBy>;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type IQueryUserGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IUserGroupOrderBy>;
};


export type IQueryUserPreferenceArgs = {
  key: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type IQueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IUserOrderBy>;
};


export type IQueryWorkspaceArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryWorkspaceAccessControlListArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryWorkspaceAccessControlListByObjectArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type IQueryWorkspaceAccessControlListByObjectAggregateArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type IQueryWorkspaceAccessControlListUserRolesArgs = {
  userId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
};


export type IQueryWorkspaceAggregateArgs = {
  filters?: InputMaybe<IWorkspaceFilters>;
};


export type IQueryWorkspaceBoardArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryWorkspaceBoardAccessControlListUserRolesArgs = {
  userId: Scalars['ID']['input'];
  workspaceBoardId: Scalars['ID']['input'];
};


export type IQueryWorkspaceBoardAggregateArgs = {
  filters?: InputMaybe<IWorkspaceBoardFilters>;
  workspaceId: Scalars['ID']['input'];
};


export type IQueryWorkspaceBoardShapeArgs = {
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['ID']['input'];
};


export type IQueryWorkspaceBoardShapesArgs = {
  shapeIds: Array<Scalars['String']['input']>;
  workspaceBoardId: Scalars['ID']['input'];
};


export type IQueryWorkspaceBoardTagsArgs = {
  workspaceId: Scalars['ID']['input'];
};


export type IQueryWorkspaceBoardsArgs = {
  filters?: InputMaybe<IWorkspaceBoardFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IWorkspaceBoardOrderBy>;
  workspaceId: Scalars['ID']['input'];
};


export type IQueryWorkspaceFileArgs = {
  id: Scalars['ID']['input'];
};


export type IQueryWorkspaceFileAggregateArgs = {
  filters?: InputMaybe<IWorkspaceFileFilters>;
  workspaceId: Scalars['ID']['input'];
};


export type IQueryWorkspaceFilesArgs = {
  filters?: InputMaybe<IWorkspaceFileFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IWorkspaceFileOrderBy>;
  workspaceId: Scalars['ID']['input'];
};


export type IQueryWorkspacesArgs = {
  filters?: InputMaybe<IWorkspaceFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IWorkspaceOrderBy>;
};

export type IReindexWorkspaceInput = {
  id: Scalars['ID']['input'];
};

export type IReindexWorkspaceResult = {
  __typename?: 'ReindexWorkspaceResult';
  id: Scalars['ID']['output'];
};

export type IResultsOrderBy = {
  createdAt?: InputMaybe<ISortOrder>;
  name?: InputMaybe<ISortOrder>;
};

export enum IRowState {
  /** Shape data is in active use by the shape */
  Active = 'Active',
  /** Shape data is no longer in use */
  Deleted = 'Deleted'
}

export type IScoreItemOverrideElementType = {
  ancestorNames?: InputMaybe<Array<Scalars['String']['input']>>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  indent?: InputMaybe<Scalars['Int']['input']>;
  isAllUppercase?: InputMaybe<Scalars['Boolean']['input']>;
  isBold?: InputMaybe<Scalars['Boolean']['input']>;
  isItalic?: InputMaybe<Scalars['Boolean']['input']>;
  isUnderline?: InputMaybe<Scalars['Boolean']['input']>;
  styles: Scalars['JSON']['input'];
  textLength?: InputMaybe<Scalars['Int']['input']>;
  textStyle?: InputMaybe<Scalars['String']['input']>;
};

export type IScoreItemOverrideType = {
  element: IScoreItemOverrideElementType;
  score: Scalars['Int']['input'];
};

export type IShapeData = {
  __typename?: 'ShapeData';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  data: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rowState: IRowState;
  shapeId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  workspaceBoardId: Scalars['ID']['output'];
};

export enum ISortOrder {
  /** Ascending order */
  Asc = 'asc',
  /** Descending order */
  Desc = 'desc'
}

export type ISpeaker = {
  __typename?: 'Speaker';
  fullName: Scalars['String']['output'];
  role: Scalars['String']['output'];
};

export type ISpeakerInput = {
  fullName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type ISubscription = {
  __typename?: 'Subscription';
  commandResponse?: Maybe<ICommandResponse>;
  onChatMessageAdded?: Maybe<IChatMessage>;
  onChatMessageDeleted?: Maybe<IDeleteResult>;
  onChatMessageUpdated?: Maybe<IChatMessage>;
  onWorkspaceFileAdded?: Maybe<IWorkspaceFileUploadStatus>;
};


export type ISubscriptionCommandResponseArgs = {
  sessionId: Scalars['String']['input'];
};


export type ISubscriptionOnChatMessageAddedArgs = {
  channelId: Scalars['ID']['input'];
  channelType: IChatMessageChannelType;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};


export type ISubscriptionOnChatMessageDeletedArgs = {
  channelId: Scalars['ID']['input'];
  channelType: IChatMessageChannelType;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};


export type ISubscriptionOnChatMessageUpdatedArgs = {
  channelId: Scalars['ID']['input'];
  channelType: IChatMessageChannelType;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};


export type ISubscriptionOnWorkspaceFileAddedArgs = {
  workspaceId: Scalars['ID']['input'];
};

export type ISubscriptionChatMessageMutationEvent = ISubscriptionEvent & {
  __typename?: 'SubscriptionChatMessageMutationEvent';
  chatMessage?: Maybe<IChatMessage>;
  mutationType: IPubSubMutationType;
};

export type ISubscriptionEvent = {
  mutationType: IPubSubMutationType;
};

export type ISushiCustomError = {
  __typename?: 'SushiCustomError';
  message?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

export type ITestFilter = {
  __typename?: 'TestFilter';
  displayName: Scalars['String']['output'];
};

export type ITheme = {
  __typename?: 'Theme';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type IThemeDiscussionAnalysis = {
  __typename?: 'ThemeDiscussionAnalysis';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  discussionDepth: Scalars['Float']['output'];
  discussionNature: Array<IDiscussionNature>;
  displaySymbol: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  itemDate: Scalars['Date']['output'];
  itemId: Scalars['String']['output'];
  itemTitle: Scalars['String']['output'];
  participants: Array<ITranscriptParticipant>;
  section: Scalars['String']['output'];
  summaries: Array<Scalars['String']['output']>;
  symbols: Array<Scalars['String']['output']>;
  themeId: Scalars['String']['output'];
  themeName: Scalars['String']['output'];
  topics: Array<Scalars['String']['output']>;
  topicsAnalysisItems: Array<ITopicsAnalysis>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type IThemeDiscussionAnalysisFilters = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  itemId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  symbols?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IThemeDiscussionAnalysisOrderBy = {
  itemDate?: InputMaybe<ISortOrder>;
  itemTitle?: InputMaybe<ISortOrder>;
  symbol?: InputMaybe<ISortOrder>;
};

export type IThemeDiscussionAnalysisSearchResponse = {
  __typename?: 'ThemeDiscussionAnalysisSearchResponse';
  errors?: Maybe<Array<ISushiCustomError>>;
  results?: Maybe<Array<IThemeDiscussionAnalysis>>;
  status: Scalars['String']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type IThemeFilters = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type IThemeOrderBy = {
  name?: InputMaybe<ISortOrder>;
};

export type IThemeSearchResponse = {
  __typename?: 'ThemeSearchResponse';
  errors?: Maybe<Array<ISushiCustomError>>;
  results?: Maybe<Array<ITheme>>;
  status: Scalars['String']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ITopicDiscussionExample = {
  __typename?: 'TopicDiscussionExample';
  category?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  detail: Scalars['String']['output'];
  event: Scalars['String']['output'];
  eventDate: Scalars['Date']['output'];
  eventType?: Maybe<Scalars['String']['output']>;
  explanation?: Maybe<Scalars['String']['output']>;
  firm?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  period?: Maybe<Scalars['String']['output']>;
  quarter?: Maybe<Scalars['Int']['output']>;
  question: Scalars['String']['output'];
  rating?: Maybe<Scalars['Float']['output']>;
  reportTitle?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  section?: Maybe<Scalars['String']['output']>;
  segmentId?: Maybe<Scalars['String']['output']>;
  segmentType?: Maybe<Scalars['String']['output']>;
  sourceFormat?: Maybe<Scalars['String']['output']>;
  sourceId?: Maybe<Scalars['String']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  sourceUrl?: Maybe<Scalars['String']['output']>;
  /** Array of speaker info */
  speakers?: Maybe<Array<ISpeaker>>;
  summary: Scalars['String']['output'];
  ticker: Scalars['String']['output'];
  topic: Scalars['String']['output'];
  turn?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  year?: Maybe<Scalars['Int']['output']>;
};

export type ITopicDiscussionExampleAggregate = {
  __typename?: 'TopicDiscussionExampleAggregate';
  max?: Maybe<Scalars['Date']['output']>;
  min?: Maybe<Scalars['Date']['output']>;
};

export type ITopicDiscussionExampleFacetsInput = {
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  eventDate?: InputMaybe<IDateRangeInput>;
  section?: InputMaybe<Array<Scalars['String']['input']>>;
  ticker?: InputMaybe<Array<Scalars['String']['input']>>;
  topic?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ITopicDiscussionExampleFieldGroupType = {
  __typename?: 'TopicDiscussionExampleFieldGroupType';
  category?: Maybe<Scalars['String']['output']>;
  count: Scalars['Int']['output'];
  section?: Maybe<Scalars['String']['output']>;
  ticker?: Maybe<Scalars['String']['output']>;
  topic?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ITopicDiscussionInNewsArticle = {
  __typename?: 'TopicDiscussionInNewsArticle';
  articleCount: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  date: Scalars['Date']['output'];
  headline: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  /** Array of new source info */
  newSources: Array<INewSource>;
  paragraph: Scalars['String']['output'];
  segmentId: Scalars['String']['output'];
  segmentType: Scalars['String']['output'];
  sourceType: Scalars['String']['output'];
  theme: Scalars['String']['output'];
  /** Array of topic discussion info */
  topicDiscussions: Array<IITopicDiscussion>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type ITopicDiscussionInNewsArticleAggregate = {
  __typename?: 'TopicDiscussionInNewsArticleAggregate';
  max?: Maybe<Scalars['Date']['output']>;
  min?: Maybe<Scalars['Date']['output']>;
};

export type ITopicDiscussionInNewsArticleFacetsInput = {
  date?: InputMaybe<IDateRangeInput>;
  theme?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ITopicDiscussionInNewsArticleFieldGroupType = {
  __typename?: 'TopicDiscussionInNewsArticleFieldGroupType';
  count: Scalars['Int']['output'];
  theme?: Maybe<Scalars['String']['output']>;
};

export type ITopicDiscussionInput = {
  summary: Scalars['String']['input'];
  topic: Scalars['String']['input'];
};

export type ITopicDiscussionSummaryExample = {
  __typename?: 'TopicDiscussionSummaryExample';
  analysisName: Scalars['String']['output'];
  category: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  detail: Scalars['String']['output'];
  event: Scalars['String']['output'];
  eventDate: Scalars['Date']['output'];
  eventType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  period?: Maybe<Scalars['String']['output']>;
  quarter?: Maybe<Scalars['Int']['output']>;
  questionMentionCounts: Array<Scalars['Int']['output']>;
  questions: Array<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  section: Scalars['String']['output'];
  sourceFormat: Scalars['String']['output'];
  sourceId: Scalars['String']['output'];
  sourceUrl: Scalars['String']['output'];
  /** Array of speaker info */
  speakers: Array<ISpeaker>;
  summary: Scalars['String']['output'];
  ticker: Scalars['String']['output'];
  topic: Scalars['String']['output'];
  topicMentionCount: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  year: Scalars['Int']['output'];
};

export type ITopicDiscussionSummaryExampleAggregate = {
  __typename?: 'TopicDiscussionSummaryExampleAggregate';
  max?: Maybe<Scalars['Date']['output']>;
  min?: Maybe<Scalars['Date']['output']>;
};

export type ITopicDiscussionSummaryExampleFacetsInput = {
  analysisName?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<Array<Scalars['String']['input']>>;
  event?: InputMaybe<Array<Scalars['String']['input']>>;
  eventDate?: InputMaybe<IDateRangeInput>;
  eventType?: InputMaybe<Array<Scalars['String']['input']>>;
  period?: InputMaybe<Array<Scalars['String']['input']>>;
  quarter?: InputMaybe<Array<Scalars['Int']['input']>>;
  rating?: InputMaybe<Array<Scalars['Float']['input']>>;
  section?: InputMaybe<Array<Scalars['String']['input']>>;
  ticker?: InputMaybe<Array<Scalars['String']['input']>>;
  topic?: InputMaybe<Array<Scalars['String']['input']>>;
  year?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ITopicDiscussionSummaryExampleFieldGroupType = {
  __typename?: 'TopicDiscussionSummaryExampleFieldGroupType';
  analysisName?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  count: Scalars['Int']['output'];
  event?: Maybe<Scalars['String']['output']>;
  eventType?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  quarter?: Maybe<Scalars['Int']['output']>;
  rating?: Maybe<Scalars['Float']['output']>;
  section?: Maybe<Scalars['String']['output']>;
  ticker?: Maybe<Scalars['String']['output']>;
  topic?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type ITopicsAnalysis = {
  __typename?: 'TopicsAnalysis';
  discussionDepth: Scalars['Float']['output'];
  discussionNature: Array<IDiscussionNature>;
  participants: Array<ITranscriptParticipant>;
  topic: Scalars['String']['output'];
};

export type ITranscript = {
  __typename?: 'Transcript';
  audioUrl: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  deduplicationId: Scalars['String']['output'];
  displaySymbol: Scalars['String']['output'];
  eventType: ITranscriptEventType;
  id: Scalars['ID']['output'];
  importSourceDeduplicationId: Scalars['String']['output'];
  itemDate: Scalars['Date']['output'];
  participants: Array<ITranscriptParticipant>;
  quarter: Scalars['Int']['output'];
  symbols: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  transcriptItems: Array<ITranscriptItem>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  year: Scalars['Int']['output'];
};

export type ITranscriptAggregate = {
  __typename?: 'TranscriptAggregate';
  maxItemDate?: Maybe<Scalars['String']['output']>;
  minItemDate?: Maybe<Scalars['String']['output']>;
};

export enum ITranscriptEventType {
  Conference = 'Conference',
  EarningsCall = 'EarningsCall',
  Other = 'Other',
  ShareholderMeeting = 'ShareholderMeeting'
}

export type ITranscriptFilters = {
  deduplicationId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  importSourceDeduplicationId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  symbols?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ITranscriptItem = {
  __typename?: 'TranscriptItem';
  fullName: Scalars['String']['output'];
  itemIndex: Scalars['Int']['output'];
  occupationTitles: Array<IOccupationTitle>;
  role: ITranscriptRole;
  session: ITranscriptSession;
  speech: Array<Scalars['String']['output']>;
};

export type ITranscriptOrderBy = {
  displaySymbol?: InputMaybe<ISortOrder>;
  itemDate?: InputMaybe<ISortOrder>;
  quarter?: InputMaybe<ISortOrder>;
  title?: InputMaybe<ISortOrder>;
  year?: InputMaybe<ISortOrder>;
};

export type ITranscriptParticipant = {
  __typename?: 'TranscriptParticipant';
  description: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  occupationTitles: Array<IOccupationTitle>;
  role: ITranscriptRole;
};

export enum ITranscriptRole {
  Analyst = 'Analyst',
  Executive = 'Executive',
  Operator = 'Operator',
  Other = 'Other'
}

export type ITranscriptSearchResponse = {
  __typename?: 'TranscriptSearchResponse';
  errors?: Maybe<Array<ISushiCustomError>>;
  results?: Maybe<Array<ITranscript>>;
  status: Scalars['String']['output'];
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum ITranscriptSession {
  ManagementDiscussion = 'ManagementDiscussion',
  Other = 'Other',
  QuestionAnswer = 'QuestionAnswer'
}

export type IUpdateAnalystQuestionClusterAssignmentInput = {
  analystQuestionClusterId?: InputMaybe<Scalars['ID']['input']>;
  analystQuestionId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  score?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type IUpdateAnalystQuestionClusterInput = {
  id: Scalars['ID']['input'];
  idealizedQuestion?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type IUpdateAnalystQuestionInput = {
  eventDate?: InputMaybe<Scalars['Date']['input']>;
  firm?: InputMaybe<Scalars['String']['input']>;
  firmType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  segment?: InputMaybe<Array<Scalars['String']['input']>>;
  topics?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IUpdateChatMessageInput = {
  channelType: IChatMessageChannelType;
  id: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type IUpdateConfigKeyInput = {
  data: Scalars['String']['input'];
  key: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type IUpdateDocumentAccessControlListInput = {
  id: Scalars['ID']['input'];
  role: IObjectRole;
};

export type IUpdateDocumentContentInput = {
  content: Scalars['String']['input'];
  contentType?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
};

export type IUpdateDocumentInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type IUpdateFileAccessControlListInput = {
  id: Scalars['ID']['input'];
  role: IObjectRole;
};

export type IUpdateFileInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type IUpdateShapeDataInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  rowState?: InputMaybe<IRowState>;
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['String']['input'];
};

export type IUpdateTopicDiscussionExampleInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  eventDate?: InputMaybe<Scalars['Date']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  explanation?: InputMaybe<Scalars['String']['input']>;
  firm?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Scalars['Int']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  reportTitle?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Float']['input']>;
  section?: InputMaybe<Scalars['String']['input']>;
  segmentId?: InputMaybe<Scalars['String']['input']>;
  segmentType?: InputMaybe<Scalars['String']['input']>;
  sourceFormat?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<Scalars['String']['input']>;
  sourceUrl?: InputMaybe<Scalars['String']['input']>;
  /** Array of speaker info */
  speakers?: InputMaybe<Array<ISpeakerInput>>;
  summary?: InputMaybe<Scalars['String']['input']>;
  ticker?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<Scalars['String']['input']>;
  turn?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type IUpdateTopicDiscussionInNewsArticleInput = {
  articleCount?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  /** Array of new source info */
  newSources?: InputMaybe<Array<INewSourceInput>>;
  paragraph?: InputMaybe<Scalars['String']['input']>;
  segmentId?: InputMaybe<Scalars['String']['input']>;
  segmentType?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  /** Array of topic discussion info */
  topicDiscussions?: InputMaybe<Array<ITopicDiscussionInput>>;
};

export type IUpdateTopicDiscussionSummaryExampleInput = {
  analysisName?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<Scalars['String']['input']>;
  eventDate?: InputMaybe<Scalars['Date']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  period?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Scalars['Int']['input']>;
  questionMentionCounts?: InputMaybe<Array<Scalars['Int']['input']>>;
  questions?: InputMaybe<Array<Scalars['String']['input']>>;
  rating?: InputMaybe<Scalars['Float']['input']>;
  score?: InputMaybe<Scalars['Float']['input']>;
  section?: InputMaybe<Scalars['String']['input']>;
  sourceFormat?: InputMaybe<Scalars['String']['input']>;
  sourceId?: InputMaybe<Scalars['String']['input']>;
  sourceUrl?: InputMaybe<Scalars['String']['input']>;
  /** Array of speaker info */
  speakers?: InputMaybe<Array<ISpeakerInput>>;
  summary?: InputMaybe<Scalars['String']['input']>;
  ticker?: InputMaybe<Scalars['String']['input']>;
  topic?: InputMaybe<Scalars['String']['input']>;
  topicMentionCount?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type IUpdateUserGroupInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type IUpdateUserGroupMemberInput = {
  id: Scalars['ID']['input'];
  userGroupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type IUpdateWorkspaceAccessControlListInput = {
  id: Scalars['ID']['input'];
  role: IObjectRole;
};

export type IUpdateWorkspaceBoardCameraPositionSubInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type IUpdateWorkspaceBoardInput = {
  cameraPosition?: InputMaybe<IUpdateWorkspaceBoardCameraPositionSubInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isThumbnailLocked?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schema?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['Tag']['input']>>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  zoomLevel?: InputMaybe<Scalars['Float']['input']>;
};

export type IUpdateWorkspaceFileInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type IUpdateWorkspaceInput = {
  defaultWorkspaceBoardId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['Tag']['input']>>;
};

export type IUploadInput = {
  file: Scalars['Upload']['input'];
  fileSize: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
};

export type IUploadWorkspaceFileResponse = {
  __typename?: 'UploadWorkspaceFileResponse';
  failed: Array<IWorkspaceFileFailedFile>;
  succeeded: Array<IWorkspaceFileData>;
};

export type IUploadWorkspaceFilesInput = {
  files: Array<IUploadInput>;
  workspaceId: Scalars['ID']['input'];
};

export type IUpsertUserPreferenceInput = {
  data: Scalars['JSONObject']['input'];
  key: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type IUser = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
};

export type IUserGroup = {
  __typename?: 'UserGroup';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
};

export type IUserGroupAggregate = {
  __typename?: 'UserGroupAggregate';
  count: Scalars['Int']['output'];
};

export type IUserGroupMember = {
  __typename?: 'UserGroupMember';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  user?: Maybe<IUser>;
  userGroup: IUserGroup;
  userId: Scalars['ID']['output'];
};

export type IUserGroupMemberAggregate = {
  __typename?: 'UserGroupMemberAggregate';
  count: Scalars['Int']['output'];
};

export type IUserGroupMemberOrderBy = {
  createdAt?: InputMaybe<ISortOrder>;
  name?: InputMaybe<ISortOrder>;
  updatedAt?: InputMaybe<ISortOrder>;
};

export type IUserGroupOrderBy = {
  createdAt?: InputMaybe<ISortOrder>;
  name?: InputMaybe<ISortOrder>;
  updatedAt?: InputMaybe<ISortOrder>;
};

export type IUserOrderBy = {
  createdAt?: InputMaybe<ISortOrder>;
  email?: InputMaybe<ISortOrder>;
  id?: InputMaybe<ISortOrder>;
  name?: InputMaybe<ISortOrder>;
  updatedAt?: InputMaybe<ISortOrder>;
};

export type IUserPreference = {
  __typename?: 'UserPreference';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  data: Scalars['JSONObject']['output'];
  key: Scalars['ID']['output'];
  organizationId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  userId: Scalars['String']['output'];
};

export type IWorkspace = {
  __typename?: 'Workspace';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  defaultWorkspaceBoard?: Maybe<IWorkspaceBoard>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tags: Array<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  workspaceAccessControlList: Array<IWorkspaceAccessControlList>;
  workspaceBoards: Array<IWorkspaceBoard>;
  workspaceFiles: Array<IWorkspaceFile>;
};

export type IWorkspaceAccessControlList = {
  __typename?: 'WorkspaceAccessControlList';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  role: IObjectRole;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  user?: Maybe<IUser>;
  userGroup?: Maybe<IUserGroup>;
  userId?: Maybe<Scalars['ID']['output']>;
  workspaceId: Scalars['ID']['output'];
};

export type IWorkspaceAccessControlListAggregate = {
  __typename?: 'WorkspaceAccessControlListAggregate';
  count: Scalars['Int']['output'];
};

export type IWorkspaceAccessControlListOrderBy = {
  createdAt?: InputMaybe<ISortOrder>;
  updatedAt?: InputMaybe<ISortOrder>;
};

export type IWorkspaceAccessControlListUserRoles = {
  __typename?: 'WorkspaceAccessControlListUserRoles';
  objectId: Scalars['String']['output'];
  roles: Array<IObjectRole>;
  userId: Scalars['String']['output'];
};

export type IWorkspaceAggregate = {
  __typename?: 'WorkspaceAggregate';
  count: Scalars['Int']['output'];
};

export type IWorkspaceBoard = {
  __typename?: 'WorkspaceBoard';
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isThumbnailLocked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  schema?: Maybe<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  workspaceId: Scalars['ID']['output'];
};

export type IWorkspaceBoardAggregate = {
  __typename?: 'WorkspaceBoardAggregate';
  count: Scalars['Int']['output'];
};

export type IWorkspaceBoardAssetFile = {
  __typename?: 'WorkspaceBoardAssetFile';
  apiDownloadUrl: Scalars['String']['output'];
  assetDownloadUrl: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  storageLocationUrl: Scalars['String']['output'];
};

export type IWorkspaceBoardFilters = {
  includeUntagged?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['Tag']['input']>>;
};

export type IWorkspaceBoardOrderBy = {
  createdAt?: InputMaybe<ISortOrder>;
  name?: InputMaybe<ISortOrder>;
  updatedAt?: InputMaybe<ISortOrder>;
};

export type IWorkspaceFile = {
  __typename?: 'WorkspaceFile';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['ID']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  updatedBy: Scalars['ID']['output'];
  url: Scalars['String']['output'];
  workspaceId: Scalars['ID']['output'];
};

export type IWorkspaceFileAggregate = {
  __typename?: 'WorkspaceFileAggregate';
  count: Scalars['Int']['output'];
};

export type IWorkspaceFileData = {
  __typename?: 'WorkspaceFileData';
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  storageLocation: Scalars['String']['output'];
};

export type IWorkspaceFileFailedFile = {
  __typename?: 'WorkspaceFileFailedFile';
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  storageLocation: Scalars['String']['output'];
};

export type IWorkspaceFileFilters = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type IWorkspaceFileOrderBy = {
  createdAt?: InputMaybe<ISortOrder>;
  name?: InputMaybe<ISortOrder>;
  updatedAt?: InputMaybe<ISortOrder>;
};

export type IWorkspaceFileUploadStatus = {
  __typename?: 'WorkspaceFileUploadStatus';
  error: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  totalSize: Scalars['Int']['output'];
  uploadStatus: Scalars['String']['output'];
  uploadedSize: Scalars['Int']['output'];
  workspaceId: Scalars['ID']['output'];
};

export type IWorkspaceFilters = {
  includeUntagged?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['Tag']['input']>>;
};

export type IWorkspaceOrderBy = {
  createdAt?: InputMaybe<ISortOrder>;
  name?: InputMaybe<ISortOrder>;
  updatedAt?: InputMaybe<ISortOrder>;
};

export type IConfigs = {
  __typename?: 'configs';
  createdAt: Scalars['Date']['output'];
  data: Scalars['String']['output'];
  key: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
  version: Scalars['Int']['output'];
};

export type IEmptyQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type IEmptyQueryQuery = { __typename: 'Query' };

export type IFeedTranscriptsTranscriptQueryVariables = Exact<{
  filters?: InputMaybe<ITranscriptFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ITranscriptOrderBy>> | InputMaybe<ITranscriptOrderBy>>;
}>;


export type IFeedTranscriptsTranscriptQuery = { __typename?: 'Query', transcripts?: { __typename?: 'TranscriptSearchResponse', status: string, totalCount?: number | null, results?: Array<{ __typename?: 'Transcript', id: string, displaySymbol: string, eventType: ITranscriptEventType, itemDate: any, title: string }> | null } | null, transcriptAggregate: { __typename?: 'TranscriptAggregate', minItemDate?: string | null, maxItemDate?: string | null } };

export type IThemeDiscussionAnalysisParentTranscriptQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IThemeDiscussionAnalysisParentTranscriptQuery = { __typename?: 'Query', transcript?: { __typename?: 'Transcript', id: string, displaySymbol: string, eventType: ITranscriptEventType, itemDate: any } | null };

export type IThemeDiscussionAnalysisParentTranscriptsQueryVariables = Exact<{
  filters?: InputMaybe<ITranscriptFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ITranscriptOrderBy>> | InputMaybe<ITranscriptOrderBy>>;
}>;


export type IThemeDiscussionAnalysisParentTranscriptsQuery = { __typename?: 'Query', transcripts?: { __typename?: 'TranscriptSearchResponse', results?: Array<{ __typename?: 'Transcript', id: string, displaySymbol: string, eventType: ITranscriptEventType, itemDate: any }> | null } | null };

export type ICreateAnalysisFeedbackMutationVariables = Exact<{
  input: ICreateAnalysisFeedbackInput;
}>;


export type ICreateAnalysisFeedbackMutation = { __typename?: 'Mutation', createAnalysisFeedback?: { __typename?: 'AnalysisFeedback', id: string } | null };

export type IUpdateAnalystQuestionMutationVariables = Exact<{
  input: IUpdateAnalystQuestionInput;
}>;


export type IUpdateAnalystQuestionMutation = { __typename?: 'Mutation', updateAnalystQuestion: { __typename?: 'AnalystQuestion', id: string, eventDate: any, firm: string, firmType: string, question: string, segment: Array<string>, topics: Array<string> } };

export type IAnalystQuestionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IAnalystQuestionQuery = { __typename?: 'Query', analystQuestion?: { __typename?: 'AnalystQuestion', id: string, eventDate: any, firm: string, firmType: string, question: string, segment: Array<string>, topics: Array<string> } | null };

export type IAnalystQuestionsQueryVariables = Exact<{
  facets: IAnalystQuestionFacetsInput;
  numberOfClusters?: InputMaybe<Scalars['Int']['input']>;
  otherGroupLabel: Scalars['String']['input'];
  version?: InputMaybe<Scalars['String']['input']>;
}>;


export type IAnalystQuestionsQuery = { __typename?: 'Query', analystQuestions: Array<{ __typename?: 'AnalystQuestion', id: string, eventDate: any, firm: string, firmType: string, question: string, segment: Array<string>, topics: Array<string>, group?: string | null, analystQuestionClusterAssignment: Array<{ __typename?: 'AnalystQuestionClusterAssignment', analystQuestionCluster: { __typename?: 'AnalystQuestionCluster', name: string } }> }> };

export type IGroupedAnalystQuestionsQueryVariables = Exact<{
  facets: IAnalystQuestionFacetsInput;
  numberOfClusters: Scalars['Int']['input'];
  otherGroupLabel: Scalars['String']['input'];
  version: Scalars['String']['input'];
}>;


export type IGroupedAnalystQuestionsQuery = { __typename?: 'Query', groupedAnalystQuestions: Array<{ __typename?: 'GroupedAnalystQuestion', id: string, eventDate: any, firm: string, firmType: string, question: string, segment: Array<string>, topics: Array<string>, group: string }> };

export type ICreateChatMessageMutationVariables = Exact<{
  input: ICreateChatMessageInput;
}>;


export type ICreateChatMessageMutation = { __typename?: 'Mutation', createChatMessage: { __typename?: 'ChatMessage', id: string, channelId: string, messageType: IChatMessageType, message: string, privateRecipientIds?: Array<string> | null, createdAt: any, createdBy: string, updatedAt?: any | null, updatedBy?: string | null, attachments: Array<{ __typename?: 'ChatMessageAttachment', mimeType: string, name: string, storageLocationUrl: string }>, mentions: Array<{ __typename?: 'ChatMessageMention', targetId: string, name: string, mentionType: IChatMessageMentionType }>, reactions: Array<{ __typename?: 'ChatMessageReaction', reaction: string, userIds: Array<string> }> } };

export type IUpdateChatMessageMutationVariables = Exact<{
  input: IUpdateChatMessageInput;
}>;


export type IUpdateChatMessageMutation = { __typename?: 'Mutation', updateChatMessage: { __typename?: 'ChatMessage', id: string, message: string, createdAt: any, createdBy: string, updatedAt?: any | null, updatedBy?: string | null, updateCount: number } };

export type IDeleteChatMessageMutationVariables = Exact<{
  input: IDeleteChatMessageInput;
}>;


export type IDeleteChatMessageMutation = { __typename?: 'Mutation', softOrHardDeleteChatMessage: { __typename?: 'DeleteResult', id: string } };

export type IChatMessageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  channelType: IChatMessageChannelType;
}>;


export type IChatMessageQuery = { __typename?: 'Query', chatMessage?: { __typename?: 'ChatMessage', id: string, channelId: string, parentId?: string | null, messageType: IChatMessageType, message: string, privateRecipientIds?: Array<string> | null, createdAt: any, createdBy: string, updatedAt?: any | null, updatedBy?: string | null, deletedAt?: any | null, deletedBy?: string | null, childCount: number, attachments: Array<{ __typename?: 'ChatMessageAttachment', mimeType: string, name: string, storageLocationUrl: string }>, mentions: Array<{ __typename?: 'ChatMessageMention', targetId: string, name: string, mentionType: IChatMessageMentionType }>, reactions: Array<{ __typename?: 'ChatMessageReaction', reaction: string, userIds: Array<string> }>, createdByUser?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null } | null };

export type IChatMessagesQueryVariables = Exact<{
  channelId: Scalars['ID']['input'];
  channelType: IChatMessageChannelType;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type IChatMessagesQuery = { __typename?: 'Query', chatMessages: Array<{ __typename?: 'ChatMessage', id: string, channelId: string, parentId?: string | null, messageType: IChatMessageType, message: string, privateRecipientIds?: Array<string> | null, createdAt: any, createdBy: string, updatedAt?: any | null, updatedBy?: string | null, deletedAt?: any | null, deletedBy?: string | null, childCount: number, attachments: Array<{ __typename?: 'ChatMessageAttachment', mimeType: string, name: string, storageLocationUrl: string }>, mentions: Array<{ __typename?: 'ChatMessageMention', targetId: string, name: string, mentionType: IChatMessageMentionType }>, reactions: Array<{ __typename?: 'ChatMessageReaction', reaction: string, userIds: Array<string> }>, createdByUser?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null }> };

export type IOnChatMessagesAddedSubscriptionVariables = Exact<{
  channelId: Scalars['ID']['input'];
  channelType: IChatMessageChannelType;
  parentId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type IOnChatMessagesAddedSubscription = { __typename?: 'Subscription', onChatMessageAdded?: { __typename?: 'ChatMessage', id: string, channelId: string, parentId?: string | null, messageType: IChatMessageType, message: string, privateRecipientIds?: Array<string> | null, createdAt: any, createdBy: string, updatedAt?: any | null, updatedBy?: string | null, deletedAt?: any | null, deletedBy?: string | null, childCount: number, attachments: Array<{ __typename?: 'ChatMessageAttachment', mimeType: string, name: string, storageLocationUrl: string }>, mentions: Array<{ __typename?: 'ChatMessageMention', targetId: string, name: string, mentionType: IChatMessageMentionType }>, reactions: Array<{ __typename?: 'ChatMessageReaction', reaction: string, userIds: Array<string> }> } | null };

export type IOnChatMessagesUpdatedSubscriptionVariables = Exact<{
  channelId: Scalars['ID']['input'];
  channelType: IChatMessageChannelType;
  parentId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type IOnChatMessagesUpdatedSubscription = { __typename?: 'Subscription', onChatMessageUpdated?: { __typename?: 'ChatMessage', id: string, channelId: string, parentId?: string | null, messageType: IChatMessageType, message: string, privateRecipientIds?: Array<string> | null, createdAt: any, createdBy: string, updatedAt?: any | null, updatedBy?: string | null, deletedAt?: any | null, deletedBy?: string | null, childCount: number, attachments: Array<{ __typename?: 'ChatMessageAttachment', mimeType: string, name: string, storageLocationUrl: string }>, mentions: Array<{ __typename?: 'ChatMessageMention', targetId: string, name: string, mentionType: IChatMessageMentionType }>, reactions: Array<{ __typename?: 'ChatMessageReaction', reaction: string, userIds: Array<string> }> } | null };

export type IOnChatMessagesDeletedSubscriptionVariables = Exact<{
  channelId: Scalars['ID']['input'];
  channelType: IChatMessageChannelType;
  parentId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type IOnChatMessagesDeletedSubscription = { __typename?: 'Subscription', onChatMessageDeleted?: { __typename?: 'DeleteResult', id: string } | null };

export type IExecuteCommandMutationVariables = Exact<{
  input: IExecuteCommandInput;
}>;


export type IExecuteCommandMutation = { __typename?: 'Mutation', executeCommand?: { __typename?: 'CommandResponse', data?: string | null, errors: Array<string>, requestId: any, sessionId: any, status: string } | null };

export type ICommandPingMutationVariables = Exact<{
  input: IExecuteCommandInput;
}>;


export type ICommandPingMutation = { __typename?: 'Mutation', executePingCommand?: { __typename?: 'CommandResponse', data?: string | null, errors: Array<string>, requestId: any, sessionId: any, status: string } | null };

export type IOnCommandResponseSubscriptionVariables = Exact<{
  sessionId: Scalars['String']['input'];
}>;


export type IOnCommandResponseSubscription = { __typename?: 'Subscription', commandResponse?: { __typename?: 'CommandResponse', activeCommandContext: any, callerData?: string | null, data?: string | null, errors: Array<string>, requestId: any, status: string, sessionId: any } | null };

export type IGetLatestConfigsByPrefixQueryVariables = Exact<{
  keyPrefix: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
}>;


export type IGetLatestConfigsByPrefixQuery = { __typename?: 'Query', LatestConfigsByPrefix?: Array<{ __typename?: 'configs', data: string }> | null };

export type IGetConfigDataQueryVariables = Exact<{
  key: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
}>;


export type IGetConfigDataQuery = { __typename?: 'Query', Config?: { __typename?: 'configs', data: string } | null };

export type IGetAppConfigDataQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  baseDocumentStylesheetKey: Scalars['ID']['input'];
  organizationDocumentStylesheetKey: Scalars['ID']['input'];
  organizationPreferencesKey: Scalars['ID']['input'];
}>;


export type IGetAppConfigDataQuery = { __typename?: 'Query', BaseDocumentStylesheet?: { __typename?: 'configs', data: string } | null, OrganizationDocumentStylesheet?: { __typename?: 'configs', data: string } | null, OrganizationPreferences?: { __typename?: 'configs', data: string } | null };

export type IDistilledTranscriptQueryVariables = Exact<{
  transcriptId: Scalars['ID']['input'];
}>;


export type IDistilledTranscriptQuery = { __typename?: 'Query', distilledTranscript?: { __typename?: 'DistilledTranscript', id: string, displaySymbol: string, eventType: ITranscriptEventType, itemDate: any, title: string, distilledSegments: Array<{ __typename?: 'DistilledTranscriptSegment', segmentIndex: number, session: string, distilledTranscriptItems: Array<{ __typename?: 'TranscriptItem', itemIndex: number, fullName: string, occupationTitles: Array<IOccupationTitle>, role: ITranscriptRole, session: ITranscriptSession, speech: Array<string> }>, originalTranscriptItems: Array<{ __typename?: 'TranscriptItem', itemIndex: number, fullName: string, occupationTitles: Array<IOccupationTitle>, role: ITranscriptRole, session: ITranscriptSession, speech: Array<string> }> }> } | null };

export type ICreateDocumentMutationVariables = Exact<{
  input: ICreateDocumentInput;
}>;


export type ICreateDocumentMutation = { __typename?: 'Mutation', createDocument?: { __typename?: 'Document', id: string, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, content: { __typename?: 'DocumentContent', id: string, content: string, contentType: string } } | null };

export type IUpdateDocumentMutationVariables = Exact<{
  input: IUpdateDocumentInput;
}>;


export type IUpdateDocumentMutation = { __typename?: 'Mutation', updateDocument: { __typename?: 'Document', id: string, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type IDeleteDocumentMutationVariables = Exact<{
  input: IDeleteDocumentInput;
}>;


export type IDeleteDocumentMutation = { __typename?: 'Mutation', deleteDocument: { __typename?: 'DeleteResult', id: string } };

export type IDocumentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IDocumentQuery = { __typename?: 'Query', document?: { __typename?: 'Document', id: string, name: string } | null };

export type IDocumentsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IDocumentOrderBy>;
}>;


export type IDocumentsQuery = { __typename?: 'Query', documents: Array<{ __typename?: 'Document', id: string, name: string, documentAccessControlList: Array<{ __typename?: 'DocumentAccessControlList', userGroup?: { __typename?: 'UserGroup', name: string } | null, user?: { __typename?: 'User', id: string, name: string } | null }> }>, documentAggregate: { __typename?: 'DocumentAggregate', count: number } };

export type ICreateDocumentAccessControlListMutationVariables = Exact<{
  input: ICreateDocumentAccessControlListInput;
}>;


export type ICreateDocumentAccessControlListMutation = { __typename?: 'Mutation', createDocumentAccessControlList?: { __typename?: 'DocumentAccessControlList', id: string, documentId: string, role: IObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null } | null };

export type IUpdateDocumentAccessControlListMutationVariables = Exact<{
  input: IUpdateDocumentAccessControlListInput;
}>;


export type IUpdateDocumentAccessControlListMutation = { __typename?: 'Mutation', updateDocumentAccessControlList: { __typename?: 'DocumentAccessControlList', id: string, documentId: string, role: IObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null } };

export type IDeleteDocumentAccessControlListMutationVariables = Exact<{
  input: IDeleteDocumentAccessControlListInput;
}>;


export type IDeleteDocumentAccessControlListMutation = { __typename?: 'Mutation', deleteDocumentAccessControlList: { __typename?: 'DeleteResult', id: string } };

export type IDocumentAccessControlListQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IDocumentAccessControlListQuery = { __typename?: 'Query', documentAccessControlList?: { __typename?: 'DocumentAccessControlList', id: string, documentId: string, role: IObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null } | null };

export type IDocumentAccessControlListByObjectQueryVariables = Exact<{
  documentId: Scalars['ID']['input'];
}>;


export type IDocumentAccessControlListByObjectQuery = { __typename?: 'Query', documentAccessControlListByObject: Array<{ __typename?: 'DocumentAccessControlList', id: string, documentId: string, role: IObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null }>, documentAccessControlListByObjectAggregate: { __typename?: 'DocumentAccessControlListAggregate', count: number } };

export type IDocumentAccessControlListUserRolesQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
}>;


export type IDocumentAccessControlListUserRolesQuery = { __typename?: 'Query', documentAccessControlListUserRoles: { __typename?: 'DocumentAccessControlListUserRoles', userId: string, objectId: string, roles: Array<IObjectRole> } };

export type IUpdateDocumentContentMutationVariables = Exact<{
  input: IUpdateDocumentContentInput;
}>;


export type IUpdateDocumentContentMutation = { __typename?: 'Mutation', updateDocumentContent: { __typename?: 'DocumentContent', id: string } };

export type ICreateFileMutationVariables = Exact<{
  input: ICreateFileInput;
}>;


export type ICreateFileMutation = { __typename?: 'Mutation', createFile?: { __typename?: 'File', id: string, name: string, url: string, filename: string, mimeType: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } | null };

export type IUpdateFileMutationVariables = Exact<{
  input: IUpdateFileInput;
}>;


export type IUpdateFileMutation = { __typename?: 'Mutation', updateFile: { __typename?: 'File', id: string, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type IDeleteFileMutationVariables = Exact<{
  input: IDeleteFileInput;
}>;


export type IDeleteFileMutation = { __typename?: 'Mutation', deleteFile: { __typename?: 'DeleteResult', id: string } };

export type IFileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IFileQuery = { __typename?: 'Query', file?: { __typename?: 'File', id: string, name: string } | null };

export type IFilesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IFileOrderBy>;
}>;


export type IFilesQuery = { __typename?: 'Query', files: Array<{ __typename?: 'File', id: string, name: string, url: string, filename: string, mimeType: string, fileAccessControlList: Array<{ __typename?: 'FileAccessControlList', userGroup?: { __typename?: 'UserGroup', name: string } | null, user?: { __typename?: 'User', id: string, name: string } | null }> }>, fileAggregate: { __typename?: 'FileAggregate', count: number } };

export type ICreateFileAccessControlListMutationVariables = Exact<{
  input: ICreateFileAccessControlListInput;
}>;


export type ICreateFileAccessControlListMutation = { __typename?: 'Mutation', createFileAccessControlList?: { __typename?: 'FileAccessControlList', id: string, fileId: string, role: IObjectRole, userId?: string | null, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string } | null } | null };

export type IUpdateFileAccessControlListMutationVariables = Exact<{
  input: IUpdateFileAccessControlListInput;
}>;


export type IUpdateFileAccessControlListMutation = { __typename?: 'Mutation', updateFileAccessControlList: { __typename?: 'FileAccessControlList', id: string, fileId: string, role: IObjectRole, userId?: string | null, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string } | null } };

export type IDeleteFileAccessControlListMutationVariables = Exact<{
  input: IDeleteFileAccessControlListInput;
}>;


export type IDeleteFileAccessControlListMutation = { __typename?: 'Mutation', deleteFileAccessControlList: { __typename?: 'DeleteResult', id: string } };

export type IFileAccessControlListQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IFileAccessControlListQuery = { __typename?: 'Query', fileAccessControlList?: { __typename?: 'FileAccessControlList', id: string, fileId: string, role: IObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null } | null };

export type IFileAccessControlListByObjectQueryVariables = Exact<{
  fileId: Scalars['ID']['input'];
}>;


export type IFileAccessControlListByObjectQuery = { __typename?: 'Query', fileAccessControlListByObject: Array<{ __typename?: 'FileAccessControlList', id: string, fileId: string, role: IObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null }>, fileAccessControlListByObjectAggregate: { __typename?: 'FileAccessControlListAggregate', count: number } };

export type IFileAccessControlListUserRolesQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  fileId: Scalars['ID']['input'];
}>;


export type IFileAccessControlListUserRolesQuery = { __typename?: 'Query', fileAccessControlListUserRoles: { __typename?: 'FileAccessControlListUserRoles', userId: string, objectId: string, roles: Array<IObjectRole> } };

export type ILinkPropsQueryVariables = Exact<{
  url: Scalars['String']['input'];
}>;


export type ILinkPropsQuery = { __typename?: 'Query', linkProps?: { __typename?: 'LinkPropsType', isEmbeddable: boolean } | null };

export type IOrganizationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IOrganizationQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', id: string, name: string } | null };

export type IOrganizationsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IOrganizationOrderBy>;
}>;


export type IOrganizationsQuery = { __typename?: 'Query', organizations?: Array<{ __typename?: 'Organization', id: string, name: string }> | null };

export type IProcessHtmlMutationVariables = Exact<{
  sourceDocument: Scalars['String']['input'];
  preset?: InputMaybe<Scalars['String']['input']>;
  analysisOverride?: InputMaybe<IAnalysisResultOverrideType>;
  analysisOverrideConfig?: InputMaybe<Scalars['String']['input']>;
}>;


export type IProcessHtmlMutation = { __typename?: 'Mutation', processHtml: { __typename?: 'ProcessHtmlResponseType', resultDocument: string } };

export type ICreateShapeDataMutationVariables = Exact<{
  input: ICreateShapeDataInput;
}>;


export type ICreateShapeDataMutation = { __typename?: 'Mutation', createShapeData?: { __typename?: 'ShapeData', id: string, data: string, rowState: IRowState, shapeId: string, workspaceBoardId: string } | null };

export type ICopyShapeDataMutationVariables = Exact<{
  input: ICopyShapeDataInput;
}>;


export type ICopyShapeDataMutation = { __typename?: 'Mutation', copyShapeData?: { __typename?: 'ShapeData', id: string, data: string, rowState: IRowState, shapeId: string, workspaceBoardId: string } | null };

export type IUpdateShapeDataMutationVariables = Exact<{
  input: IUpdateShapeDataInput;
}>;


export type IUpdateShapeDataMutation = { __typename?: 'Mutation', updateShapeData?: { __typename?: 'ShapeData', id: string, data: string, rowState: IRowState, shapeId: string, workspaceBoardId: string } | null };

export type IShapeDataQueryVariables = Exact<{
  shapeId: Scalars['String']['input'];
  workspaceBoardId: Scalars['String']['input'];
}>;


export type IShapeDataQuery = { __typename?: 'Query', shapeData?: { __typename?: 'ShapeData', id: string, data: string, rowState: IRowState, shapeId: string, workspaceBoardId: string } | null };

export type IThemeDiscussionAnalysesQueryVariables = Exact<{
  filters?: InputMaybe<IThemeDiscussionAnalysisFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<IThemeDiscussionAnalysisOrderBy>> | InputMaybe<IThemeDiscussionAnalysisOrderBy>>;
}>;


export type IThemeDiscussionAnalysesQuery = { __typename?: 'Query', themeDiscussionAnalyses?: { __typename?: 'ThemeDiscussionAnalysisSearchResponse', status: string, totalCount?: number | null, results?: Array<{ __typename?: 'ThemeDiscussionAnalysis', id: string, discussionDepth: number, discussionNature: Array<IDiscussionNature>, displaySymbol: string, itemDate: any, itemId: string, itemTitle: string, section: string, summaries: Array<string>, symbols: Array<string>, themeId: string, themeName: string, topics: Array<string>, participants: Array<{ __typename?: 'TranscriptParticipant', description: string, fullName: string, occupationTitles: Array<IOccupationTitle>, role: ITranscriptRole }>, topicsAnalysisItems: Array<{ __typename?: 'TopicsAnalysis', topic: string, discussionDepth: number, discussionNature: Array<IDiscussionNature>, participants: Array<{ __typename?: 'TranscriptParticipant', description: string, fullName: string, occupationTitles: Array<IOccupationTitle>, role: ITranscriptRole }> }> }> | null } | null };

export type IThemeDiscussionAnalysisQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IThemeDiscussionAnalysisQuery = { __typename?: 'Query', themeDiscussionAnalysis?: { __typename?: 'ThemeDiscussionAnalysis', id: string, discussionDepth: number, discussionNature: Array<IDiscussionNature>, displaySymbol: string, itemDate: any, itemId: string, itemTitle: string, section: string, summaries: Array<string>, symbols: Array<string>, themeId: string, themeName: string, topics: Array<string>, participants: Array<{ __typename?: 'TranscriptParticipant', description: string, fullName: string, occupationTitles: Array<IOccupationTitle>, role: ITranscriptRole }>, topicsAnalysisItems: Array<{ __typename?: 'TopicsAnalysis', topic: string, discussionDepth: number, discussionNature: Array<IDiscussionNature>, participants: Array<{ __typename?: 'TranscriptParticipant', description: string, fullName: string, occupationTitles: Array<IOccupationTitle>, role: ITranscriptRole }> }> } | null };

export type IImageTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type IImageTokenQuery = { __typename?: 'Query', imageToken: { __typename?: 'ImageTokenResultType', token: string } };

export type IUpdateTopicDiscussionExampleMutationVariables = Exact<{
  input: IUpdateTopicDiscussionExampleInput;
}>;


export type IUpdateTopicDiscussionExampleMutation = { __typename?: 'Mutation', updateTopicDiscussionExample?: { __typename?: 'TopicDiscussionExample', id: string, topic: string, question: string, detail: string, turn?: string | null, event: string, eventType?: string | null, ticker: string, category?: string | null, eventDate: any, segmentId?: string | null, section?: string | null, rating?: number | null, score?: number | null, explanation?: string | null, summary: string, firm?: string | null, reportTitle?: string | null, type: string, speakers?: Array<{ __typename?: 'Speaker', fullName: string, role: string }> | null } | null };

export type ITopicDiscussionExampleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ITopicDiscussionExampleQuery = { __typename?: 'Query', topicDiscussionExample?: { __typename?: 'TopicDiscussionExample', id: string, topic: string, question: string, detail: string } | null };

export type ITopicDiscussionExamplesQueryVariables = Exact<{
  facets: ITopicDiscussionExampleFacetsInput;
}>;


export type ITopicDiscussionExamplesQuery = { __typename?: 'Query', topicDiscussionExamples: Array<{ __typename?: 'TopicDiscussionExample', id: string, topic: string, question: string, detail: string, turn?: string | null, event: string, eventType?: string | null, ticker: string, category?: string | null, eventDate: any, segmentId?: string | null, section?: string | null, rating?: number | null, score?: number | null, explanation?: string | null, summary: string, firm?: string | null, reportTitle?: string | null, type: string, speakers?: Array<{ __typename?: 'Speaker', fullName: string, role: string }> | null }> };

export type ITopicDiscussionExampleAggregateQueryVariables = Exact<{
  facets: ITopicDiscussionExampleFacetsInput;
}>;


export type ITopicDiscussionExampleAggregateQuery = { __typename?: 'Query', bubbleChartScale: { __typename?: 'MinMaxAggregate', min?: number | null, max?: number | null }, barChartScale: { __typename?: 'MinMaxAggregate', min?: number | null, max?: number | null } };

export type IUpdateTopicDiscussionInNewsArticleMutationVariables = Exact<{
  input: IUpdateTopicDiscussionInNewsArticleInput;
}>;


export type IUpdateTopicDiscussionInNewsArticleMutation = { __typename?: 'Mutation', updateTopicDiscussionInNewsArticle?: { __typename?: 'TopicDiscussionInNewsArticle', id: string, theme: string, segmentId: string, segmentType: string, sourceType: string, date: any, headline: string, paragraph: string, articleCount: number, topicDiscussions: Array<{ __typename?: 'ITopicDiscussion', topic: string, summary: string }>, newSources: Array<{ __typename?: 'NewSource', name: string, url: string }> } | null };

export type ITopicDiscussionInNewsArticleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ITopicDiscussionInNewsArticleQuery = { __typename?: 'Query', topicDiscussionInNewsArticle?: { __typename?: 'TopicDiscussionInNewsArticle', id: string, theme: string, segmentId: string, segmentType: string, sourceType: string, date: any, headline: string, paragraph: string, topicDiscussions: Array<{ __typename?: 'ITopicDiscussion', topic: string, summary: string }>, newSources: Array<{ __typename?: 'NewSource', name: string, url: string }> } | null };

export type ITopicDiscussionInNewsArticlesQueryVariables = Exact<{
  facets: ITopicDiscussionInNewsArticleFacetsInput;
}>;


export type ITopicDiscussionInNewsArticlesQuery = { __typename?: 'Query', topicDiscussionInNewsArticles: Array<{ __typename?: 'TopicDiscussionInNewsArticle', id: string, theme: string, segmentId: string, segmentType: string, sourceType: string, date: any, headline: string, paragraph: string, articleCount: number, topicDiscussions: Array<{ __typename?: 'ITopicDiscussion', topic: string, summary: string }>, newSources: Array<{ __typename?: 'NewSource', name: string, url: string }> }> };

export type IUpdateTopicDiscussionSummaryExampleMutationVariables = Exact<{
  input: IUpdateTopicDiscussionSummaryExampleInput;
}>;


export type IUpdateTopicDiscussionSummaryExampleMutation = { __typename?: 'Mutation', updateTopicDiscussionSummaryExample?: { __typename?: 'TopicDiscussionSummaryExample', id: string, topic: string, questions: Array<string>, questionMentionCounts: Array<number>, detail: string, topicMentionCount: number, event: string, eventType: string, ticker: string, category: string, eventDate: any, section: string, rating?: number | null, score?: number | null, summary: string, analysisName: string, speakers: Array<{ __typename?: 'Speaker', fullName: string, role: string }> } | null };

export type ITopicDiscussionSummaryExampleQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ITopicDiscussionSummaryExampleQuery = { __typename?: 'Query', topicDiscussionSummaryExample?: { __typename?: 'TopicDiscussionSummaryExample', id: string, topic: string, questions: Array<string>, event: string, summary: string, detail: string } | null };

export type ITopicDiscussionSummaryExamplesQueryVariables = Exact<{
  facets: ITopicDiscussionSummaryExampleFacetsInput;
}>;


export type ITopicDiscussionSummaryExamplesQuery = { __typename?: 'Query', topicDiscussionSummaryExamples: Array<{ __typename?: 'TopicDiscussionSummaryExample', id: string, topic: string, questions: Array<string>, questionMentionCounts: Array<number>, detail: string, topicMentionCount: number, event: string, eventType: string, ticker: string, category: string, eventDate: any, section: string, rating?: number | null, score?: number | null, summary: string, analysisName: string, speakers: Array<{ __typename?: 'Speaker', fullName: string, role: string }> }> };

export type ITopicDiscussionSummaryExampleAggregateQueryVariables = Exact<{
  facets: ITopicDiscussionSummaryExampleFacetsInput;
}>;


export type ITopicDiscussionSummaryExampleAggregateQuery = { __typename?: 'Query', bubbleChartScale: { __typename?: 'MinMaxAggregate', min?: number | null, max?: number | null }, barChartScale: { __typename?: 'MinMaxAggregate', min?: number | null, max?: number | null } };

export type ITranscriptsQueryVariables = Exact<{
  filters?: InputMaybe<ITranscriptFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ITranscriptOrderBy>> | InputMaybe<ITranscriptOrderBy>>;
}>;


export type ITranscriptsQuery = { __typename?: 'Query', transcripts?: { __typename?: 'TranscriptSearchResponse', status: string, totalCount?: number | null, results?: Array<{ __typename?: 'Transcript', id: string, displaySymbol: string, eventType: ITranscriptEventType, itemDate: any, title: string }> | null } | null, transcriptAggregate: { __typename?: 'TranscriptAggregate', minItemDate?: string | null, maxItemDate?: string | null } };

export type ITranscriptQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ITranscriptQuery = { __typename?: 'Query', transcript?: { __typename?: 'Transcript', id: string, displaySymbol: string, eventType: ITranscriptEventType, itemDate: any, title: string, symbols: Array<string>, transcriptItems: Array<{ __typename?: 'TranscriptItem', itemIndex: number, fullName: string, occupationTitles: Array<IOccupationTitle>, role: ITranscriptRole, speech: Array<string>, session: ITranscriptSession }> } | null };

export type IUploadFileMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;


export type IUploadFileMutation = { __typename?: 'Mutation', uploadFile?: { __typename?: 'FileData', filename: string, originalFilename: string, apiDownloadUrl: string, storageLocation: string, mimetype: string } | null };

export type IUploadWorkspaceFilesMutationVariables = Exact<{
  input: IUploadWorkspaceFilesInput;
}>;


export type IUploadWorkspaceFilesMutation = { __typename?: 'Mutation', uploadWorkspaceFiles?: { __typename?: 'UploadWorkspaceFileResponse', succeeded: Array<{ __typename?: 'WorkspaceFileData', filename: string, id: string, originalFilename: string, storageLocation: string, mimetype: string }>, failed: Array<{ __typename?: 'WorkspaceFileFailedFile', filename: string, id: string, originalFilename: string, storageLocation: string, mimetype: string, reason: string }> } | null };

export type IUploadDocumentMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;


export type IUploadDocumentMutation = { __typename?: 'Mutation', uploadDocument?: { __typename?: 'DocumentFile', filename: string, originalFilename: string, document: { __typename?: 'DocumentType', id: string, name: string, organizationId: string } } | null };

export type IUploadDocumentImageMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
  documentId: Scalars['UUID']['input'];
}>;


export type IUploadDocumentImageMutation = { __typename?: 'Mutation', uploadDocumentImage?: { __typename?: 'DocumentImageFile', assetDownloadUrl: string } | null };

export type IUploadWorkspaceBoardAssetMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
  workspaceBoardId: Scalars['UUID']['input'];
}>;


export type IUploadWorkspaceBoardAssetMutation = { __typename?: 'Mutation', uploadWorkspaceBoardAsset?: { __typename?: 'WorkspaceBoardAssetFile', storageLocationUrl: string } | null };

export type IOnWorkspaceFileAddedSubscriptionVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
}>;


export type IOnWorkspaceFileAddedSubscription = { __typename?: 'Subscription', onWorkspaceFileAdded?: { __typename?: 'WorkspaceFileUploadStatus', id: string, uploadedSize: number, totalSize: number, fileName: string, mimeType: string, workspaceId: string, uploadStatus: string, error: string } | null };

export type IUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, name: string, email: string } | null };

export type IUsersQueryVariables = Exact<{
  orderBy?: InputMaybe<IUserOrderBy>;
}>;


export type IUsersQuery = { __typename?: 'Query', users?: Array<{ __typename?: 'User', id: string, name: string, email: string }> | null };

export type ICreateUserGroupMutationVariables = Exact<{
  input: ICreateUserGroupInput;
}>;


export type ICreateUserGroupMutation = { __typename?: 'Mutation', createUserGroup?: { __typename?: 'UserGroup', id: string, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } | null };

export type IUpdateUserGroupMutationVariables = Exact<{
  input: IUpdateUserGroupInput;
}>;


export type IUpdateUserGroupMutation = { __typename?: 'Mutation', updateUserGroup: { __typename?: 'UserGroup', id: string, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type IDeleteUserGroupMutationVariables = Exact<{
  input: IDeleteUserGroupInput;
}>;


export type IDeleteUserGroupMutation = { __typename?: 'Mutation', deleteUserGroup: { __typename?: 'DeleteResult', id: string } };

export type IUserGroupQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IUserGroupQuery = { __typename?: 'Query', userGroup?: { __typename?: 'UserGroup', id: string, name: string } | null };

export type IUserGroupsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IUserGroupOrderBy>;
}>;


export type IUserGroupsQuery = { __typename?: 'Query', userGroups: Array<{ __typename?: 'UserGroup', id: string, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string }>, userGroupAggregate: { __typename?: 'UserGroupAggregate', count: number } };

export type ICreateUserGroupMemberMutationVariables = Exact<{
  input: ICreateUserGroupMemberInput;
}>;


export type ICreateUserGroupMemberMutation = { __typename?: 'Mutation', createUserGroupMember?: { __typename?: 'UserGroupMember', id: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup: { __typename?: 'UserGroup', id: string, name: string }, user?: { __typename?: 'User', id: string, name: string } | null } | null };

export type IUpdateUserGroupMemberMutationVariables = Exact<{
  input: IUpdateUserGroupMemberInput;
}>;


export type IUpdateUserGroupMemberMutation = { __typename?: 'Mutation', updateUserGroupMember: { __typename?: 'UserGroupMember', id: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup: { __typename?: 'UserGroup', id: string, name: string }, user?: { __typename?: 'User', id: string, name: string } | null } };

export type IDeleteUserGroupMemberMutationVariables = Exact<{
  input: IDeleteUserGroupMemberInput;
}>;


export type IDeleteUserGroupMemberMutation = { __typename?: 'Mutation', deleteUserGroupMember: { __typename?: 'DeleteResult', id: string } };

export type IUserGroupMemberQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type IUserGroupMemberQuery = { __typename?: 'Query', userGroupMember?: { __typename?: 'UserGroupMember', id: string, userGroup: { __typename?: 'UserGroup', id: string, name: string }, user?: { __typename?: 'User', id: string, name: string } | null } | null };

export type IUserGroupMembersQueryVariables = Exact<{
  userGroupId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IUserGroupMemberOrderBy>;
}>;


export type IUserGroupMembersQuery = { __typename?: 'Query', userGroupMembers: Array<{ __typename?: 'UserGroupMember', id: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup: { __typename?: 'UserGroup', id: string, name: string }, user?: { __typename?: 'User', id: string, name: string } | null }>, userGroupMemberAggregate: { __typename?: 'UserGroupMemberAggregate', count: number } };

export type IUpsertWorkspacesPageUserPreferencesMutationVariables = Exact<{
  input: IUpsertUserPreferenceInput;
}>;


export type IUpsertWorkspacesPageUserPreferencesMutation = { __typename?: 'Mutation', upsertUserPreference: { __typename?: 'UserPreference', key: string, data: any } };

export type IUpsertWorkspacePageUserPreferencesMutationVariables = Exact<{
  input: IUpsertUserPreferenceInput;
}>;


export type IUpsertWorkspacePageUserPreferencesMutation = { __typename?: 'Mutation', upsertUserPreference: { __typename?: 'UserPreference', key: string, data: any } };

export type IUpsertWorkspaceUserPreferencesMutationVariables = Exact<{
  input: IUpsertUserPreferenceInput;
}>;


export type IUpsertWorkspaceUserPreferencesMutation = { __typename?: 'Mutation', upsertUserPreference: { __typename?: 'UserPreference', key: string, data: any } };

export type IWorkspacesPageUserPreferencesQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type IWorkspacesPageUserPreferencesQuery = { __typename?: 'Query', userPreference?: { __typename?: 'UserPreference', key: string, data: any } | null };

export type IWorkspacePageUserPreferencesQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type IWorkspacePageUserPreferencesQuery = { __typename?: 'Query', userPreference?: { __typename?: 'UserPreference', key: string, data: any } | null };

export type IWorkspaceUserPreferencesQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type IWorkspaceUserPreferencesQuery = { __typename?: 'Query', userPreference?: { __typename?: 'UserPreference', key: string, data: any } | null };

export type IWorkspaceBoardUserPreferencesQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type IWorkspaceBoardUserPreferencesQuery = { __typename?: 'Query', userPreference?: { __typename?: 'UserPreference', key: string, data: any } | null };

export type ICreateWorkspaceMutationVariables = Exact<{
  input: ICreateWorkspaceInput;
}>;


export type ICreateWorkspaceMutation = { __typename?: 'Mutation', createWorkspace?: { __typename?: 'Workspace', id: string, description?: string | null, name: string, tags: Array<string>, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, defaultWorkspaceBoard?: { __typename?: 'WorkspaceBoard', id: string, name: string, tags: Array<string>, thumbnail?: string | null } | null } | null };

export type IUpdateWorkspaceMutationVariables = Exact<{
  input: IUpdateWorkspaceInput;
}>;


export type IUpdateWorkspaceMutation = { __typename?: 'Mutation', updateWorkspace: { __typename?: 'Workspace', id: string, description?: string | null, name: string, tags: Array<string>, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type IDeleteWorkspaceMutationVariables = Exact<{
  input: IDeleteWorkspaceInput;
}>;


export type IDeleteWorkspaceMutation = { __typename?: 'Mutation', deleteWorkspace: { __typename?: 'DeleteResult', id: string } };

export type ICopyWorkspaceMutationVariables = Exact<{
  input: ICopyWorkspaceInput;
}>;


export type ICopyWorkspaceMutation = { __typename?: 'Mutation', copyWorkspace?: { __typename?: 'Workspace', id: string, description?: string | null, name: string, tags: Array<string>, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, defaultWorkspaceBoard?: { __typename?: 'WorkspaceBoard', id: string, name: string, tags: Array<string>, thumbnail?: string | null } | null } | null };

export type IReindexWorkspaceMutationVariables = Exact<{
  input: IReindexWorkspaceInput;
}>;


export type IReindexWorkspaceMutation = { __typename?: 'Mutation', reindexWorkspace: { __typename?: 'ReindexWorkspaceResult', id: string } };

export type IWorkspaceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IWorkspaceQuery = { __typename?: 'Query', workspace?: { __typename?: 'Workspace', id: string, description?: string | null, name: string, tags: Array<string>, defaultWorkspaceBoard?: { __typename?: 'WorkspaceBoard', id: string, thumbnail?: string | null } | null } | null };

export type IWorkspacesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IWorkspaceOrderBy>;
  filters?: InputMaybe<IWorkspaceFilters>;
}>;


export type IWorkspacesQuery = { __typename?: 'Query', workspaces: Array<{ __typename?: 'Workspace', id: string, description?: string | null, name: string, tags: Array<string>, updatedAt: any, defaultWorkspaceBoard?: { __typename?: 'WorkspaceBoard', id: string, thumbnail?: string | null } | null, workspaceAccessControlList: Array<{ __typename?: 'WorkspaceAccessControlList', userGroup?: { __typename?: 'UserGroup', name: string } | null, user?: { __typename?: 'User', id: string, name: string } | null }> }>, workspaceAggregate: { __typename?: 'WorkspaceAggregate', count: number } };

export type IWorkspaceTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type IWorkspaceTagsQuery = { __typename?: 'Query', workspaceTags: Array<string> };

export type ICreateWorkspaceAccessControlListMutationVariables = Exact<{
  input: ICreateWorkspaceAccessControlListInput;
}>;


export type ICreateWorkspaceAccessControlListMutation = { __typename?: 'Mutation', createWorkspaceAccessControlList?: { __typename?: 'WorkspaceAccessControlList', id: string, workspaceId: string, role: IObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null } | null };

export type IUpdateWorkspaceAccessControlListMutationVariables = Exact<{
  input: IUpdateWorkspaceAccessControlListInput;
}>;


export type IUpdateWorkspaceAccessControlListMutation = { __typename?: 'Mutation', updateWorkspaceAccessControlList: { __typename?: 'WorkspaceAccessControlList', id: string, workspaceId: string, role: IObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null } };

export type IDeleteWorkspaceAccessControlListMutationVariables = Exact<{
  input: IDeleteWorkspaceAccessControlListInput;
}>;


export type IDeleteWorkspaceAccessControlListMutation = { __typename?: 'Mutation', deleteWorkspaceAccessControlList: { __typename?: 'DeleteResult', id: string } };

export type IWorkspaceAccessControlListQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IWorkspaceAccessControlListQuery = { __typename?: 'Query', workspaceAccessControlList?: { __typename?: 'WorkspaceAccessControlList', id: string, workspaceId: string, role: IObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null } | null };

export type IWorkspaceAccessControlListByObjectQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
}>;


export type IWorkspaceAccessControlListByObjectQuery = { __typename?: 'Query', workspaceAccessControlListByObject: Array<{ __typename?: 'WorkspaceAccessControlList', id: string, workspaceId: string, role: IObjectRole, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string, userGroup?: { __typename?: 'UserGroup', id: string, name: string } | null, user?: { __typename?: 'User', id: string, name: string, profilePictureUrl?: string | null } | null }>, workspaceAccessControlListByObjectAggregate: { __typename?: 'WorkspaceAccessControlListAggregate', count: number } };

export type IWorkspaceAccessControlListUserRolesQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  workspaceId: Scalars['ID']['input'];
}>;


export type IWorkspaceAccessControlListUserRolesQuery = { __typename?: 'Query', workspaceAccessControlListUserRoles: { __typename?: 'WorkspaceAccessControlListUserRoles', userId: string, objectId: string, roles: Array<IObjectRole> } };

export type IWorkspaceBoardAccessControlListUserRolesQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  workspaceBoardId: Scalars['ID']['input'];
}>;


export type IWorkspaceBoardAccessControlListUserRolesQuery = { __typename?: 'Query', workspaceBoardAccessControlListUserRoles: { __typename?: 'WorkspaceAccessControlListUserRoles', userId: string, objectId: string, roles: Array<IObjectRole> } };

export type ICreateWorkspaceBoardMutationVariables = Exact<{
  input: ICreateWorkspaceBoardInput;
}>;


export type ICreateWorkspaceBoardMutation = { __typename?: 'Mutation', createWorkspaceBoard: { __typename?: 'WorkspaceBoard', id: string, content?: string | null, description?: string | null, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type IDeleteWorkspaceBoardMutationVariables = Exact<{
  input: IDeleteWorkspaceBoardInput;
}>;


export type IDeleteWorkspaceBoardMutation = { __typename?: 'Mutation', deleteWorkspaceBoard: { __typename?: 'DeleteResult', id: string } };

export type IUpdateWorkspaceBoardMutationVariables = Exact<{
  input: IUpdateWorkspaceBoardInput;
}>;


export type IUpdateWorkspaceBoardMutation = { __typename?: 'Mutation', updateWorkspaceBoard: { __typename?: 'WorkspaceBoard', content?: string | null, description?: string | null, id: string, isThumbnailLocked: boolean, name: string, tags: Array<string>, thumbnail?: string | null, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type IMarkWorkspaceBoardShapesAsDeletedMutationVariables = Exact<{
  input: IMarkWorkspaceBoardShapeAsDeletedInput;
}>;


export type IMarkWorkspaceBoardShapesAsDeletedMutation = { __typename?: 'Mutation', markWorkspaceBoardShapesAsDeleted: Array<string> };

export type IWorkspaceBoardQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IWorkspaceBoardQuery = { __typename?: 'Query', workspaceBoard?: { __typename?: 'WorkspaceBoard', content?: string | null, description?: string | null, id: string, isThumbnailLocked: boolean, name: string, tags: Array<string>, thumbnail?: string | null, workspaceId: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } | null };

export type IWorkspaceBoardsQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IWorkspaceBoardOrderBy>;
  filters?: InputMaybe<IWorkspaceBoardFilters>;
}>;


export type IWorkspaceBoardsQuery = { __typename?: 'Query', workspaceBoards: Array<{ __typename?: 'WorkspaceBoard', content?: string | null, description?: string | null, id: string, isThumbnailLocked: boolean, name: string, tags: Array<string>, thumbnail?: string | null, workspaceId: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string }>, workspaceBoardAggregate: { __typename?: 'WorkspaceBoardAggregate', count: number } };

export type IWorkspaceBoardAggregateQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  filters?: InputMaybe<IWorkspaceBoardFilters>;
}>;


export type IWorkspaceBoardAggregateQuery = { __typename?: 'Query', workspaceBoardAggregate: { __typename?: 'WorkspaceBoardAggregate', count: number } };

export type IWorkspaceBoardTagsQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
}>;


export type IWorkspaceBoardTagsQuery = { __typename?: 'Query', workspaceBoardTags: Array<string> };

export type ICreateWorkspaceFileMutationVariables = Exact<{
  input: ICreateWorkspaceFileInput;
}>;


export type ICreateWorkspaceFileMutation = { __typename?: 'Mutation', createWorkspaceFile: { __typename?: 'WorkspaceFile', id: string, name: string, url: string, filename: string, mimeType: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type IUpdateWorkspaceFileMutationVariables = Exact<{
  input: IUpdateWorkspaceFileInput;
}>;


export type IUpdateWorkspaceFileMutation = { __typename?: 'Mutation', updateWorkspaceFile: { __typename?: 'WorkspaceFile', id: string, name: string, createdAt: any, createdBy: string, updatedAt: any, updatedBy: string } };

export type IDeleteWorkspaceFileMutationVariables = Exact<{
  input: IDeleteWorkspaceFileInput;
}>;


export type IDeleteWorkspaceFileMutation = { __typename?: 'Mutation', deleteWorkspaceFile: { __typename?: 'DeleteResult', id: string } };

export type IWorkspaceFileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IWorkspaceFileQuery = { __typename?: 'Query', workspaceFile?: { __typename?: 'WorkspaceFile', id: string, name: string, filename: string, workspaceId: string } | null };

export type IWorkspaceFilesQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<IWorkspaceFileOrderBy>;
  filters?: InputMaybe<IWorkspaceFileFilters>;
}>;


export type IWorkspaceFilesQuery = { __typename?: 'Query', workspaceFiles: Array<{ __typename?: 'WorkspaceFile', id: string, name: string, url: string, filename: string, mimeType: string, workspaceId: string, updatedAt: any }>, workspaceFileAggregate: { __typename?: 'WorkspaceFileAggregate', count: number } };

export type IWorkspaceFileAggregateQueryVariables = Exact<{
  workspaceId: Scalars['ID']['input'];
  filters?: InputMaybe<IWorkspaceFileFilters>;
}>;


export type IWorkspaceFileAggregateQuery = { __typename?: 'Query', workspaceFileAggregate: { __typename?: 'WorkspaceFileAggregate', count: number } };

export type IUpsertWorkspaceBoardUserPreferencesCacheQueryVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type IUpsertWorkspaceBoardUserPreferencesCacheQuery = { __typename?: 'Query', userPreference?: { __typename?: 'UserPreference', key: string, data: any } | null };


export const EmptyQueryDocument = gql`
    query EmptyQuery {
  __typename
}
    `;
export const FeedTranscriptsTranscriptDocument = gql`
    query FeedTranscriptsTranscript($filters: TranscriptFilters, $limit: Int, $offset: Int, $orderBy: [TranscriptOrderBy]) {
  transcripts(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filters: $filters
  ) {
    results {
      id
      displaySymbol
      eventType
      itemDate
      title
    }
    status
    totalCount
  }
  transcriptAggregate {
    minItemDate
    maxItemDate
  }
}
    `;
export const ThemeDiscussionAnalysisParentTranscriptDocument = gql`
    query ThemeDiscussionAnalysisParentTranscript($id: ID!) {
  transcript(id: $id) {
    id
    displaySymbol
    eventType
    itemDate
  }
}
    `;
export const ThemeDiscussionAnalysisParentTranscriptsDocument = gql`
    query ThemeDiscussionAnalysisParentTranscripts($filters: TranscriptFilters, $limit: Int, $offset: Int, $orderBy: [TranscriptOrderBy]) {
  transcripts(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filters: $filters
  ) {
    results {
      id
      displaySymbol
      eventType
      itemDate
    }
  }
}
    `;
export const CreateAnalysisFeedbackDocument = gql`
    mutation CreateAnalysisFeedback($input: CreateAnalysisFeedbackInput!) {
  createAnalysisFeedback(input: $input) {
    id
  }
}
    `;
export const UpdateAnalystQuestionDocument = gql`
    mutation UpdateAnalystQuestion($input: UpdateAnalystQuestionInput!) {
  updateAnalystQuestion(input: $input) {
    id
    eventDate
    firm
    firmType
    question
    segment
    topics
  }
}
    `;
export const AnalystQuestionDocument = gql`
    query AnalystQuestion($id: ID!) {
  analystQuestion(id: $id) {
    id
    eventDate
    firm
    firmType
    question
    segment
    topics
  }
}
    `;
export const AnalystQuestionsDocument = gql`
    query AnalystQuestions($facets: AnalystQuestionFacetsInput!, $numberOfClusters: Int, $otherGroupLabel: String!, $version: String) {
  analystQuestions(
    facets: $facets
    numberOfClusters: $numberOfClusters
    otherGroupLabel: $otherGroupLabel
    version: $version
  ) {
    id
    eventDate
    firm
    firmType
    question
    segment
    topics
    group
    analystQuestionClusterAssignment {
      analystQuestionCluster {
        name
      }
    }
  }
}
    `;
export const GroupedAnalystQuestionsDocument = gql`
    query GroupedAnalystQuestions($facets: AnalystQuestionFacetsInput!, $numberOfClusters: Int!, $otherGroupLabel: String!, $version: String!) {
  groupedAnalystQuestions(
    facets: $facets
    numberOfClusters: $numberOfClusters
    otherGroupLabel: $otherGroupLabel
    version: $version
  ) {
    id
    eventDate
    firm
    firmType
    question
    segment
    topics
    group
  }
}
    `;
export const CreateChatMessageDocument = gql`
    mutation CreateChatMessage($input: CreateChatMessageInput!) {
  createChatMessage(input: $input) {
    id
    channelId
    messageType
    message
    privateRecipientIds
    attachments {
      mimeType
      name
      storageLocationUrl
    }
    mentions {
      targetId
      name
      mentionType
    }
    reactions {
      reaction
      userIds
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const UpdateChatMessageDocument = gql`
    mutation UpdateChatMessage($input: UpdateChatMessageInput!) {
  updateChatMessage(input: $input) {
    id
    message
    createdAt
    createdBy
    updatedAt
    updatedBy
    updateCount
  }
}
    `;
export const DeleteChatMessageDocument = gql`
    mutation DeleteChatMessage($input: DeleteChatMessageInput!) {
  softOrHardDeleteChatMessage(input: $input) {
    id
  }
}
    `;
export const ChatMessageDocument = gql`
    query ChatMessage($id: ID!, $channelType: ChatMessageChannelType!) {
  chatMessage(id: $id, channelType: $channelType) {
    id
    channelId
    parentId
    messageType
    message
    privateRecipientIds
    attachments {
      mimeType
      name
      storageLocationUrl
    }
    mentions {
      targetId
      name
      mentionType
    }
    reactions {
      reaction
      userIds
    }
    createdAt
    createdBy
    createdByUser {
      id
      name
      profilePictureUrl
    }
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    childCount
  }
}
    `;
export const ChatMessagesDocument = gql`
    query ChatMessages($channelId: ID!, $channelType: ChatMessageChannelType!, $parentId: ID, $offset: Int, $limit: Int) {
  chatMessages(
    channelId: $channelId
    channelType: $channelType
    parentId: $parentId
    offset: $offset
    limit: $limit
  ) {
    id
    channelId
    parentId
    messageType
    message
    privateRecipientIds
    attachments {
      mimeType
      name
      storageLocationUrl
    }
    mentions {
      targetId
      name
      mentionType
    }
    reactions {
      reaction
      userIds
    }
    createdAt
    createdBy
    createdByUser {
      id
      name
      profilePictureUrl
    }
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    childCount
  }
}
    `;
export const OnChatMessagesAddedDocument = gql`
    subscription OnChatMessagesAdded($channelId: ID!, $channelType: ChatMessageChannelType!, $parentId: ID) {
  onChatMessageAdded(
    channelId: $channelId
    channelType: $channelType
    parentId: $parentId
  ) {
    id
    channelId
    parentId
    messageType
    message
    privateRecipientIds
    attachments {
      mimeType
      name
      storageLocationUrl
    }
    mentions {
      targetId
      name
      mentionType
    }
    reactions {
      reaction
      userIds
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    childCount
  }
}
    `;
export const OnChatMessagesUpdatedDocument = gql`
    subscription OnChatMessagesUpdated($channelId: ID!, $channelType: ChatMessageChannelType!, $parentId: ID) {
  onChatMessageUpdated(
    channelId: $channelId
    channelType: $channelType
    parentId: $parentId
  ) {
    id
    channelId
    parentId
    messageType
    message
    privateRecipientIds
    attachments {
      mimeType
      name
      storageLocationUrl
    }
    mentions {
      targetId
      name
      mentionType
    }
    reactions {
      reaction
      userIds
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
    childCount
  }
}
    `;
export const OnChatMessagesDeletedDocument = gql`
    subscription OnChatMessagesDeleted($channelId: ID!, $channelType: ChatMessageChannelType!, $parentId: ID) {
  onChatMessageDeleted(
    channelId: $channelId
    channelType: $channelType
    parentId: $parentId
  ) {
    id
  }
}
    `;
export const ExecuteCommandDocument = gql`
    mutation ExecuteCommand($input: ExecuteCommandInput!) {
  executeCommand(input: $input) {
    data
    errors
    requestId
    sessionId
    status
  }
}
    `;
export const CommandPingDocument = gql`
    mutation CommandPing($input: ExecuteCommandInput!) {
  executePingCommand(input: $input) {
    data
    errors
    requestId
    sessionId
    status
  }
}
    `;
export const OnCommandResponseDocument = gql`
    subscription OnCommandResponse($sessionId: String!) {
  commandResponse(sessionId: $sessionId) {
    activeCommandContext
    callerData
    data
    errors
    requestId
    status
    sessionId
  }
}
    `;
export const GetLatestConfigsByPrefixDocument = gql`
    query getLatestConfigsByPrefix($keyPrefix: String!, $organizationId: ID!) {
  LatestConfigsByPrefix(keyPrefix: $keyPrefix, organizationId: $organizationId) {
    data
  }
}
    `;
export const GetConfigDataDocument = gql`
    query getConfigData($key: ID!, $organizationId: ID!) {
  Config(key: $key, organizationId: $organizationId) {
    data
  }
}
    `;
export const GetAppConfigDataDocument = gql`
    query getAppConfigData($organizationId: ID!, $baseDocumentStylesheetKey: ID!, $organizationDocumentStylesheetKey: ID!, $organizationPreferencesKey: ID!) {
  BaseDocumentStylesheet: Config(
    key: $baseDocumentStylesheetKey
    organizationId: $organizationId
  ) {
    data
  }
  OrganizationDocumentStylesheet: Config(
    key: $organizationDocumentStylesheetKey
    organizationId: $organizationId
  ) {
    data
  }
  OrganizationPreferences: Config(
    key: $organizationPreferencesKey
    organizationId: $organizationId
  ) {
    data
  }
}
    `;
export const DistilledTranscriptDocument = gql`
    query DistilledTranscript($transcriptId: ID!) {
  distilledTranscript(transcriptId: $transcriptId) {
    id
    displaySymbol
    distilledSegments {
      segmentIndex
      session
      distilledTranscriptItems {
        itemIndex
        fullName
        occupationTitles
        role
        session
        speech
      }
      originalTranscriptItems {
        itemIndex
        fullName
        occupationTitles
        role
        session
        speech
      }
    }
    eventType
    itemDate
    title
  }
}
    `;
export const CreateDocumentDocument = gql`
    mutation CreateDocument($input: CreateDocumentInput!) {
  createDocument(input: $input) {
    id
    name
    content {
      id
      content
      contentType
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const UpdateDocumentDocument = gql`
    mutation UpdateDocument($input: UpdateDocumentInput!) {
  updateDocument(input: $input) {
    id
    name
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const DeleteDocumentDocument = gql`
    mutation DeleteDocument($input: DeleteDocumentInput!) {
  deleteDocument(input: $input) {
    id
  }
}
    `;
export const DocumentDocument = gql`
    query Document($id: ID!) {
  document(id: $id) {
    id
    name
  }
}
    `;
export const DocumentsDocument = gql`
    query Documents($offset: Int, $limit: Int, $orderBy: DocumentOrderBy) {
  documents(offset: $offset, limit: $limit, orderBy: $orderBy) {
    id
    name
    documentAccessControlList {
      userGroup {
        name
      }
      user {
        id
        name
      }
    }
  }
  documentAggregate {
    count
  }
}
    `;
export const CreateDocumentAccessControlListDocument = gql`
    mutation CreateDocumentAccessControlList($input: CreateDocumentAccessControlListInput!) {
  createDocumentAccessControlList(input: $input) {
    id
    documentId
    role
    userGroup {
      id
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const UpdateDocumentAccessControlListDocument = gql`
    mutation UpdateDocumentAccessControlList($input: UpdateDocumentAccessControlListInput!) {
  updateDocumentAccessControlList(input: $input) {
    id
    documentId
    role
    userGroup {
      id
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const DeleteDocumentAccessControlListDocument = gql`
    mutation DeleteDocumentAccessControlList($input: DeleteDocumentAccessControlListInput!) {
  deleteDocumentAccessControlList(input: $input) {
    id
  }
}
    `;
export const DocumentAccessControlListDocument = gql`
    query DocumentAccessControlList($id: ID!) {
  documentAccessControlList(id: $id) {
    id
    documentId
    role
    userGroup {
      id
      name
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const DocumentAccessControlListByObjectDocument = gql`
    query DocumentAccessControlListByObject($documentId: ID!) {
  documentAccessControlListByObject(documentId: $documentId) {
    id
    documentId
    role
    userGroup {
      id
      name
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
  documentAccessControlListByObjectAggregate(documentId: $documentId) {
    count
  }
}
    `;
export const DocumentAccessControlListUserRolesDocument = gql`
    query DocumentAccessControlListUserRoles($userId: ID!, $documentId: ID!) {
  documentAccessControlListUserRoles(userId: $userId, documentId: $documentId) {
    userId
    objectId
    roles
  }
}
    `;
export const UpdateDocumentContentDocument = gql`
    mutation UpdateDocumentContent($input: UpdateDocumentContentInput!) {
  updateDocumentContent(input: $input) {
    id
  }
}
    `;
export const CreateFileDocument = gql`
    mutation CreateFile($input: CreateFileInput!) {
  createFile(input: $input) {
    id
    name
    url
    filename
    mimeType
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const UpdateFileDocument = gql`
    mutation UpdateFile($input: UpdateFileInput!) {
  updateFile(input: $input) {
    id
    name
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const DeleteFileDocument = gql`
    mutation DeleteFile($input: DeleteFileInput!) {
  deleteFile(input: $input) {
    id
  }
}
    `;
export const FileDocument = gql`
    query File($id: ID!) {
  file(id: $id) {
    id
    name
  }
}
    `;
export const FilesDocument = gql`
    query Files($offset: Int, $limit: Int, $orderBy: FileOrderBy) {
  files(offset: $offset, limit: $limit, orderBy: $orderBy) {
    id
    name
    url
    filename
    mimeType
    fileAccessControlList {
      userGroup {
        name
      }
      user {
        id
        name
      }
    }
  }
  fileAggregate {
    count
  }
}
    `;
export const CreateFileAccessControlListDocument = gql`
    mutation CreateFileAccessControlList($input: CreateFileAccessControlListInput!) {
  createFileAccessControlList(input: $input) {
    id
    fileId
    role
    userGroup {
      id
    }
    userId
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const UpdateFileAccessControlListDocument = gql`
    mutation UpdateFileAccessControlList($input: UpdateFileAccessControlListInput!) {
  updateFileAccessControlList(input: $input) {
    id
    fileId
    role
    userGroup {
      id
    }
    userId
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const DeleteFileAccessControlListDocument = gql`
    mutation DeleteFileAccessControlList($input: DeleteFileAccessControlListInput!) {
  deleteFileAccessControlList(input: $input) {
    id
  }
}
    `;
export const FileAccessControlListDocument = gql`
    query FileAccessControlList($id: ID!) {
  fileAccessControlList(id: $id) {
    id
    fileId
    role
    userGroup {
      id
      name
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const FileAccessControlListByObjectDocument = gql`
    query FileAccessControlListByObject($fileId: ID!) {
  fileAccessControlListByObject(fileId: $fileId) {
    id
    fileId
    role
    userGroup {
      id
      name
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
  fileAccessControlListByObjectAggregate(fileId: $fileId) {
    count
  }
}
    `;
export const FileAccessControlListUserRolesDocument = gql`
    query FileAccessControlListUserRoles($userId: ID!, $fileId: ID!) {
  fileAccessControlListUserRoles(userId: $userId, fileId: $fileId) {
    userId
    objectId
    roles
  }
}
    `;
export const LinkPropsDocument = gql`
    query LinkProps($url: String!) {
  linkProps(url: $url) {
    isEmbeddable
  }
}
    `;
export const OrganizationDocument = gql`
    query Organization($id: ID!) {
  organization(id: $id) {
    id
    name
  }
}
    `;
export const OrganizationsDocument = gql`
    query Organizations($offset: Int, $limit: Int, $orderBy: OrganizationOrderBy) {
  organizations(offset: $offset, limit: $limit, orderBy: $orderBy) {
    id
    name
  }
}
    `;
export const ProcessHtmlDocument = gql`
    mutation ProcessHtml($sourceDocument: String!, $preset: String, $analysisOverride: AnalysisResultOverrideType, $analysisOverrideConfig: String) {
  processHtml(
    sourceDocument: $sourceDocument
    preset: $preset
    analysisOverride: $analysisOverride
    analysisOverrideConfig: $analysisOverrideConfig
  ) {
    resultDocument
  }
}
    `;
export const CreateShapeDataDocument = gql`
    mutation CreateShapeData($input: CreateShapeDataInput!) {
  createShapeData(input: $input) {
    id
    data
    rowState
    shapeId
    workspaceBoardId
  }
}
    `;
export const CopyShapeDataDocument = gql`
    mutation CopyShapeData($input: CopyShapeDataInput!) {
  copyShapeData(input: $input) {
    id
    data
    rowState
    shapeId
    workspaceBoardId
  }
}
    `;
export const UpdateShapeDataDocument = gql`
    mutation UpdateShapeData($input: UpdateShapeDataInput!) {
  updateShapeData(input: $input) {
    id
    data
    rowState
    shapeId
    workspaceBoardId
  }
}
    `;
export const ShapeDataDocument = gql`
    query ShapeData($shapeId: String!, $workspaceBoardId: String!) {
  shapeData(shapeId: $shapeId, workspaceBoardId: $workspaceBoardId) {
    id
    data
    rowState
    shapeId
    workspaceBoardId
  }
}
    `;
export const ThemeDiscussionAnalysesDocument = gql`
    query ThemeDiscussionAnalyses($filters: ThemeDiscussionAnalysisFilters, $limit: Int, $offset: Int, $orderBy: [ThemeDiscussionAnalysisOrderBy]) {
  themeDiscussionAnalyses(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filters: $filters
  ) {
    results {
      id
      discussionDepth
      discussionNature
      displaySymbol
      itemDate
      itemId
      itemTitle
      participants {
        description
        fullName
        occupationTitles
        role
      }
      section
      summaries
      symbols
      themeId
      themeName
      topics
      topicsAnalysisItems {
        topic
        discussionDepth
        discussionNature
        participants {
          description
          fullName
          occupationTitles
          role
        }
      }
    }
    status
    totalCount
  }
}
    `;
export const ThemeDiscussionAnalysisDocument = gql`
    query ThemeDiscussionAnalysis($id: ID!) {
  themeDiscussionAnalysis(id: $id) {
    id
    discussionDepth
    discussionNature
    displaySymbol
    itemDate
    itemId
    itemTitle
    participants {
      description
      fullName
      occupationTitles
      role
    }
    section
    summaries
    symbols
    themeId
    themeName
    topics
    topicsAnalysisItems {
      topic
      discussionDepth
      discussionNature
      participants {
        description
        fullName
        occupationTitles
        role
      }
    }
  }
}
    `;
export const ImageTokenDocument = gql`
    query ImageToken {
  imageToken {
    token
  }
}
    `;
export const UpdateTopicDiscussionExampleDocument = gql`
    mutation UpdateTopicDiscussionExample($input: UpdateTopicDiscussionExampleInput!) {
  updateTopicDiscussionExample(input: $input) {
    id
    topic
    question
    detail
    turn
    event
    eventType
    ticker
    category
    eventDate
    speakers {
      fullName
      role
    }
    segmentId
    section
    rating
    score
    explanation
    summary
    firm
    reportTitle
    type
  }
}
    `;
export const TopicDiscussionExampleDocument = gql`
    query TopicDiscussionExample($id: ID!) {
  topicDiscussionExample(id: $id) {
    id
    topic
    question
    detail
  }
}
    `;
export const TopicDiscussionExamplesDocument = gql`
    query TopicDiscussionExamples($facets: TopicDiscussionExampleFacetsInput!) {
  topicDiscussionExamples(facets: $facets) {
    id
    topic
    question
    detail
    turn
    event
    eventType
    ticker
    category
    eventDate
    speakers {
      fullName
      role
    }
    segmentId
    section
    rating
    score
    explanation
    summary
    firm
    reportTitle
    type
  }
}
    `;
export const TopicDiscussionExampleAggregateDocument = gql`
    query TopicDiscussionExampleAggregate($facets: TopicDiscussionExampleFacetsInput!) {
  bubbleChartScale: topicDiscussionExampleQuestionCountsAggregate(facets: $facets) {
    min
    max
  }
  barChartScale: topicDiscussionExampleEventAggregate(facets: $facets) {
    min
    max
  }
}
    `;
export const UpdateTopicDiscussionInNewsArticleDocument = gql`
    mutation UpdateTopicDiscussionInNewsArticle($input: UpdateTopicDiscussionInNewsArticleInput!) {
  updateTopicDiscussionInNewsArticle(input: $input) {
    id
    theme
    topicDiscussions {
      topic
      summary
    }
    segmentId
    segmentType
    sourceType
    newSources {
      name
      url
    }
    date
    headline
    paragraph
    articleCount
  }
}
    `;
export const TopicDiscussionInNewsArticleDocument = gql`
    query TopicDiscussionInNewsArticle($id: ID!) {
  topicDiscussionInNewsArticle(id: $id) {
    id
    theme
    topicDiscussions {
      topic
      summary
    }
    segmentId
    segmentType
    sourceType
    newSources {
      name
      url
    }
    date
    headline
    paragraph
  }
}
    `;
export const TopicDiscussionInNewsArticlesDocument = gql`
    query TopicDiscussionInNewsArticles($facets: TopicDiscussionInNewsArticleFacetsInput!) {
  topicDiscussionInNewsArticles(facets: $facets) {
    id
    theme
    topicDiscussions {
      topic
      summary
    }
    segmentId
    segmentType
    sourceType
    newSources {
      name
      url
    }
    date
    headline
    paragraph
    articleCount
  }
}
    `;
export const UpdateTopicDiscussionSummaryExampleDocument = gql`
    mutation UpdateTopicDiscussionSummaryExample($input: UpdateTopicDiscussionSummaryExampleInput!) {
  updateTopicDiscussionSummaryExample(input: $input) {
    id
    topic
    questions
    questionMentionCounts
    detail
    topicMentionCount
    event
    eventType
    ticker
    category
    eventDate
    speakers {
      fullName
      role
    }
    section
    rating
    score
    summary
    analysisName
  }
}
    `;
export const TopicDiscussionSummaryExampleDocument = gql`
    query TopicDiscussionSummaryExample($id: ID!) {
  topicDiscussionSummaryExample(id: $id) {
    id
    topic
    questions
    event
    summary
    detail
  }
}
    `;
export const TopicDiscussionSummaryExamplesDocument = gql`
    query TopicDiscussionSummaryExamples($facets: TopicDiscussionSummaryExampleFacetsInput!) {
  topicDiscussionSummaryExamples(facets: $facets) {
    id
    topic
    questions
    questionMentionCounts
    detail
    topicMentionCount
    event
    eventType
    ticker
    category
    eventDate
    speakers {
      fullName
      role
    }
    section
    rating
    score
    summary
    analysisName
  }
}
    `;
export const TopicDiscussionSummaryExampleAggregateDocument = gql`
    query TopicDiscussionSummaryExampleAggregate($facets: TopicDiscussionSummaryExampleFacetsInput!) {
  bubbleChartScale: topicDiscussionSummaryExampleQuestionMentionCountsAggregate(
    facets: $facets
  ) {
    min
    max
  }
  barChartScale: topicDiscussionSummaryExampleEventAggregate(facets: $facets) {
    min
    max
  }
}
    `;
export const TranscriptsDocument = gql`
    query Transcripts($filters: TranscriptFilters, $limit: Int, $offset: Int, $orderBy: [TranscriptOrderBy]) {
  transcripts(
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filters: $filters
  ) {
    results {
      id
      displaySymbol
      eventType
      itemDate
      title
    }
    status
    totalCount
  }
  transcriptAggregate {
    minItemDate
    maxItemDate
  }
}
    `;
export const TranscriptDocument = gql`
    query Transcript($id: ID!) {
  transcript(id: $id) {
    id
    displaySymbol
    eventType
    itemDate
    title
    symbols
    transcriptItems {
      itemIndex
      fullName
      occupationTitles
      role
      speech
      session
    }
  }
}
    `;
export const UploadFileDocument = gql`
    mutation UploadFile($file: Upload!) {
  uploadFile(file: $file) {
    filename
    originalFilename
    apiDownloadUrl
    storageLocation
    mimetype
  }
}
    `;
export const UploadWorkspaceFilesDocument = gql`
    mutation UploadWorkspaceFiles($input: UploadWorkspaceFilesInput!) {
  uploadWorkspaceFiles(input: $input) {
    succeeded {
      filename
      id
      originalFilename
      storageLocation
      mimetype
    }
    failed {
      filename
      id
      originalFilename
      storageLocation
      mimetype
      reason
    }
  }
}
    `;
export const UploadDocumentDocument = gql`
    mutation UploadDocument($file: Upload!) {
  uploadDocument(file: $file) {
    filename
    originalFilename
    document {
      id
      name
      organizationId
    }
  }
}
    `;
export const UploadDocumentImageDocument = gql`
    mutation UploadDocumentImage($file: Upload!, $documentId: UUID!) {
  uploadDocumentImage(file: $file, documentId: $documentId) {
    assetDownloadUrl
  }
}
    `;
export const UploadWorkspaceBoardAssetDocument = gql`
    mutation UploadWorkspaceBoardAsset($file: Upload!, $workspaceBoardId: UUID!) {
  uploadWorkspaceBoardAsset(file: $file, workspaceBoardId: $workspaceBoardId) {
    storageLocationUrl
  }
}
    `;
export const OnWorkspaceFileAddedDocument = gql`
    subscription OnWorkspaceFileAdded($workspaceId: ID!) {
  onWorkspaceFileAdded(workspaceId: $workspaceId) {
    id
    uploadedSize
    totalSize
    fileName
    mimeType
    workspaceId
    uploadStatus
    error
  }
}
    `;
export const UserDocument = gql`
    query User($id: ID!) {
  user(id: $id) {
    id
    name
    email
  }
}
    `;
export const UsersDocument = gql`
    query Users($orderBy: UserOrderBy) {
  users(orderBy: $orderBy) {
    id
    name
    email
  }
}
    `;
export const CreateUserGroupDocument = gql`
    mutation CreateUserGroup($input: CreateUserGroupInput!) {
  createUserGroup(input: $input) {
    id
    name
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const UpdateUserGroupDocument = gql`
    mutation UpdateUserGroup($input: UpdateUserGroupInput!) {
  updateUserGroup(input: $input) {
    id
    name
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const DeleteUserGroupDocument = gql`
    mutation DeleteUserGroup($input: DeleteUserGroupInput!) {
  deleteUserGroup(input: $input) {
    id
  }
}
    `;
export const UserGroupDocument = gql`
    query UserGroup($id: ID!) {
  userGroup(id: $id) {
    id
    name
  }
}
    `;
export const UserGroupsDocument = gql`
    query UserGroups($offset: Int, $limit: Int, $orderBy: UserGroupOrderBy) {
  userGroups(offset: $offset, limit: $limit, orderBy: $orderBy) {
    id
    name
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
  userGroupAggregate {
    count
  }
}
    `;
export const CreateUserGroupMemberDocument = gql`
    mutation CreateUserGroupMember($input: CreateUserGroupMemberInput!) {
  createUserGroupMember(input: $input) {
    id
    userGroup {
      id
      name
    }
    user {
      id
      name
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const UpdateUserGroupMemberDocument = gql`
    mutation UpdateUserGroupMember($input: UpdateUserGroupMemberInput!) {
  updateUserGroupMember(input: $input) {
    id
    userGroup {
      id
      name
    }
    user {
      id
      name
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const DeleteUserGroupMemberDocument = gql`
    mutation DeleteUserGroupMember($input: DeleteUserGroupMemberInput!) {
  deleteUserGroupMember(input: $input) {
    id
  }
}
    `;
export const UserGroupMemberDocument = gql`
    query UserGroupMember($id: ID, $userGroupId: ID, $userId: ID) {
  userGroupMember(id: $id, userGroupId: $userGroupId, userId: $userId) {
    id
    userGroup {
      id
      name
    }
    user {
      id
      name
    }
  }
}
    `;
export const UserGroupMembersDocument = gql`
    query UserGroupMembers($userGroupId: ID, $userId: ID, $offset: Int, $limit: Int, $orderBy: UserGroupMemberOrderBy) {
  userGroupMembers(
    userGroupId: $userGroupId
    userId: $userId
    offset: $offset
    limit: $limit
    orderBy: $orderBy
  ) {
    id
    userGroup {
      id
      name
    }
    user {
      id
      name
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
  userGroupMemberAggregate(userGroupId: $userGroupId, userId: $userId) {
    count
  }
}
    `;
export const UpsertWorkspacesPageUserPreferencesDocument = gql`
    mutation UpsertWorkspacesPageUserPreferences($input: UpsertUserPreferenceInput!) {
  upsertUserPreference(input: $input) {
    key
    data
  }
}
    `;
export const UpsertWorkspacePageUserPreferencesDocument = gql`
    mutation UpsertWorkspacePageUserPreferences($input: UpsertUserPreferenceInput!) {
  upsertUserPreference(input: $input) {
    key
    data
  }
}
    `;
export const UpsertWorkspaceUserPreferencesDocument = gql`
    mutation UpsertWorkspaceUserPreferences($input: UpsertUserPreferenceInput!) {
  upsertUserPreference(input: $input) {
    key
    data
  }
}
    `;
export const WorkspacesPageUserPreferencesDocument = gql`
    query WorkspacesPageUserPreferences($key: String!) {
  userPreference(key: $key) {
    key
    data
  }
}
    `;
export const WorkspacePageUserPreferencesDocument = gql`
    query WorkspacePageUserPreferences($key: String!) {
  userPreference(key: $key) {
    key
    data
  }
}
    `;
export const WorkspaceUserPreferencesDocument = gql`
    query WorkspaceUserPreferences($key: String!) {
  userPreference(key: $key) {
    key
    data
  }
}
    `;
export const WorkspaceBoardUserPreferencesDocument = gql`
    query WorkspaceBoardUserPreferences($key: String!) {
  userPreference(key: $key) {
    key
    data
  }
}
    `;
export const CreateWorkspaceDocument = gql`
    mutation CreateWorkspace($input: CreateWorkspaceInput!) {
  createWorkspace(input: $input) {
    id
    description
    name
    tags
    defaultWorkspaceBoard {
      id
      name
      tags
      thumbnail
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const UpdateWorkspaceDocument = gql`
    mutation UpdateWorkspace($input: UpdateWorkspaceInput!) {
  updateWorkspace(input: $input) {
    id
    description
    name
    tags
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const DeleteWorkspaceDocument = gql`
    mutation DeleteWorkspace($input: DeleteWorkspaceInput!) {
  deleteWorkspace(input: $input) {
    id
  }
}
    `;
export const CopyWorkspaceDocument = gql`
    mutation CopyWorkspace($input: CopyWorkspaceInput!) {
  copyWorkspace(input: $input) {
    id
    description
    name
    tags
    defaultWorkspaceBoard {
      id
      name
      tags
      thumbnail
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const ReindexWorkspaceDocument = gql`
    mutation ReindexWorkspace($input: ReindexWorkspaceInput!) {
  reindexWorkspace(input: $input) {
    id
  }
}
    `;
export const WorkspaceDocument = gql`
    query Workspace($id: ID!) {
  workspace(id: $id) {
    id
    defaultWorkspaceBoard {
      id
      thumbnail
    }
    description
    name
    tags
  }
}
    `;
export const WorkspacesDocument = gql`
    query Workspaces($offset: Int, $limit: Int, $orderBy: WorkspaceOrderBy, $filters: WorkspaceFilters) {
  workspaces(offset: $offset, limit: $limit, orderBy: $orderBy, filters: $filters) {
    id
    defaultWorkspaceBoard {
      id
      thumbnail
    }
    description
    name
    tags
    updatedAt
    workspaceAccessControlList {
      userGroup {
        name
      }
      user {
        id
        name
      }
    }
  }
  workspaceAggregate(filters: $filters) {
    count
  }
}
    `;
export const WorkspaceTagsDocument = gql`
    query WorkspaceTags {
  workspaceTags
}
    `;
export const CreateWorkspaceAccessControlListDocument = gql`
    mutation CreateWorkspaceAccessControlList($input: CreateWorkspaceAccessControlListInput!) {
  createWorkspaceAccessControlList(input: $input) {
    id
    workspaceId
    role
    userGroup {
      id
      name
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const UpdateWorkspaceAccessControlListDocument = gql`
    mutation UpdateWorkspaceAccessControlList($input: UpdateWorkspaceAccessControlListInput!) {
  updateWorkspaceAccessControlList(input: $input) {
    id
    workspaceId
    role
    userGroup {
      id
      name
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const DeleteWorkspaceAccessControlListDocument = gql`
    mutation DeleteWorkspaceAccessControlList($input: DeleteWorkspaceAccessControlListInput!) {
  deleteWorkspaceAccessControlList(input: $input) {
    id
  }
}
    `;
export const WorkspaceAccessControlListDocument = gql`
    query WorkspaceAccessControlList($id: ID!) {
  workspaceAccessControlList(id: $id) {
    id
    workspaceId
    role
    userGroup {
      id
      name
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const WorkspaceAccessControlListByObjectDocument = gql`
    query WorkspaceAccessControlListByObject($workspaceId: ID!) {
  workspaceAccessControlListByObject(workspaceId: $workspaceId) {
    id
    workspaceId
    role
    userGroup {
      id
      name
    }
    user {
      id
      name
      profilePictureUrl
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
  workspaceAccessControlListByObjectAggregate(workspaceId: $workspaceId) {
    count
  }
}
    `;
export const WorkspaceAccessControlListUserRolesDocument = gql`
    query WorkspaceAccessControlListUserRoles($userId: ID!, $workspaceId: ID!) {
  workspaceAccessControlListUserRoles(userId: $userId, workspaceId: $workspaceId) {
    userId
    objectId
    roles
  }
}
    `;
export const WorkspaceBoardAccessControlListUserRolesDocument = gql`
    query WorkspaceBoardAccessControlListUserRoles($userId: ID!, $workspaceBoardId: ID!) {
  workspaceBoardAccessControlListUserRoles(
    userId: $userId
    workspaceBoardId: $workspaceBoardId
  ) {
    userId
    objectId
    roles
  }
}
    `;
export const CreateWorkspaceBoardDocument = gql`
    mutation CreateWorkspaceBoard($input: CreateWorkspaceBoardInput!) {
  createWorkspaceBoard(input: $input) {
    id
    content
    description
    name
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const DeleteWorkspaceBoardDocument = gql`
    mutation DeleteWorkspaceBoard($input: DeleteWorkspaceBoardInput!) {
  deleteWorkspaceBoard(input: $input) {
    id
  }
}
    `;
export const UpdateWorkspaceBoardDocument = gql`
    mutation UpdateWorkspaceBoard($input: UpdateWorkspaceBoardInput!) {
  updateWorkspaceBoard(input: $input) {
    content
    description
    id
    isThumbnailLocked
    name
    tags
    thumbnail
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const MarkWorkspaceBoardShapesAsDeletedDocument = gql`
    mutation MarkWorkspaceBoardShapesAsDeleted($input: MarkWorkspaceBoardShapeAsDeletedInput!) {
  markWorkspaceBoardShapesAsDeleted(input: $input)
}
    `;
export const WorkspaceBoardDocument = gql`
    query WorkspaceBoard($id: ID!) {
  workspaceBoard(id: $id) {
    content
    description
    id
    isThumbnailLocked
    name
    tags
    thumbnail
    workspaceId
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const WorkspaceBoardsDocument = gql`
    query WorkspaceBoards($workspaceId: ID!, $offset: Int, $limit: Int, $orderBy: WorkspaceBoardOrderBy, $filters: WorkspaceBoardFilters) {
  workspaceBoards(
    workspaceId: $workspaceId
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filters: $filters
  ) {
    content
    description
    id
    isThumbnailLocked
    name
    tags
    thumbnail
    workspaceId
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
  workspaceBoardAggregate(workspaceId: $workspaceId, filters: $filters) {
    count
  }
}
    `;
export const WorkspaceBoardAggregateDocument = gql`
    query WorkspaceBoardAggregate($workspaceId: ID!, $filters: WorkspaceBoardFilters) {
  workspaceBoardAggregate(workspaceId: $workspaceId, filters: $filters) {
    count
  }
}
    `;
export const WorkspaceBoardTagsDocument = gql`
    query WorkspaceBoardTags($workspaceId: ID!) {
  workspaceBoardTags(workspaceId: $workspaceId)
}
    `;
export const CreateWorkspaceFileDocument = gql`
    mutation CreateWorkspaceFile($input: CreateWorkspaceFileInput!) {
  createWorkspaceFile(input: $input) {
    id
    name
    url
    filename
    mimeType
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const UpdateWorkspaceFileDocument = gql`
    mutation UpdateWorkspaceFile($input: UpdateWorkspaceFileInput!) {
  updateWorkspaceFile(input: $input) {
    id
    name
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
}
    `;
export const DeleteWorkspaceFileDocument = gql`
    mutation DeleteWorkspaceFile($input: DeleteWorkspaceFileInput!) {
  deleteWorkspaceFile(input: $input) {
    id
  }
}
    `;
export const WorkspaceFileDocument = gql`
    query WorkspaceFile($id: ID!) {
  workspaceFile(id: $id) {
    id
    name
    filename
    workspaceId
  }
}
    `;
export const WorkspaceFilesDocument = gql`
    query WorkspaceFiles($workspaceId: ID!, $offset: Int, $limit: Int, $orderBy: WorkspaceFileOrderBy, $filters: WorkspaceFileFilters) {
  workspaceFiles(
    workspaceId: $workspaceId
    offset: $offset
    limit: $limit
    orderBy: $orderBy
    filters: $filters
  ) {
    id
    name
    url
    filename
    mimeType
    workspaceId
    updatedAt
  }
  workspaceFileAggregate(workspaceId: $workspaceId, filters: $filters) {
    count
  }
}
    `;
export const WorkspaceFileAggregateDocument = gql`
    query WorkspaceFileAggregate($workspaceId: ID!, $filters: WorkspaceFileFilters) {
  workspaceFileAggregate(workspaceId: $workspaceId, filters: $filters) {
    count
  }
}
    `;
export const UpsertWorkspaceBoardUserPreferencesCacheDocument = gql`
    query UpsertWorkspaceBoardUserPreferencesCache($key: String!) {
  userPreference(key: $key) {
    key
    data
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    EmptyQuery(variables?: IEmptyQueryQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IEmptyQueryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IEmptyQueryQuery>(EmptyQueryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'EmptyQuery', 'query', variables);
    },
    FeedTranscriptsTranscript(variables?: IFeedTranscriptsTranscriptQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IFeedTranscriptsTranscriptQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IFeedTranscriptsTranscriptQuery>(FeedTranscriptsTranscriptDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FeedTranscriptsTranscript', 'query', variables);
    },
    ThemeDiscussionAnalysisParentTranscript(variables: IThemeDiscussionAnalysisParentTranscriptQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IThemeDiscussionAnalysisParentTranscriptQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IThemeDiscussionAnalysisParentTranscriptQuery>(ThemeDiscussionAnalysisParentTranscriptDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ThemeDiscussionAnalysisParentTranscript', 'query', variables);
    },
    ThemeDiscussionAnalysisParentTranscripts(variables?: IThemeDiscussionAnalysisParentTranscriptsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IThemeDiscussionAnalysisParentTranscriptsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IThemeDiscussionAnalysisParentTranscriptsQuery>(ThemeDiscussionAnalysisParentTranscriptsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ThemeDiscussionAnalysisParentTranscripts', 'query', variables);
    },
    CreateAnalysisFeedback(variables: ICreateAnalysisFeedbackMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateAnalysisFeedbackMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateAnalysisFeedbackMutation>(CreateAnalysisFeedbackDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateAnalysisFeedback', 'mutation', variables);
    },
    UpdateAnalystQuestion(variables: IUpdateAnalystQuestionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateAnalystQuestionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateAnalystQuestionMutation>(UpdateAnalystQuestionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateAnalystQuestion', 'mutation', variables);
    },
    AnalystQuestion(variables: IAnalystQuestionQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IAnalystQuestionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IAnalystQuestionQuery>(AnalystQuestionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AnalystQuestion', 'query', variables);
    },
    AnalystQuestions(variables: IAnalystQuestionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IAnalystQuestionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IAnalystQuestionsQuery>(AnalystQuestionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AnalystQuestions', 'query', variables);
    },
    GroupedAnalystQuestions(variables: IGroupedAnalystQuestionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGroupedAnalystQuestionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGroupedAnalystQuestionsQuery>(GroupedAnalystQuestionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GroupedAnalystQuestions', 'query', variables);
    },
    CreateChatMessage(variables: ICreateChatMessageMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateChatMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateChatMessageMutation>(CreateChatMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateChatMessage', 'mutation', variables);
    },
    UpdateChatMessage(variables: IUpdateChatMessageMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateChatMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateChatMessageMutation>(UpdateChatMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateChatMessage', 'mutation', variables);
    },
    DeleteChatMessage(variables: IDeleteChatMessageMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteChatMessageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteChatMessageMutation>(DeleteChatMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteChatMessage', 'mutation', variables);
    },
    ChatMessage(variables: IChatMessageQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IChatMessageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IChatMessageQuery>(ChatMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ChatMessage', 'query', variables);
    },
    ChatMessages(variables: IChatMessagesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IChatMessagesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IChatMessagesQuery>(ChatMessagesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ChatMessages', 'query', variables);
    },
    OnChatMessagesAdded(variables: IOnChatMessagesAddedSubscriptionVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IOnChatMessagesAddedSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<IOnChatMessagesAddedSubscription>(OnChatMessagesAddedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OnChatMessagesAdded', 'subscription', variables);
    },
    OnChatMessagesUpdated(variables: IOnChatMessagesUpdatedSubscriptionVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IOnChatMessagesUpdatedSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<IOnChatMessagesUpdatedSubscription>(OnChatMessagesUpdatedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OnChatMessagesUpdated', 'subscription', variables);
    },
    OnChatMessagesDeleted(variables: IOnChatMessagesDeletedSubscriptionVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IOnChatMessagesDeletedSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<IOnChatMessagesDeletedSubscription>(OnChatMessagesDeletedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OnChatMessagesDeleted', 'subscription', variables);
    },
    ExecuteCommand(variables: IExecuteCommandMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IExecuteCommandMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IExecuteCommandMutation>(ExecuteCommandDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ExecuteCommand', 'mutation', variables);
    },
    CommandPing(variables: ICommandPingMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICommandPingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICommandPingMutation>(CommandPingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CommandPing', 'mutation', variables);
    },
    OnCommandResponse(variables: IOnCommandResponseSubscriptionVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IOnCommandResponseSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<IOnCommandResponseSubscription>(OnCommandResponseDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OnCommandResponse', 'subscription', variables);
    },
    getLatestConfigsByPrefix(variables: IGetLatestConfigsByPrefixQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetLatestConfigsByPrefixQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetLatestConfigsByPrefixQuery>(GetLatestConfigsByPrefixDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLatestConfigsByPrefix', 'query', variables);
    },
    getConfigData(variables: IGetConfigDataQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetConfigDataQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetConfigDataQuery>(GetConfigDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getConfigData', 'query', variables);
    },
    getAppConfigData(variables: IGetAppConfigDataQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetAppConfigDataQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetAppConfigDataQuery>(GetAppConfigDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAppConfigData', 'query', variables);
    },
    DistilledTranscript(variables: IDistilledTranscriptQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDistilledTranscriptQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDistilledTranscriptQuery>(DistilledTranscriptDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DistilledTranscript', 'query', variables);
    },
    CreateDocument(variables: ICreateDocumentMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateDocumentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateDocumentMutation>(CreateDocumentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateDocument', 'mutation', variables);
    },
    UpdateDocument(variables: IUpdateDocumentMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateDocumentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateDocumentMutation>(UpdateDocumentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateDocument', 'mutation', variables);
    },
    DeleteDocument(variables: IDeleteDocumentMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteDocumentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteDocumentMutation>(DeleteDocumentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteDocument', 'mutation', variables);
    },
    Document(variables: IDocumentQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDocumentQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDocumentQuery>(DocumentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Document', 'query', variables);
    },
    Documents(variables?: IDocumentsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDocumentsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDocumentsQuery>(DocumentsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Documents', 'query', variables);
    },
    CreateDocumentAccessControlList(variables: ICreateDocumentAccessControlListMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateDocumentAccessControlListMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateDocumentAccessControlListMutation>(CreateDocumentAccessControlListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateDocumentAccessControlList', 'mutation', variables);
    },
    UpdateDocumentAccessControlList(variables: IUpdateDocumentAccessControlListMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateDocumentAccessControlListMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateDocumentAccessControlListMutation>(UpdateDocumentAccessControlListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateDocumentAccessControlList', 'mutation', variables);
    },
    DeleteDocumentAccessControlList(variables: IDeleteDocumentAccessControlListMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteDocumentAccessControlListMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteDocumentAccessControlListMutation>(DeleteDocumentAccessControlListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteDocumentAccessControlList', 'mutation', variables);
    },
    DocumentAccessControlList(variables: IDocumentAccessControlListQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDocumentAccessControlListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDocumentAccessControlListQuery>(DocumentAccessControlListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DocumentAccessControlList', 'query', variables);
    },
    DocumentAccessControlListByObject(variables: IDocumentAccessControlListByObjectQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDocumentAccessControlListByObjectQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDocumentAccessControlListByObjectQuery>(DocumentAccessControlListByObjectDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DocumentAccessControlListByObject', 'query', variables);
    },
    DocumentAccessControlListUserRoles(variables: IDocumentAccessControlListUserRolesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDocumentAccessControlListUserRolesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDocumentAccessControlListUserRolesQuery>(DocumentAccessControlListUserRolesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DocumentAccessControlListUserRoles', 'query', variables);
    },
    UpdateDocumentContent(variables: IUpdateDocumentContentMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateDocumentContentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateDocumentContentMutation>(UpdateDocumentContentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateDocumentContent', 'mutation', variables);
    },
    CreateFile(variables: ICreateFileMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateFileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateFileMutation>(CreateFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateFile', 'mutation', variables);
    },
    UpdateFile(variables: IUpdateFileMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateFileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateFileMutation>(UpdateFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateFile', 'mutation', variables);
    },
    DeleteFile(variables: IDeleteFileMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteFileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteFileMutation>(DeleteFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteFile', 'mutation', variables);
    },
    File(variables: IFileQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IFileQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IFileQuery>(FileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'File', 'query', variables);
    },
    Files(variables?: IFilesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IFilesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IFilesQuery>(FilesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Files', 'query', variables);
    },
    CreateFileAccessControlList(variables: ICreateFileAccessControlListMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateFileAccessControlListMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateFileAccessControlListMutation>(CreateFileAccessControlListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateFileAccessControlList', 'mutation', variables);
    },
    UpdateFileAccessControlList(variables: IUpdateFileAccessControlListMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateFileAccessControlListMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateFileAccessControlListMutation>(UpdateFileAccessControlListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateFileAccessControlList', 'mutation', variables);
    },
    DeleteFileAccessControlList(variables: IDeleteFileAccessControlListMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteFileAccessControlListMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteFileAccessControlListMutation>(DeleteFileAccessControlListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteFileAccessControlList', 'mutation', variables);
    },
    FileAccessControlList(variables: IFileAccessControlListQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IFileAccessControlListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IFileAccessControlListQuery>(FileAccessControlListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FileAccessControlList', 'query', variables);
    },
    FileAccessControlListByObject(variables: IFileAccessControlListByObjectQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IFileAccessControlListByObjectQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IFileAccessControlListByObjectQuery>(FileAccessControlListByObjectDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FileAccessControlListByObject', 'query', variables);
    },
    FileAccessControlListUserRoles(variables: IFileAccessControlListUserRolesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IFileAccessControlListUserRolesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IFileAccessControlListUserRolesQuery>(FileAccessControlListUserRolesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FileAccessControlListUserRoles', 'query', variables);
    },
    LinkProps(variables: ILinkPropsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ILinkPropsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ILinkPropsQuery>(LinkPropsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LinkProps', 'query', variables);
    },
    Organization(variables: IOrganizationQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IOrganizationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IOrganizationQuery>(OrganizationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Organization', 'query', variables);
    },
    Organizations(variables?: IOrganizationsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IOrganizationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IOrganizationsQuery>(OrganizationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Organizations', 'query', variables);
    },
    ProcessHtml(variables: IProcessHtmlMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IProcessHtmlMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IProcessHtmlMutation>(ProcessHtmlDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ProcessHtml', 'mutation', variables);
    },
    CreateShapeData(variables: ICreateShapeDataMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateShapeDataMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateShapeDataMutation>(CreateShapeDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateShapeData', 'mutation', variables);
    },
    CopyShapeData(variables: ICopyShapeDataMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICopyShapeDataMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICopyShapeDataMutation>(CopyShapeDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CopyShapeData', 'mutation', variables);
    },
    UpdateShapeData(variables: IUpdateShapeDataMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateShapeDataMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateShapeDataMutation>(UpdateShapeDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateShapeData', 'mutation', variables);
    },
    ShapeData(variables: IShapeDataQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IShapeDataQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IShapeDataQuery>(ShapeDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ShapeData', 'query', variables);
    },
    ThemeDiscussionAnalyses(variables?: IThemeDiscussionAnalysesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IThemeDiscussionAnalysesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IThemeDiscussionAnalysesQuery>(ThemeDiscussionAnalysesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ThemeDiscussionAnalyses', 'query', variables);
    },
    ThemeDiscussionAnalysis(variables: IThemeDiscussionAnalysisQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IThemeDiscussionAnalysisQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IThemeDiscussionAnalysisQuery>(ThemeDiscussionAnalysisDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ThemeDiscussionAnalysis', 'query', variables);
    },
    ImageToken(variables?: IImageTokenQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IImageTokenQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IImageTokenQuery>(ImageTokenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ImageToken', 'query', variables);
    },
    UpdateTopicDiscussionExample(variables: IUpdateTopicDiscussionExampleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateTopicDiscussionExampleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateTopicDiscussionExampleMutation>(UpdateTopicDiscussionExampleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateTopicDiscussionExample', 'mutation', variables);
    },
    TopicDiscussionExample(variables: ITopicDiscussionExampleQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ITopicDiscussionExampleQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ITopicDiscussionExampleQuery>(TopicDiscussionExampleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TopicDiscussionExample', 'query', variables);
    },
    TopicDiscussionExamples(variables: ITopicDiscussionExamplesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ITopicDiscussionExamplesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ITopicDiscussionExamplesQuery>(TopicDiscussionExamplesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TopicDiscussionExamples', 'query', variables);
    },
    TopicDiscussionExampleAggregate(variables: ITopicDiscussionExampleAggregateQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ITopicDiscussionExampleAggregateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ITopicDiscussionExampleAggregateQuery>(TopicDiscussionExampleAggregateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TopicDiscussionExampleAggregate', 'query', variables);
    },
    UpdateTopicDiscussionInNewsArticle(variables: IUpdateTopicDiscussionInNewsArticleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateTopicDiscussionInNewsArticleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateTopicDiscussionInNewsArticleMutation>(UpdateTopicDiscussionInNewsArticleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateTopicDiscussionInNewsArticle', 'mutation', variables);
    },
    TopicDiscussionInNewsArticle(variables: ITopicDiscussionInNewsArticleQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ITopicDiscussionInNewsArticleQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ITopicDiscussionInNewsArticleQuery>(TopicDiscussionInNewsArticleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TopicDiscussionInNewsArticle', 'query', variables);
    },
    TopicDiscussionInNewsArticles(variables: ITopicDiscussionInNewsArticlesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ITopicDiscussionInNewsArticlesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ITopicDiscussionInNewsArticlesQuery>(TopicDiscussionInNewsArticlesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TopicDiscussionInNewsArticles', 'query', variables);
    },
    UpdateTopicDiscussionSummaryExample(variables: IUpdateTopicDiscussionSummaryExampleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateTopicDiscussionSummaryExampleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateTopicDiscussionSummaryExampleMutation>(UpdateTopicDiscussionSummaryExampleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateTopicDiscussionSummaryExample', 'mutation', variables);
    },
    TopicDiscussionSummaryExample(variables: ITopicDiscussionSummaryExampleQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ITopicDiscussionSummaryExampleQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ITopicDiscussionSummaryExampleQuery>(TopicDiscussionSummaryExampleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TopicDiscussionSummaryExample', 'query', variables);
    },
    TopicDiscussionSummaryExamples(variables: ITopicDiscussionSummaryExamplesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ITopicDiscussionSummaryExamplesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ITopicDiscussionSummaryExamplesQuery>(TopicDiscussionSummaryExamplesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TopicDiscussionSummaryExamples', 'query', variables);
    },
    TopicDiscussionSummaryExampleAggregate(variables: ITopicDiscussionSummaryExampleAggregateQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ITopicDiscussionSummaryExampleAggregateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ITopicDiscussionSummaryExampleAggregateQuery>(TopicDiscussionSummaryExampleAggregateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TopicDiscussionSummaryExampleAggregate', 'query', variables);
    },
    Transcripts(variables?: ITranscriptsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ITranscriptsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ITranscriptsQuery>(TranscriptsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Transcripts', 'query', variables);
    },
    Transcript(variables: ITranscriptQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ITranscriptQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ITranscriptQuery>(TranscriptDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Transcript', 'query', variables);
    },
    UploadFile(variables: IUploadFileMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUploadFileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUploadFileMutation>(UploadFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UploadFile', 'mutation', variables);
    },
    UploadWorkspaceFiles(variables: IUploadWorkspaceFilesMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUploadWorkspaceFilesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUploadWorkspaceFilesMutation>(UploadWorkspaceFilesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UploadWorkspaceFiles', 'mutation', variables);
    },
    UploadDocument(variables: IUploadDocumentMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUploadDocumentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUploadDocumentMutation>(UploadDocumentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UploadDocument', 'mutation', variables);
    },
    UploadDocumentImage(variables: IUploadDocumentImageMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUploadDocumentImageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUploadDocumentImageMutation>(UploadDocumentImageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UploadDocumentImage', 'mutation', variables);
    },
    UploadWorkspaceBoardAsset(variables: IUploadWorkspaceBoardAssetMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUploadWorkspaceBoardAssetMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUploadWorkspaceBoardAssetMutation>(UploadWorkspaceBoardAssetDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UploadWorkspaceBoardAsset', 'mutation', variables);
    },
    OnWorkspaceFileAdded(variables: IOnWorkspaceFileAddedSubscriptionVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IOnWorkspaceFileAddedSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<IOnWorkspaceFileAddedSubscription>(OnWorkspaceFileAddedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'OnWorkspaceFileAdded', 'subscription', variables);
    },
    User(variables: IUserQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUserQuery>(UserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'User', 'query', variables);
    },
    Users(variables?: IUsersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUsersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUsersQuery>(UsersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Users', 'query', variables);
    },
    CreateUserGroup(variables: ICreateUserGroupMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateUserGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateUserGroupMutation>(CreateUserGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateUserGroup', 'mutation', variables);
    },
    UpdateUserGroup(variables: IUpdateUserGroupMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateUserGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateUserGroupMutation>(UpdateUserGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateUserGroup', 'mutation', variables);
    },
    DeleteUserGroup(variables: IDeleteUserGroupMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteUserGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteUserGroupMutation>(DeleteUserGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteUserGroup', 'mutation', variables);
    },
    UserGroup(variables: IUserGroupQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUserGroupQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUserGroupQuery>(UserGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserGroup', 'query', variables);
    },
    UserGroups(variables?: IUserGroupsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUserGroupsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUserGroupsQuery>(UserGroupsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserGroups', 'query', variables);
    },
    CreateUserGroupMember(variables: ICreateUserGroupMemberMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateUserGroupMemberMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateUserGroupMemberMutation>(CreateUserGroupMemberDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateUserGroupMember', 'mutation', variables);
    },
    UpdateUserGroupMember(variables: IUpdateUserGroupMemberMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateUserGroupMemberMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateUserGroupMemberMutation>(UpdateUserGroupMemberDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateUserGroupMember', 'mutation', variables);
    },
    DeleteUserGroupMember(variables: IDeleteUserGroupMemberMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteUserGroupMemberMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteUserGroupMemberMutation>(DeleteUserGroupMemberDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteUserGroupMember', 'mutation', variables);
    },
    UserGroupMember(variables?: IUserGroupMemberQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUserGroupMemberQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUserGroupMemberQuery>(UserGroupMemberDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserGroupMember', 'query', variables);
    },
    UserGroupMembers(variables?: IUserGroupMembersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUserGroupMembersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUserGroupMembersQuery>(UserGroupMembersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserGroupMembers', 'query', variables);
    },
    UpsertWorkspacesPageUserPreferences(variables: IUpsertWorkspacesPageUserPreferencesMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpsertWorkspacesPageUserPreferencesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpsertWorkspacesPageUserPreferencesMutation>(UpsertWorkspacesPageUserPreferencesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpsertWorkspacesPageUserPreferences', 'mutation', variables);
    },
    UpsertWorkspacePageUserPreferences(variables: IUpsertWorkspacePageUserPreferencesMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpsertWorkspacePageUserPreferencesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpsertWorkspacePageUserPreferencesMutation>(UpsertWorkspacePageUserPreferencesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpsertWorkspacePageUserPreferences', 'mutation', variables);
    },
    UpsertWorkspaceUserPreferences(variables: IUpsertWorkspaceUserPreferencesMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpsertWorkspaceUserPreferencesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpsertWorkspaceUserPreferencesMutation>(UpsertWorkspaceUserPreferencesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpsertWorkspaceUserPreferences', 'mutation', variables);
    },
    WorkspacesPageUserPreferences(variables: IWorkspacesPageUserPreferencesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspacesPageUserPreferencesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspacesPageUserPreferencesQuery>(WorkspacesPageUserPreferencesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspacesPageUserPreferences', 'query', variables);
    },
    WorkspacePageUserPreferences(variables: IWorkspacePageUserPreferencesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspacePageUserPreferencesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspacePageUserPreferencesQuery>(WorkspacePageUserPreferencesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspacePageUserPreferences', 'query', variables);
    },
    WorkspaceUserPreferences(variables: IWorkspaceUserPreferencesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspaceUserPreferencesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspaceUserPreferencesQuery>(WorkspaceUserPreferencesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspaceUserPreferences', 'query', variables);
    },
    WorkspaceBoardUserPreferences(variables: IWorkspaceBoardUserPreferencesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspaceBoardUserPreferencesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspaceBoardUserPreferencesQuery>(WorkspaceBoardUserPreferencesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspaceBoardUserPreferences', 'query', variables);
    },
    CreateWorkspace(variables: ICreateWorkspaceMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateWorkspaceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateWorkspaceMutation>(CreateWorkspaceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateWorkspace', 'mutation', variables);
    },
    UpdateWorkspace(variables: IUpdateWorkspaceMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateWorkspaceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateWorkspaceMutation>(UpdateWorkspaceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateWorkspace', 'mutation', variables);
    },
    DeleteWorkspace(variables: IDeleteWorkspaceMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteWorkspaceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteWorkspaceMutation>(DeleteWorkspaceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteWorkspace', 'mutation', variables);
    },
    CopyWorkspace(variables: ICopyWorkspaceMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICopyWorkspaceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICopyWorkspaceMutation>(CopyWorkspaceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CopyWorkspace', 'mutation', variables);
    },
    ReindexWorkspace(variables: IReindexWorkspaceMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IReindexWorkspaceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IReindexWorkspaceMutation>(ReindexWorkspaceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ReindexWorkspace', 'mutation', variables);
    },
    Workspace(variables: IWorkspaceQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspaceQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspaceQuery>(WorkspaceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Workspace', 'query', variables);
    },
    Workspaces(variables?: IWorkspacesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspacesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspacesQuery>(WorkspacesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Workspaces', 'query', variables);
    },
    WorkspaceTags(variables?: IWorkspaceTagsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspaceTagsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspaceTagsQuery>(WorkspaceTagsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspaceTags', 'query', variables);
    },
    CreateWorkspaceAccessControlList(variables: ICreateWorkspaceAccessControlListMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateWorkspaceAccessControlListMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateWorkspaceAccessControlListMutation>(CreateWorkspaceAccessControlListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateWorkspaceAccessControlList', 'mutation', variables);
    },
    UpdateWorkspaceAccessControlList(variables: IUpdateWorkspaceAccessControlListMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateWorkspaceAccessControlListMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateWorkspaceAccessControlListMutation>(UpdateWorkspaceAccessControlListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateWorkspaceAccessControlList', 'mutation', variables);
    },
    DeleteWorkspaceAccessControlList(variables: IDeleteWorkspaceAccessControlListMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteWorkspaceAccessControlListMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteWorkspaceAccessControlListMutation>(DeleteWorkspaceAccessControlListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteWorkspaceAccessControlList', 'mutation', variables);
    },
    WorkspaceAccessControlList(variables: IWorkspaceAccessControlListQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspaceAccessControlListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspaceAccessControlListQuery>(WorkspaceAccessControlListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspaceAccessControlList', 'query', variables);
    },
    WorkspaceAccessControlListByObject(variables: IWorkspaceAccessControlListByObjectQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspaceAccessControlListByObjectQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspaceAccessControlListByObjectQuery>(WorkspaceAccessControlListByObjectDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspaceAccessControlListByObject', 'query', variables);
    },
    WorkspaceAccessControlListUserRoles(variables: IWorkspaceAccessControlListUserRolesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspaceAccessControlListUserRolesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspaceAccessControlListUserRolesQuery>(WorkspaceAccessControlListUserRolesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspaceAccessControlListUserRoles', 'query', variables);
    },
    WorkspaceBoardAccessControlListUserRoles(variables: IWorkspaceBoardAccessControlListUserRolesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspaceBoardAccessControlListUserRolesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspaceBoardAccessControlListUserRolesQuery>(WorkspaceBoardAccessControlListUserRolesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspaceBoardAccessControlListUserRoles', 'query', variables);
    },
    CreateWorkspaceBoard(variables: ICreateWorkspaceBoardMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateWorkspaceBoardMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateWorkspaceBoardMutation>(CreateWorkspaceBoardDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateWorkspaceBoard', 'mutation', variables);
    },
    DeleteWorkspaceBoard(variables: IDeleteWorkspaceBoardMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteWorkspaceBoardMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteWorkspaceBoardMutation>(DeleteWorkspaceBoardDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteWorkspaceBoard', 'mutation', variables);
    },
    UpdateWorkspaceBoard(variables: IUpdateWorkspaceBoardMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateWorkspaceBoardMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateWorkspaceBoardMutation>(UpdateWorkspaceBoardDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateWorkspaceBoard', 'mutation', variables);
    },
    MarkWorkspaceBoardShapesAsDeleted(variables: IMarkWorkspaceBoardShapesAsDeletedMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IMarkWorkspaceBoardShapesAsDeletedMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IMarkWorkspaceBoardShapesAsDeletedMutation>(MarkWorkspaceBoardShapesAsDeletedDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MarkWorkspaceBoardShapesAsDeleted', 'mutation', variables);
    },
    WorkspaceBoard(variables: IWorkspaceBoardQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspaceBoardQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspaceBoardQuery>(WorkspaceBoardDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspaceBoard', 'query', variables);
    },
    WorkspaceBoards(variables: IWorkspaceBoardsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspaceBoardsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspaceBoardsQuery>(WorkspaceBoardsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspaceBoards', 'query', variables);
    },
    WorkspaceBoardAggregate(variables: IWorkspaceBoardAggregateQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspaceBoardAggregateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspaceBoardAggregateQuery>(WorkspaceBoardAggregateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspaceBoardAggregate', 'query', variables);
    },
    WorkspaceBoardTags(variables: IWorkspaceBoardTagsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspaceBoardTagsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspaceBoardTagsQuery>(WorkspaceBoardTagsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspaceBoardTags', 'query', variables);
    },
    CreateWorkspaceFile(variables: ICreateWorkspaceFileMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateWorkspaceFileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateWorkspaceFileMutation>(CreateWorkspaceFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateWorkspaceFile', 'mutation', variables);
    },
    UpdateWorkspaceFile(variables: IUpdateWorkspaceFileMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateWorkspaceFileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateWorkspaceFileMutation>(UpdateWorkspaceFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateWorkspaceFile', 'mutation', variables);
    },
    DeleteWorkspaceFile(variables: IDeleteWorkspaceFileMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteWorkspaceFileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteWorkspaceFileMutation>(DeleteWorkspaceFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteWorkspaceFile', 'mutation', variables);
    },
    WorkspaceFile(variables: IWorkspaceFileQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspaceFileQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspaceFileQuery>(WorkspaceFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspaceFile', 'query', variables);
    },
    WorkspaceFiles(variables: IWorkspaceFilesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspaceFilesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspaceFilesQuery>(WorkspaceFilesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspaceFiles', 'query', variables);
    },
    WorkspaceFileAggregate(variables: IWorkspaceFileAggregateQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IWorkspaceFileAggregateQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IWorkspaceFileAggregateQuery>(WorkspaceFileAggregateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'WorkspaceFileAggregate', 'query', variables);
    },
    UpsertWorkspaceBoardUserPreferencesCache(variables: IUpsertWorkspaceBoardUserPreferencesCacheQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpsertWorkspaceBoardUserPreferencesCacheQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpsertWorkspaceBoardUserPreferencesCacheQuery>(UpsertWorkspaceBoardUserPreferencesCacheDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpsertWorkspaceBoardUserPreferencesCache', 'query', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;