import { useApolloClient } from "@apollo/client";
import { FileUploadStatus } from "@bigpi/cookbook";
import { useAuthUser } from "@frontegg/react";
import { Check, WarningAmberRounded, Clear, Delete, PanoramaFishEye, Replay } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgressProps,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { filesize } from "filesize";
import { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { WORKSPACE_FILE_UPLOAD_SUBSCRIPTION } from "GraphQL/Upload/Subscription";
import { useUploadWorkspaceFilesBatch } from "./Hooks/useUploadWorkspaceFilesBatch";
import { useIsWorkspaceEditable } from "./Hooks/useIsWorkspaceEditable";

// *********************************************
// Types/Interfaces/Constants
// *********************************************/
export interface IFileWithId {
  id: string;
  file: File;
  failureReason?: string;
}

interface SelectedFilesListDialogProps {
  onClose: () => void;
  onRemoveFile: (id: string) => void;
  selectedFiles: Array<IFileWithId>;
  workspaceId: string;
}

// *********************************************
// Component
// *********************************************/
export function SelectedFilesListDialog(props: SelectedFilesListDialogProps) {
  const { onClose, onRemoveFile, selectedFiles, workspaceId } = props;

  const theme = useTheme();
  const client = useApolloClient();
  const { t } = useTranslation();

  // State
  const [progressingFiles, setProgressingFiles] = useState<Record<string, number>>({});
  const [processedFiles, setProcessedFiles] = useState<Array<string>>([]);
  const [processFailedFiles, setProcessFailedFiles] = useState<Record<string, string>>({});
  const [activeItem, setActiveItem] = useState<number>();

  // Ref
  const selectedFilesRef = useRef<Array<IFileWithId>>([]);

  const { abortUpload, cancelledFiles, failedFiles, successFiles, uploadingFiles, uploadWorkspaceFilesBatch } =
    useUploadWorkspaceFilesBatch(workspaceId);

  const { isEditable: isWorkspaceEditable } = useIsWorkspaceEditable(workspaceId);

  useEffect(() => {
    // Subscribe to the uploading progress of the files
    const clientSubscribe = client
      .subscribe({ query: WORKSPACE_FILE_UPLOAD_SUBSCRIPTION, variables: { workspaceId } })
      .subscribe({
        next: (response) => {
          const addFileResponse = response.data.onWorkspaceFileAdded;
          // Check if the response is for the current workspace
          if (addFileResponse && workspaceId === addFileResponse.workspaceId) {
            const fileId = addFileResponse.id;
            if (addFileResponse.uploadStatus === FileUploadStatus.Running) {
              setProgressingFiles({
                [fileId]: addFileResponse.uploadedSize / addFileResponse.totalSize,
              });
            } else if (addFileResponse.uploadStatus === FileUploadStatus.Success) {
              setProcessedFiles((prev) => [...prev, fileId]);
            } else if (addFileResponse.uploadStatus === FileUploadStatus.Failure) {
              setProcessFailedFiles((prev) => ({ ...prev, [fileId]: addFileResponse.error }));
            }
          }
        },
      });

    return () => {
      clientSubscribe.unsubscribe();
    };
  }, [workspaceId]);

  useEffect(() => {
    selectedFilesRef.current = selectedFiles;
  }, [selectedFiles]);

  // Callback to upload all selected files in batches
  const uploadFiles = useCallback(async () => {
    let currentSize = 0;
    const batchSize = 5;
    let batchFiles = selectedFilesRef.current.slice(currentSize, batchSize);
    while (batchFiles.length > 0) {
      await uploadWorkspaceFilesBatch(batchFiles);
      currentSize += batchSize;
      batchFiles = selectedFilesRef.current.slice(currentSize, currentSize + batchSize);
    }
  }, [uploadWorkspaceFilesBatch]);

  // Callback to retry uploading a file. This happens when a file is cancelled or failed
  const onRetryFile = useCallback(
    (file: IFileWithId) => {
      uploadWorkspaceFilesBatch([file]);
    },
    [uploadWorkspaceFilesBatch],
  );

  return (
    <Dialog open>
      <DialogTitle>{t("Components.SelectedFilesListDialog.SelectedFiles", { count: selectedFiles.length })}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "flex-start",
            mt: 2,
          }}
        >
          <List dense={true}>
            {selectedFiles.map((selectedFile, index) => {
              return (
                <ListItem
                  sx={{
                    borderTop: `solid 1px ${theme.palette.divider}`,
                    paddingRight: "70px",
                  }}
                  onMouseEnter={() => setActiveItem(index)}
                  onMouseLeave={() => setActiveItem(undefined)}
                  key={index}
                  secondaryAction={<Box>{getSecondaryAction(selectedFile, index)}</Box>}
                  disableGutters
                >
                  <ListItemText primary={`${selectedFile.file.name} (${filesize(selectedFile.file.size)})`} />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={uploadingFiles.length > 0 || processedFiles.length > 0 || successFiles.length > 0 || !isWorkspaceEditable}
          onClick={uploadFiles}
        >
          {t("Components.SelectedFilesListDialog.Labels.Upload")}
        </Button>
        <Button color="secondary" disabled={uploadingFiles.length > 0} onClick={onClose}>
          {processedFiles.length > 0 || successFiles.length > 0 ? t("Global.Action.Close") : t("Global.Action.Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  /**
   * Get the suitable secondary action for the file
   *
   * @param selectedFile Selected file to get secondary action for
   * @param index Index in the list of displayed files
   * @returns Action to display in the secondary action of the list item
   */
  function getSecondaryAction(selectedFile: IFileWithId, index: number) {
    const fileId = selectedFile.id;
    // Already uploaded
    if (processedFiles.includes(fileId) || successFiles.includes(fileId)) {
      return <Check color="success" />;
      // Currently uploading & received progress from subscription
    } else if (progressingFiles[fileId]) {
      return <CircularProgressWithLabel value={progressingFiles[fileId]} />;
      // Uploading & user hovered over the item
    } else if (uploadingFiles.includes(fileId) && activeItem === index) {
      return (
        <IconButton onClick={() => abortUpload(fileId)} sx={{ right: "-5px" }}>
          <Clear fontSize="small" />
        </IconButton>
      );
      // Uploading
    } else if (uploadingFiles.includes(fileId)) {
      return <PanoramaFishEye fontSize="small" color="disabled" />;
      // Cancelled by the user
    } else if (cancelledFiles.includes(fileId)) {
      return (
        <IconButton onClick={() => onRetryFile(selectedFile)} sx={{ right: "-5px" }}>
          <Replay />
        </IconButton>
      );
      // Failed to upload in the server
    } else if (Object.keys(processFailedFiles).includes(fileId) || Object.keys(failedFiles).includes(fileId)) {
      return (
        <Stack direction="row" alignItems="center">
          <Tooltip title={processFailedFiles[fileId]}>
            <WarningAmberRounded color="warning" />
          </Tooltip>
          <IconButton onClick={() => onRetryFile(selectedFile)} sx={{ right: "-5px" }}>
            <Replay />
          </IconButton>
        </Stack>
      );
      // User hovered over the item
    } else if (activeItem === index) {
      return (
        <IconButton onClick={() => onRemoveFile(selectedFile.id)} sx={{ right: "-5px" }}>
          <Delete />
        </IconButton>
      );
    }
  }
}

function CircularProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" value={props.value * 100} size={20} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary" sx={{ fontSize: 9 }}>{`${Math.round(
          props.value * 100,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
